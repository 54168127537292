import React from 'react'
import { useController, useFormContext } from 'react-hook-form'
import { FormControl, FormControlLabel, FormHelperText, Checkbox as MuiCheckbox } from '@mui/material'

export interface Props {
    name: string
    label?: string
    disabled?: boolean
    testId?: string
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const Checkbox = ({ name, label, disabled, testId, onChange }: Props) => {
    const {
        field,
        fieldState: { error },
    } = useController({ name })
    const { watch } = useFormContext()
    const fieldValue = watch(name)

    return (
        <FormControl>
            <FormControlLabel
                control={
                    <MuiCheckbox
                        checked={fieldValue === true}
                        onChange={(e, checked) => (onChange ? onChange(e) : field.onChange(checked))}
                        readOnly={disabled}
                        name={name}
                        disabled={disabled}
                        data-testid={testId || name}
                    />
                }
                label={label}
            />
            {error && <FormHelperText error>{error?.message}</FormHelperText>}
        </FormControl>
    )
}
