import { log_info } from '@local/src/utils/helpers/logger'
import { HubConnection, HubConnectionState } from '@microsoft/signalr'
import { useEffect, useState } from 'react'

type TargetMethod = 'StudiestodNummerIsSetEvent' | 'StudiestodDeletedEvent' | 'NewDomainEvent'

interface Props {
    hub: HubConnection
    targetMethod: TargetMethod
    callBack?: (...arg: unknown[]) => void
}

const useSignalROnTargetMethod = ({ hub, targetMethod, callBack }: Props) => {
    const [hubConnectionState, setHubConnectionState] = useState<HubConnectionState>(hub?.state ?? HubConnectionState.Disconnected)

    useEffect(() => {
        setHubConnectionState(hub?.state)
    }, [hub?.state])

    useEffect(() => {
        if (hubConnectionState !== HubConnectionState.Connected) return
        hub.on(targetMethod, (...data: unknown[]) => {
            log_info('on method invoke', { targetMethod, data })
            if (callBack) {
                callBack(...data)
            }
        })
        return () => {
            if (hub && hub.state === HubConnectionState.Connected) {
                log_info('On client method effect cleanup', { targetMethod })
                hub.off(targetMethod)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hub, targetMethod, hubConnectionState])
}

export default useSignalROnTargetMethod
