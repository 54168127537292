import { removeTrailingSlash } from '@local/src/utils/helpers'
import { head } from 'ramda'
import { useHistory } from 'react-router-dom'

const useNavigateToPage = () => {
    const history = useHistory()

    const navigateToStudierTab = () => {
        const pathSplit = 'studier'
        const pathArray = window.location.pathname.split(pathSplit)
        navigateInsideMf(`${head(pathArray)}${pathSplit}`)
    }

    const navigateToStudieansokan = (ansokanId: string) =>
        navigateInsideMf(`${removeTrailingSlash(history.location.pathname)}/ansokan/${ansokanId}/ansokan`)

    const navigateInsideMf = (url: string) => history.push(url)

    const navigateOutsideMf = (url: string) => {
        window.location.href = url
    }

    return { navigateInsideMf, navigateOutsideMf, navigateToStudierTab, navigateToStudieansokan }
}

export { useNavigateToPage }
