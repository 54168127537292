import { FormHelperText } from '@mui/material'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const FormErrorSummary = () => {
    const {
        formState: { errors },
    } = useFormContext()
    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokan' })
    const hasFormErrors = Object.keys(errors).length > 0

    return hasFormErrors ? (
        <FormHelperText sx={{ fontSize: 16, mt: 2 }} error data-testid={'submit-button-error'}>
            {t('formNotValid')}
        </FormHelperText>
    ) : null
}

export default FormErrorSummary
