/* eslint-disable @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return */

import getConfig from '../../utils/ConfigService'

import { IFetchRequestType } from './network.types'

const { API_URL } = getConfig()
const baseURL = (url: string, apiVersion: string) =>
  url.endsWith('json') // if request is a json file, retrieve it from public
    ? `${process.env.PUBLIC_URL}/v${apiVersion}${url}`
    : `${API_URL}/v${apiVersion}${url}`

const requestHeaders = ({ id_token, body, method, multipart = false }: Partial<IFetchRequestType>) => {
  // Directly forcing a 'multipart/form-data' will not automatically generate boundaries, if file is provided, dont set content-type and content-type and boundaries will automatically be generated
  const headerContentType = multipart ? {} : { 'Content-type': 'application/json' }
  return {
    headers: {
      Authorization: `Bearer ${id_token}`,
      ...headerContentType,
      'Accept-Language': 'sv',
    },
    method,
    body,
  }
}

const documentTypes = [
  'application/pdf',
  'image/jpg',
  'application/octet-stream',
  'image/bmp',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'image/gif',
  'image/jpeg',
  'image/png',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/rtf',
  'image/tiff',
  'image/tif',
  'text/plain',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
]

export const handleResponse = async (response: Response) => {
  const responseContentType = response.headers.get('content-type') ?? ''
  const contentTypeIsDocument = documentTypes.some((type) => responseContentType.includes(type))

  const responseStatusIs2XX = Math.floor(response.status / 100) === 2
  const responseStatusIs4XX = Math.floor(response.status / 100) === 4
  const responseStatusIs5XX = Math.floor(response.status / 100) === 5

  const emptyResponseBody = !responseContentType.includes('application/json') // Cypress responses will use this header by default

  if (responseStatusIs2XX) return contentTypeIsDocument ? response : emptyResponseBody ? null : response

  if (responseStatusIs4XX) {
    await response
      .json()
      .then((responseBody: { userFacingErrorMessage: unknown }) => {
        const error = responseBody.userFacingErrorMessage
        // eslint-disable-next-line @typescript-eslint/no-base-to-string
        throw new Error(error.toString())
      })
      .catch(() => {
        switch (response.status) {
          case 401:
            throw new Error('Du saknar behörighet.')
          case 403:
            throw new Error('Du saknar behörighet.')
          case 404:
            throw new Error('Resursen hittades inte.')
          default:
            throw new Error('Ett oväntat systemfel inträffade.')
        }
      })
  }

  if (responseStatusIs5XX) throw new Error('Ett oväntat systemfel inträffade.')
}

export const fetchRequest = ({
  body,
  fullUrl,
  method = 'GET',
  id_token,
  url,
  multipart,
  apiVersion,
  _fetch = fetch,
}: IFetchRequestType): Promise<Response> => {
  const headers = requestHeaders({ id_token, body, method, multipart })
  return _fetch(`${fullUrl || baseURL(url, apiVersion)}`, headers as unknown)
}
