import React from 'react'
import getConfig from '@local/utils/ConfigService'
import { Stack } from '@mui/material'
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined'
import { ExternalLink } from '@local/src/components/ExternalLink'
import { useTranslation } from 'react-i18next'
import { usePickEpiContentWithFallback } from '@local/src/hooks'

const ExcelLinks = () => {
    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokan.steps.radgivarensUnderlag' })

    const {
        studierExcelRaknesnurrorFile: { studierExcelRaknesnurraStudieersattningLink, studierExcelRaknesnurraStudielonLink },
    } = usePickEpiContentWithFallback('studiertab')

    const studielonFileLink = `${getConfig().MEDIA_URL}${studierExcelRaknesnurraStudielonLink}`

    return (
        <Stack>
            <ExternalLink
                linkText={t('studierExcelRaknesnurraStudieersattningLinkText')}
                href={studierExcelRaknesnurraStudieersattningLink}
                testId="studieersattning-link"
            />
            <ExternalLink
                linkText={t('studierExcelRaknesnurraStudielonLinkText')}
                href={studielonFileLink}
                customIcon={<DownloadForOfflineOutlinedIcon />}
                testId="studielon-link"
            />
        </Stack>
    )
}

export default ExcelLinks
