import { FormValidationTexts } from '@local/src/content/resources'
import { KortvarigtStudiestodApiModel } from '@local/src/services/SwaggerApi/data-contracts'
import { nullableNumberSchema } from '@local/src/utils/Yup/YupHelper'
import { object as yupObject, SchemaOf } from 'yup'

export const KortvarigtStudiestodDraftSchema = (formValidationTexts?: FormValidationTexts): SchemaOf<KortvarigtStudiestodApiModel> =>
    yupObject({
        preliminarErsattning: nullableNumberSchema()
            .integer(formValidationTexts?.heltal)
            .min(1, formValidationTexts?.kortvarigtStudiestodPreliminarErsattningMin)
            .max(10000, formValidationTexts?.kortvarigtStudiestodPreliminarErsattningMax),
        utbildningslangd: nullableNumberSchema()
            .min(0.5, formValidationTexts?.kortvarigtStudiestodUtbildningslangdErsattningMin)
            .max(4.5, formValidationTexts?.kortvarigtStudiestodUtbildningslangdErsattningMax),
    }).nullable()

export const KortvarigtStudiestodSchema = (formValidationTexts?: FormValidationTexts): SchemaOf<KortvarigtStudiestodApiModel> =>
    KortvarigtStudiestodDraftSchema(formValidationTexts).concat(
        yupObject({
            preliminarErsattning: nullableNumberSchema().required(formValidationTexts?.kortvarigtStudiestodPreliminarErsattningRequired),
            utbildningslangd: nullableNumberSchema().required(formValidationTexts?.kortvarigtStudiestodUtbildningslangdErsattningRequired),
        })
    )
