import { Box, CircularProgress } from '@mui/material'
import React from 'react'

const Loader = () => (
	<Box sx={{ display: 'flex', justifyContent: 'center' }}>
		<CircularProgress />
	</Box>
)

export default Loader
