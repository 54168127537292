import React from 'react'
import { CsnBeslutstyp, CsnBeslutsutfall } from '@local/src/services/SwaggerApi/data-contracts'
import { Grid2 as Grid } from '@mui/material'
import Icons from '@local/src/components/Icons'
import { useTranslation } from 'react-i18next'

import { beslutstypToString, beslutsutfallToString } from '../../helpers'
import LabelValue from '../../../../LabelValue/LabelValue'

interface Props {
    inkomFranCsn: string
    beslutstyp: CsnBeslutstyp
    beslutsutfall: CsnBeslutsutfall
}

const BeslutFranCsnListItemBaseInformation = ({ beslutstyp, beslutsutfall }: Props) => {
    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokan.steps.csn' })

    return (
        <Grid container spacing={1}>
            <Grid container direction="row" size={{ xs: 6 }} spacing={1} alignItems="center">
                <Grid>
                    <Icons.Info color="primary" size="small" />
                </Grid>
                <Grid>
                    <LabelValue label={t('beslutTypAvBeslut')} value={beslutstypToString(beslutstyp)} />
                </Grid>
            </Grid>
            <Grid container direction="row" size={{ xs: 6 }} spacing={1} alignItems="center">
                <Grid>
                    <Icons.Info color="primary" size="small" />
                </Grid>
                <Grid>
                    <LabelValue label={t('beslutsutfall')} value={beslutsutfallToString(beslutsutfall)} />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default BeslutFranCsnListItemBaseInformation
