import { FormValidationTexts } from '@local/src/content/resources'
import { Anledning, MotiveringStudielonApiModel } from '@local/src/services/SwaggerApi/data-contracts'
import { maxLengthValidationText, shouldBeNullOrUndefined } from '@local/src/utils/Yup/YupHelper'
import { mixed as yupMixed, string as yupString, SchemaOf, object as yupObject } from 'yup'

export const MotiveringStudielonSchema = (formValidationTexts?: FormValidationTexts): SchemaOf<MotiveringStudielonApiModel> =>
    MotiveringStudielonDraftSchema().concat(
        yupObject()
            .required()
            .shape({
                anledning: yupMixed().oneOf<Anledning>([0, 1, 2, 3]).required(formValidationTexts?.anledning),
                beskrivning: yupString().required(formValidationTexts?.beskrivning).trim().max(254, maxLengthValidationText),
            })
    )

export const MotiveringStudielonDraftSchema = (): SchemaOf<MotiveringStudielonApiModel> =>
    yupObject()
        .nullable()
        .shape({
            anledning: yupMixed().oneOf<Anledning>([0, 1, 2, 3, null, undefined]),
            beskrivning: yupString().nullable().trim().max(254, maxLengthValidationText),
        })

export const MotiveringStudielonWhenOnlyStudieersattningSchema = (): SchemaOf<MotiveringStudielonApiModel> =>
    yupObject()
        .nullable()
        .shape({
            anledning: yupMixed()
                .nullable()
                .test('should be undefined', 'anledningShouldBeNull', (value) => shouldBeNullOrUndefined(value as number)),
            beskrivning: yupString()
                .nullable()
                .test('should be undefined', 'beskrivningShouldBeNull', (value) => shouldBeNullOrUndefined(value)),
        })
