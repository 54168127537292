import React from 'react'
import { useFieldArray } from 'react-hook-form'
import { Box, Button, Typography } from '@mui/material'
import { useRadgivarensUnderlagFormContext } from '@local/src/features/StudiestodAnsokan/Components/Steps/RadgivarensUnderlag/Hooks'
import { useTranslation } from 'react-i18next'

interface Props {
    utbildningsIndex: number
}

const LaggTillKalenderhalvarButton = ({ utbildningsIndex }: Props) => {
    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokan.steps.radgivarensUnderlag' })

    const { control, watch } = useRadgivarensUnderlagFormContext()
    const { append } = useFieldArray({
        control,
        name: `utbildningar.${utbildningsIndex}.studieersattningAndStudielon.kalenderhalvar`,
    })

    const kalenderhalvarCount = watch(`utbildningar.${utbildningsIndex}.studieersattningAndStudielon.kalenderhalvar`)?.length
    const showAddButton = kalenderhalvarCount < 4

    const handleAddKalenderhalvar = () => {
        append({
            studieersattning: undefined,
            studielon: undefined,
            hasNoStudielon: false,
        })
    }

    return showAddButton ? (
        <Box>
            <Button data-testid="add-kalenderhalvar" onClick={handleAddKalenderhalvar} variant="outlined">
                {t('laggTillKalenderhalvarText')}
            </Button>
        </Box>
    ) : (
        <Typography data-testid="info-no-add-kalenderhalvar">{t('maxKalenderhalvarText')}</Typography>
    )
}

export default LaggTillKalenderhalvarButton
