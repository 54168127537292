import { useGetCurrentStudiestodansokan } from '@local/src/features/StudiestodAnsokan/Hooks'
import React, { Fragment } from 'react'
import { Divider, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { BeslutAccordionItem, BeslutAccordionItemContent, BeslutListItem } from '../BeslutComponents'
import GodkandAv from '../GodkandAv/GodkandAv'

const BeslutadSpecialfall = () => {
    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokan.steps.beslut' })

    const { hasStudielonOrStudieErsattning } = useGetCurrentStudiestodansokan()

    return (
        <Stack spacing={2} data-testid="beslutad-specialfall-content">
            <Divider />
            <GodkandAv />

            {hasStudielonOrStudieErsattning ? (
                <Fragment>
                    <Typography variant="h5">{t('afterBeslutAccordionHeader')}</Typography>
                    <BeslutAccordionItem heading={t('afterBeslutAccordionEntry1Header')} expansionId="beslutad-specialfall-accordion-1">
                        <BeslutAccordionItemContent content={t('afterBeslutAccordionEntry1Description')} />
                    </BeslutAccordionItem>
                    <BeslutAccordionItem heading={t('afterBeslutAccordionEntry2Header')} expansionId="beslutad-specialfall-accordion-2">
                        <BeslutAccordionItemContent content={t('afterBeslutAccordionEntry2Description')} />
                    </BeslutAccordionItem>
                    <BeslutAccordionItem heading={t('afterBeslutAccordionEntry3Header')} expansionId="beslutad-specialfall-accordion-3">
                        <BeslutAccordionItemContent content={t('afterBeslutAccordionEntry3Description')} />
                    </BeslutAccordionItem>
                    <BeslutAccordionItem
                        heading={t('afterBeslutAccordionEntry4Header')}
                        subHeading={t('afterBeslutAccordionEntry4SubHeader')}
                        expansionId="beslutad-specialfall-accordion-4"
                    >
                        <BeslutListItem
                            heading={t('afterBeslutAccordionEntry4List1Header')}
                            items={[t('afterBeslutAccordionEntry4List1Item1'), t('afterBeslutAccordionEntry4List1Item2')]}
                        />
                        <BeslutListItem
                            heading={t('afterBeslutAccordionEntry4List2Header')}
                            items={[
                                t('afterBeslutAccordionEntry4List2Item1'),
                                t('afterBeslutAccordionEntry4List2Item2'),
                                t('afterBeslutAccordionEntry4List2Item3'),
                                t('afterBeslutAccordionEntry4List2Item4'),
                                t('afterBeslutAccordionEntry4List2Item5'),
                                t('afterBeslutAccordionEntry4List2Item6'),
                                t('afterBeslutAccordionEntry4List2Item7'),
                            ]}
                        />
                    </BeslutAccordionItem>
                </Fragment>
            ) : null}
        </Stack>
    )
}

export default BeslutadSpecialfall
