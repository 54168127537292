import { ApiConfig } from '@local/services/SwaggerApi/http-client'
import getConfig from '@local/utils/ConfigService'
import { getIdToken } from '@trr/app-shell-data'

import { StudiestodMedarbetare } from './SwaggerApi/StudiestodMedarbetare'
import { Studiestod } from './SwaggerApi/Studiestod'
import { CsnAdHocYttrande } from './SwaggerApi/CsnAdHocYttrande'
import { CsnArendeBegaran } from './SwaggerApi/CsnArendeBegaran'
import { CsnArendeBeslut } from './SwaggerApi/CsnArendeBeslut'

export const ReactQueryKey = {
    studiestodList: 'studiestodList',
    getStudiestodsansokanById: 'getStudiestodsansokanById',
    getStudieintygByAnsokanId: 'getStudieintygByAnsokanId',
    getBegardaYttranden: 'getBegardaYttranden',
    getArendeById: 'getArendeById',
} as const

export const getBaseRequestParams = () => {
    const idToken = getIdToken()

    return {
        headers: {
            Authorization: `Bearer ${idToken}`,
            'Accept-Language': 'sv',
        },
    }
}

const apiConfig: ApiConfig = {
    baseUrl: `${getConfig().API_URL}`,
}

export const studiestodMedarbetareApi = new StudiestodMedarbetare(apiConfig)
export const studiestodApi = new Studiestod(apiConfig)
export const csnAdHocYttrandeApi = new CsnAdHocYttrande(apiConfig)
export const csnArendeBegaranApi = new CsnArendeBegaran(apiConfig)
export const csnArendeBeslutApi = new CsnArendeBeslut(apiConfig)
