import { StudiestodListApiModel, UtbildningarListApiModel } from '@local/src/services/SwaggerApi/data-contracts'
import { Divider, Stack, Typography } from '@mui/material'
import React from 'react'

import { UtbildningMetaData } from './UtbildningListItemComponents'

interface Props {
    utbildning: UtbildningarListApiModel
    ansokan: StudiestodListApiModel
}

const UtbildningListItem = ({ utbildning, ansokan }: Props) => (
    <Stack spacing={4}>
        <Divider />
        <Stack spacing={2}>
            <Typography sx={{ wordBreak: 'break-word' }} variant="h6">
                {utbildning.utbildningsnamn}
            </Typography>
            <UtbildningMetaData utbildning={utbildning} ansokan={ansokan} />
        </Stack>
    </Stack>
)

export default UtbildningListItem
