import { useNavigateToPage } from '@local/src/hooks'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface Props {
    isDeleted: boolean
    studiestodNummer: number | undefined
    ansokanNamn: string | undefined
}

const IsDeletedDialog = ({ isDeleted, studiestodNummer, ansokanNamn }: Props) => {
    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokan.isDeletedDialog' })
    const { navigateToStudierTab } = useNavigateToPage()
    const { reset } = useFormContext()

    const [nummerAndNamnContentText, setNummerAndNamnContentText] = useState(undefined)

    const handleOnConfirmDialog = () => {
        navigateToStudierTab()
    }

    useEffect(() => {
        if (!isDeleted) return
        if (studiestodNummer && ansokanNamn) setNummerAndNamnContentText(`#${studiestodNummer} - ${ansokanNamn}`)
        reset()
    }, [isDeleted])

    return (
        <Dialog data-testid="studiestod-is-deleted-dialog" fullWidth open={isDeleted}>
            <DialogTitle>{t('title')}</DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    {nummerAndNamnContentText && <DialogContentText>{nummerAndNamnContentText}</DialogContentText>}
                    <DialogContentText>{t('contentText')}</DialogContentText>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant="text" onClick={handleOnConfirmDialog} data-testid="dialog--confirm-button">
                    {t('confirmButton')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default IsDeletedDialog
