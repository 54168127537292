import React from 'react'
import { Stack, Typography } from '@mui/material'
import Icons from '@local/src/components/Icons'

interface Props {
    content: string
    isReadonly: boolean
}

const MarkeratSomKlartInfo = ({ content, isReadonly }: Props) =>
    isReadonly ? (
        <Stack direction="row" spacing={2} alignItems="center">
            <Icons.Check title={content} />
            <Typography>{content}</Typography>
        </Stack>
    ) : null

export default MarkeratSomKlartInfo
