import { Dropdown, RadioButtonGroup } from '@local/src/components/FormComponents'
import { mapUtbildningslangd } from '@local/src/features/StudiestodAnsokan/Components/Utbildning/Helpers'
import { useGetCurrentStudiestodansokan } from '@local/src/features/StudiestodAnsokan/Hooks'
import { mojligaArUtbetalningsstart } from '@local/src/utils/helpers/studiestodAnsokanHelper'
import { formatToDate } from '@local/src/utils/helpers/dateTimeFormatHelper'
import React, { Fragment } from 'react'
import { FieldPath } from 'react-hook-form'
import { Stack, Typography, Box, Divider } from '@mui/material'
import InfoPopover from '@local/src/components/InfoPopover'
import { ExternalLink } from '@local/src/components/ExternalLink'
import LabelValue from '@local/src/features/StudiestodAnsokan/Components/LabelValue/LabelValue'
import { RadgivarensUnderlagForm } from '@local/src/features/StudiestodAnsokan/Components/Steps/RadgivarensUnderlag/Models/RadgivarensUnderlagForm'
import { useTranslation } from 'react-i18next'
import { usePickEpiContentWithFallback } from '@local/src/hooks'
import NumberInputField from '@local/src/components/FormComponents/InputField/NumberInputField'

interface Props {
    utbildningsIndex: number
    isReadonly: boolean
}

const KompletterandeStudiestod = ({ utbildningsIndex, isReadonly }: Props) => {
    const { studiestodAnsokan } = useGetCurrentStudiestodansokan()
    const {
        studierExcelRaknesnurrorFile: { kompletterandeStudiestodRaknesnurraLink, kompletterandeStudiestodRaknesnurraRemainingUrl },
    } = usePickEpiContentWithFallback('studiertab')

    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokan.steps.radgivarensUnderlag' })

    const fieldPath: FieldPath<RadgivarensUnderlagForm> = `utbildningar.${utbildningsIndex}.kompletterandeStudiestod`
    const currentUtbildningUnderlag = studiestodAnsokan.radgivarunderlag?.utbildningar[utbildningsIndex]
    const antalTerminer = currentUtbildningUnderlag?.utbildning?.antalTerminer
    const studieTakt = currentUtbildningUnderlag?.utbildning?.omfattning
    const beraknadStudiestart = currentUtbildningUnderlag?.utbildning?.studiestart
    const utbildningLangdEnhet = currentUtbildningUnderlag?.utbildning?.utbildningLangdEnhet
    const utbildningslangdLabel = mapUtbildningslangd(antalTerminer, utbildningLangdEnhet)

    return (
        <Fragment>
            <Divider />
            <Stack spacing={2}>
                <Typography variant="h5">{t('kompletterandeStudiestodHeader')}</Typography>
                <Typography>{t('kompletterandeStudiestodLeadText')}</Typography>
                <ExternalLink
                    linkText={t('kompletterandeStudiestodErsattningLinkText')}
                    testId="kompletterande-studiestod-ersattning-link"
                    href={t('kompletterandeStudiestodErsattningLink')}
                />
                <ExternalLink
                    linkText={t('kompletterandeStudiestodRaknesnurraLinkText')}
                    testId="kompletterande-studiestod-raknesnurra-link"
                    href={kompletterandeStudiestodRaknesnurraLink + kompletterandeStudiestodRaknesnurraRemainingUrl}
                />
                <LabelValue label={t('kompletterandeStudiestodUtbildningslangdLabel')} value={utbildningslangdLabel} />
                <LabelValue label={t('kompletterandeStudiestodStudietaktLabel')} value={`${studieTakt} %`} />
                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                    <NumberInputField
                        name={`${fieldPath}.utbildningslangd`}
                        label={t('kompletterandeStudiestodUtbildningslangdText')}
                        disabled={isReadonly}
                    />

                    <InfoPopover content={t('kompletterandeStudiestodUtbildningslangdTooltip')} />
                </Stack>
                <NumberInputField
                    name={`${fieldPath}.preliminarErsattning`}
                    label={t('kompletterandeStudiestodPreliminarErsattningText')}
                    disabled={isReadonly}
                    wrapperSx={{ maxWidth: { xs: '100%', sm: '80%' } }}
                />
                <LabelValue label={t('beraknadStudiestartText')} value={formatToDate(beraknadStudiestart)} />
                <Typography variant="caption" color="text.secondary">
                    {t('beraknadUtbetalningstartHeader')}
                </Typography>
                <Box sx={{ maxWidth: { xs: '100%', sm: '30%' } }}>
                    <Dropdown
                        name={`${fieldPath}.beraknadUtbetalningsstart.year`}
                        label="År"
                        options={mojligaArUtbetalningsstart()}
                        disabled={isReadonly}
                    />
                </Box>
                <RadioButtonGroup
                    name={`${fieldPath}.beraknadUtbetalningsstart.termin`}
                    testId={`${fieldPath}.beraknadUtbetalningsstart.termin`}
                    label=""
                    valueType="number"
                    row
                    isReadOnly={isReadonly}
                    radios={[
                        {
                            label: t('varTerminLabel'),
                            value: 0,
                            disabled: isReadonly,
                        },
                        {
                            label: t('hostTerminLabel'),
                            value: 1,
                            disabled: isReadonly,
                        },
                    ]}
                />
            </Stack>
        </Fragment>
    )
}

export default KompletterandeStudiestod
