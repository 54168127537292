import { HubConnection } from '@microsoft/signalr'

import useSignalRJoinGroup from './useSignalRJoinGroup'
import useSignalROnTargetMethod from './useSignalROnTargetMethod'

const useSignalRStudiestodDetailEvent = (hub: HubConnection, studiestodId: string, setIsDeleted: () => void) => {
    useSignalRJoinGroup({
        hub: hub,
        groupName: 'StudiestodEvent',
        groupIdentifier: studiestodId,
    })

    useSignalROnTargetMethod({
        hub: hub,
        targetMethod: 'StudiestodDeletedEvent',
        callBack: setIsDeleted,
    })
}

export default useSignalRStudiestodDetailEvent
