import { useState } from 'react'
import { studiestodMedarbetareApi } from '@local/src/services/apiEndpointHelper'
import { ApiResponseModel } from '@local/services/ApiResponseModel'
import { apiRequestTryCatchWrapper } from '@local/src/services/apiRequestHelper'
import { StudiestodUnlockState } from '@local/src/services/SwaggerApi/data-contracts'

import useGetCurrentStudiestodansokan from './useGetCurrentStudiestodansokan'

const useUnlockStudiestodAnsokanState = (state: StudiestodUnlockState) => {
    const [isPosting, setIsPosting] = useState(false)
    const [apiResponse, setApiResponse] = useState<ApiResponseModel<void>>()
    const { studiestodAnsokan, invalidateQuery } = useGetCurrentStudiestodansokan()

    const unlockStudiestodAnsokanState = () =>
        apiRequestTryCatchWrapper(
            'useUnlockStudiestodAnsokanState',
            studiestodMedarbetareApi.arendeAnsokanUnlockPartialUpdate,
            studiestodAnsokan.arendeId,
            studiestodAnsokan.id,
            state
        )

    const handleUnlockStudiestodAnsokanState = async () => {
        setIsPosting(true)
        const response = await unlockStudiestodAnsokanState()
        setApiResponse(response)
        if (response.isSuccess) {
            await invalidateQuery()
        }
        setIsPosting(false)
    }

    return { isPosting, apiResponse, handleUnlockStudiestodAnsokanState }
}

export default useUnlockStudiestodAnsokanState
