import { EventName, EventColor } from '@local/src/features/StudiestodAnsokan/Components/DomainEventsLog/Utils/domainEventTypes'
import { DomainEventLogApiModel, IsKoptUtbildning, UtbildningLangdEnhet } from '@local/src/services/SwaggerApi/data-contracts'
import { formatToDate } from '@local/src/utils/helpers/dateTimeFormatHelper'
import { diff, IChange } from 'json-diff-ts'
import { useTranslation } from 'react-i18next'

const IsValidGUID = (str: string): boolean => {
    const guidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/
    return guidRegex.test(str)
}

const IsInteger = (str: string): boolean => {
    const num = Number(str)
    return Number.isInteger(num)
}

export const getEventText = (domainEvent: DomainEventLogApiModel) => {
    switch (domainEvent.subject as EventName) {
        case 'StudiestodCreatedEvent':
            return 'Studiestödsansökan skapad'
        case 'StudiestodUpdatedEvent':
            return 'Studiestödsansökan uppdaterad'
        case 'StudiestodStudieintygAddedEvent':
            return 'Nytt studieintyg uppladdat'
        case 'StudiestodMovedToNewArendeEvent':
            return 'Studiestödsansökan flyttad till nytt ärende'
        case 'StudiestodHandlaggAnsokanAddedEvent':
            return 'Studiestödsansökan öppnad första gången i RÅD'
        case 'StudiestodUnlockStateEvent':
            switch (domainEvent.data.State) {
                case 1:
                    return 'Studiestödsansökan upplåst'
                case 2:
                    return 'Rådgivarens underlag upplåst'
                case 3:
                    return 'Beslut upplåst'
                default:
                    return 'Studiestödsansökan steg upplåst'
            }

        case 'StudiestodLockedEvent':
            return 'Studiestödsansökan är låst för kunden'
        case 'StudiestodBeslutAddedEvent':
            return 'Beslut fattat på studiestödsansökan'
        case 'StudiestodUnderlagAddedFirstTimeEvent':
            return domainEvent?.data?.UnderlagPubliceringsdatum === null || domainEvent?.data?.UnderlagPubliceringsdatum === undefined
                ? 'Rådgivarens underlag sparat'
                : 'Rådgivarens underlag markerad som klar'
        case 'StudiestodUnderlagAddedEvent':
            return domainEvent?.data?.UnderlagPubliceringsdatum === null || domainEvent?.data?.UnderlagPubliceringsdatum === undefined
                ? 'Rådgivarens underlag uppdaterat'
                : 'Rådgivarens underlag markerad som klar'
        case 'CsnBegaranKoppladToAnsokanEvent':
            return 'Begärt yttrande kopplat till studiestödsansökan'
        case 'SvarBegaranYttrandeSentToCsnEvent':
            return 'Svar på begärt yttrande skickat till CSN'
        case 'AdHocYttrandeDeletedEvent':
            return 'Ad-hoc yttrande borttaget'
        case 'AdHocYttrandeCreatedEvent':
            return 'Ad-hoc yttrande skapat'
        case 'AdHocYttrandeSentToCsnEvent':
            return 'Ad-hoc yttrande skickat till CSN'
        case 'CsnBeslutAddedEvent':
            return 'Beslut inkom från CSN'
        case 'CsnBegaranYttrandeAddedEvent':
            return 'Begäran om yttrande inkom från CSN'
        case 'StudiestodDeletedEvent':
            return 'Studiestödsansökan borttagen'
        case 'StudiestodStateResetEvent':
            return 'Studiestödsansökan upplåst'
        case 'StudiestodSetToAktuellEvent':
            return 'Studiestödsansökan är satt som aktuell'
        case 'StudiestodSetToEjAktuellEvent':
            return 'Studiestödsansökan är satt som ej aktuell'
        case 'StudiestodAnsokanPublishedFirstTimeEvent':
            return 'Studiestödsansökan inskickad'
        default:
            return domainEvent.subject as EventName
    }
}

export const getNameFromEvent = (subject: EventName, name: string) => {
    switch (subject) {
        case 'StudiestodStudieintygAddedEvent':
        case 'StudiestodUpdatedEvent':
        case 'StudiestodCreatedEvent':
        case 'StudiestodDeletedEvent':
        case 'StudiestodAnsokanPublishedFirstTimeEvent':
            return 'kunden'

        default:
            return name
    }
}

export const getEventColor = (subject: EventName): EventColor => {
    switch (subject) {
        case 'StudiestodHandlaggAnsokanAddedEvent':
            return 'info'
        case 'StudiestodBeslutAddedEvent':
        case 'CsnBeslutAddedEvent':
            return 'success'
        case 'StudiestodUpdatedEvent':
        case 'StudiestodStudieintygAddedEvent':
        case 'StudiestodCreatedEvent':
            return 'primary'
        case 'StudiestodLockedEvent':
        case 'StudiestodSetToEjAktuellEvent':
        case 'StudiestodUnlockStateEvent':
            return 'warning'
        case 'StudiestodSetToAktuellEvent':
            return 'success'
        default:
            return undefined
    }
}
export const getTimelineDotColor = (domainEvent: DomainEventLogApiModel): EventColor => {
    if (domainEvent.source === '/csn') {
        return 'error'
    }

    switch (domainEvent.subject) {
        case 'StudiestodCreatedEvent':
        case 'StudiestodUpdatedEvent':
        case 'StudiestodAnsokanPublishedFirstTimeEvent':
        case 'StudiestodAnsokanSavedEvent':
        case 'StudiestodStudieintygAddedEvent':
            return 'secondary'
        default:
            return 'primary'
    }
}

export const FormatChangeValue = (keyProp: string, value: any): string => {
    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokan' })
    switch (keyProp) {
        case 'Studiestart':
            return formatToDate(value)

        case 'Utbildningstyp':
            switch (value) {
                case 0:
                    return t('steps.radgivarensUnderlag.utbildning.universitetHogskolaText')
                case 1:
                    return t('steps.radgivarensUnderlag.utbildning.yrkeshogskolaText')
                case 2:
                    return t('steps.radgivarensUnderlag.utbildning.arbetsmarknadsutbildningText')
                case 3:
                    return t('steps.radgivarensUnderlag.utbildning.gymnasieutbildningText')
                case 4:
                    return t('steps.radgivarensUnderlag.utbildning.annatText')
                default:
                    return value
            }
        case 'Utbildningsforutsattning':
            return value === 0
                ? t('steps.ansokanOmStudiestod.utbildningsforutsattningOmskolningText')
                : t('steps.ansokanOmStudiestod.utbildningsforutsattningVidareutbildningText')

        case 'UtbildningLangdEnhet':
            return UtbildningLangdEnhet[value]
        case 'Omfattning':
            return `${value}%`
        case 'IsKoptUtbildning':
            return IsKoptUtbildning[value]
        case 'HasYttrandeToCsn':
            return value === true ? 'Ja' : 'Nej'
        case 'IsStodNekat':
            return value === false ? 'Ja' : 'Nej'
        case 'HasAktivitetstod':
            return value === true ? 'Ja' : 'Nej'
        case 'Anledning':
            switch (value) {
                case 0:
                case 'annat':
                    return t('steps.radgivarensUnderlag.skalAnnatText')
                case 1:
                case 'ejCsnBerattigad':
                    return t('steps.radgivarensUnderlag.skalEjCsnBerattigadText')
                case 2:
                case 'ekonomiskSituation':
                    return t('steps.radgivarensUnderlag.skalEkonomiskSituationText')
                case 3:
                case 'personligSituation':
                    return t('steps.radgivarensUnderlag.skalPersonligSituationText')
                default:
                    return value
            }
        case 'Termin':
            switch (value) {
                case 0:
                    return t('steps.radgivarensUnderlag.varTerminLabel')
                case 1:
                    return t('steps.radgivarensUnderlag.hostTerminLabel')
                default:
                    return value
            }
        case 'StudiemedelsberattigadUtbildning':
            return value === true ? 'Ja' : 'Nej'
        case 'AvserSokaCsn':
            return value === true ? 'Ja' : 'Nej'
        case 'KraverProvning':
            return value === true ? 'Ja' : 'Nej'
        default:
            return value
    }
}

export const FormatAddOrRemoveValue = (keyProp: string, value: any): string => {
    switch (keyProp) {
        case 'NekaStod':
            return value.IsStodNekat === false ? 'Ja' : 'Nej'
        case 'YttrandeToCsn':
            return value.HasYttrandeToCsn === true ? 'Ja' : 'Nej'
        case 'HasAktivitetstod':
            return value === true ? 'Ja' : 'Nej'
        default:
            return ''
    }
}

export const FormatTitle = (keyProp: string, domainEvent: DomainEventLogApiModel): string => {
    if (IsValidGUID(keyProp)) {
        const utbName = domainEvent.data?.Ansokan?.Utbildningar?.map((utb: any) => {
            if (utb.Id === keyProp) {
                return utb.Utbildningsnamn
            }
        }).filter((utbName: any) => utbName !== undefined)

        if (utbName === undefined) {
            return 'Utbildning'
        }
        return `Utbildning ${utbName}`
    }
    if (IsInteger(keyProp)) {
        const index = Number(keyProp) + 1
        return index.toString()
    }
    switch (keyProp) {
        case 'AnsokanNamn':
            return 'Yrkesmål'
        case 'Utbildningsforutsattning':
            return 'Syfte'
        case 'Arbetsmarknad':
            return 'Arbetsmarknad'
        case 'KompletteringErfarenhet':
            return 'Utbildnings- och yrkeserfarenhet'
        case 'Nyanstallning':
            return 'Stärker framtida ställning'
        case 'Utbildningsalternativ':
            return 'Övrig information'
        case 'Utbildningsnamn':
            return 'Utbildningens namn'
        case 'Anordnare':
            return 'Skola'
        case 'Utbildningstyp':
            return 'Typ av utbildning'
        case 'Studiestart':
            return 'Beräknad studiestart'
        case 'Omfattning':
            return 'Studieomfattning'
        case 'AntalTerminer':
            return 'Utbildningens längd'
        case 'UtbildningLangdEnhet':
            return 'Enhet utbildningens längd'
        case 'IsKoptUtbildning':
            return 'Kursavgift'
        case 'MotiveringArbetsmarknad':
            return 'Stärker framtida ställning'
        case 'MotiveringUtbildningsmal':
            return 'Arbetsmarknad'
        case 'HasAktivitetstod':
            return 'Aktivitetsstöd'
        case 'YttrandeToCsn':
        case 'HasYttrandeToCsn':
            return 'Yttrande till CSN'
        case 'NekaStod':
        case 'IsStodNekat':
            return 'Bevilja ekonomiskt stöd'
        case 'KompletterandeStudiestod':
            return 'Kompletterande studiestöd'
        case 'KortvarigtStudiestod':
            return 'Kortvarigt studiestöd'
        case 'KoptUtbildning':
            return 'Köpt utbildning'
        case 'StudieersattningAndStudielon':
            return 'Studieersättning'
        case 'PreliminarErsattning':
            return 'Preliminär ersättning'
        case 'BeraknadUtbetalningsstart':
            return 'Beräknad utbetalningsstart'
        case 'Year':
            return 'År'
        case 'Termin':
            return 'Termin'
        case 'Kalenderhalvar':
            return 'Kalenderhalvår'
        case 'Studieersattning':
            return 'Preliminär ersättning'
        case 'Studielon':
            return 'Preliminär studielön'
        case 'Utbildningslangd':
            return 'Ersatt utbildningslängd'
        case 'Kostnad':
            return 'Utbildningskostnad'
        case 'Beskrivning':
            return 'Skäl till studielön'
        case 'Anledning':
            return 'Kortfattad motivering'
        case 'MotiveringStudielon':
            return 'Studielön motivering'
        case 'StudiemedelsberattigadUtbildning':
            return 'Utbildning med rätt till studiemedel'
        case 'AvserSokaCsn':
            return 'Avser söka CSN'
        case 'KraverProvning':
            return 'Kräver prövning'
        case 'AntalTerminerStudiestod':
            return 'Antal terminer'
        default:
            return keyProp
    }
}

export const getChanges = (domainEvent: DomainEventLogApiModel, prevEvents: DomainEventLogApiModel[]): IChange[] => {
    if (prevEvents.length === 0) return []

    const { subject, data } = domainEvent
    const eventFilters: Record<string, string[]> = {
        StudiestodUpdatedEvent: ['StudiestodUpdatedEvent', 'StudiestodCreatedEvent', 'StudiestodAnsokanPublishedFirstTimeEvent'],
        StudiestodUnderlagAddedEvent: ['StudiestodUnderlagAddedEvent', 'StudiestodUnderlagAddedFirstTimeEvent'],
    }

    const relevantEvents = prevEvents.filter((e) => {
        const isRelevantSubject = eventFilters[subject]?.includes(e.subject)
        return isRelevantSubject && e.id !== domainEvent.id && e.data?.Ansokan?.Publiceringsdatum !== null
    })

    if (relevantEvents.length === 0) return []

    if (subject === 'StudiestodUpdatedEvent') {
        if (data?.Ansokan !== undefined && relevantEvents[0].data?.Ansokan !== undefined) {
            return diff(relevantEvents[0].data?.Ansokan, data?.Ansokan, {
                embeddedObjKeys: { Utbildningar: 'Id' },
                keysToSkip: ['SenastUppdateradDatum', 'Publiceringsdatum', 'HandlaggsAv', 'EjRedigerbarAv', 'Villkor', 'SkapatDatum'],
            })
        }
    }

    if (subject === 'StudiestodUnderlagAddedEvent' || subject === 'StudiestodUnderlagAddedFirstTimeEvent') {
        if (data?.Underlag !== undefined && relevantEvents[0].data?.Underlag !== undefined) {
            return diff(relevantEvents[0].data?.Underlag, data?.Underlag, {
                embeddedObjKeys: { Utbildningar: 'UtbildningId' },
                keysToSkip: ['PubliceradAv', 'SparadAv', 'SenastUppdateratDatum', 'Insatser'],
            })
        }
    }

    return []
}
