import { useEffect } from 'react'
import { useRouteMatch, generatePath } from 'react-router-dom'
import { useNavigateToPage } from '@local/src/hooks'

import { StudiestodStepEnum, StudiestodStepType } from '../Models/StudiestodAnsokan.types'
import { studiestodAnsokanDetailPath } from '../Utils/studiestodAnsokanConstants'

interface StudiestodAnsokanRouteParams {
    arendeId: string
    ansokanId: string
    step?: StudiestodStepType
}

const useFormStepUrl = () => {
    const { navigateInsideMf } = useNavigateToPage()
    const routeMatch = useRouteMatch<StudiestodAnsokanRouteParams>({
        path: studiestodAnsokanDetailPath,
    })

    const params = routeMatch?.params

    const urlStep = params?.step?.toLowerCase() as StudiestodStepType

    const isValidStudiestodStep = Object.values(StudiestodStepEnum).includes(urlStep)

    const activeStep = isValidStudiestodStep ? urlStep : StudiestodStepEnum.ANSOKAN

    const changeStep = (str: StudiestodStepType) => navigateInsideMf(str)

    useEffect(() => {
        if (isValidStudiestodStep) return
        const newUrl = generatePath(studiestodAnsokanDetailPath, {
            ...params,
            step: activeStep,
        })
        navigateInsideMf(newUrl)
    }, [isValidStudiestodStep, activeStep, generatePath])

    return {
        changeStep,
        activeStep,
    }
}

export default useFormStepUrl
