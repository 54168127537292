import { SelectOption } from '@local/src/components/FormComponents/Dropdown/Dropdown.types'

export const generateSkalOptions = ({
    skalEjCsnBerattigadText,
    skalEkonomiskSituationText,
    skalPersonligSituationText,
    skalAnnatText,
}: {
    skalEjCsnBerattigadText: string
    skalEkonomiskSituationText: string
    skalPersonligSituationText: string
    skalAnnatText: string
}): SelectOption[] => [
    { value: 1, label: skalEjCsnBerattigadText },
    { value: 2, label: skalEkonomiskSituationText },
    { value: 3, label: skalPersonligSituationText },
    { value: 0, label: skalAnnatText },
]

export const generateBeskrivningOptions = ({
    beskrivningSlutPaCsn,
    beskrivningAlder,
    beskrivningTidigareSkuld,
    beskrivningOtillrackligaStudieresultat,
}: {
    beskrivningSlutPaCsn: string
    beskrivningAlder: string
    beskrivningTidigareSkuld: string
    beskrivningOtillrackligaStudieresultat: string
}): SelectOption[] => [
    {
        label: beskrivningSlutPaCsn,
        value: 'Slut på CSN',
    },
    {
        label: beskrivningAlder,
        value: 'Ålder',
    },
    {
        label: beskrivningTidigareSkuld,
        value: 'Tidigare skuld',
    },
    {
        label: beskrivningOtillrackligaStudieresultat,
        value: 'Otillräckliga studieresultat',
    },
]
