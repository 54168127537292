import { BegaranYttrandeStatus } from '@local/src/services/SwaggerApi/data-contracts'
import { Box, Chip } from '@mui/material'
import React from 'react'

interface Props {
    status: BegaranYttrandeStatus
}
export const BegaranYttrandeListItemChip = ({ status }: Props) => {
    const getStatusText = () => {
        switch (status) {
            case BegaranYttrandeStatus.Ny:
                return 'Ny'
            case BegaranYttrandeStatus.UnderBedomning:
                return 'Under bedömning'
            case BegaranYttrandeStatus.Skickad:
                return 'Skickad till CSN'
            case BegaranYttrandeStatus.BesvaradWithAdhocYttrande:
                return 'Besvarad med ad hoc'
            default:
                return 'Status okänd'
        }
    }

    const getTestId = () => {
        switch (status) {
            case BegaranYttrandeStatus.Ny:
                return 'new'
            case BegaranYttrandeStatus.UnderBedomning:
                return 'saved'
            case BegaranYttrandeStatus.Skickad:
                return 'sent'
            case BegaranYttrandeStatus.BesvaradWithAdhocYttrande:
                return 'adhoc'
            default:
                return 'unknown'
        }
    }

    const getStatusChipVariant = () => {
        switch (status) {
            case BegaranYttrandeStatus.Ny:
            case BegaranYttrandeStatus.UnderBedomning:
                return 'status-info'
            case BegaranYttrandeStatus.Skickad:
            case BegaranYttrandeStatus.BesvaradWithAdhocYttrande:
                return 'status-success'
            default:
                return 'status-info'
        }
    }

    return (
        <Box display={'flex'}>
            <Chip variant={getStatusChipVariant()} label={getStatusText()} data-testid={`begaran-yttrande-status-chip-${getTestId()}`} />
        </Box>
    )
}
