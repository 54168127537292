import { Box, SxProps, TextField, Theme } from '@mui/material'
import { isEmpty, isNotNil } from 'ramda'
import React from 'react'
import { useFormContext } from 'react-hook-form'

interface IInputField {
    name: string
    label: string
    disabled?: boolean
    testId?: string
    wrapperSx?: SxProps<Theme>
    fullWidth?: boolean
}

export const TextInputField = ({ name, label, disabled, testId, wrapperSx = {}, fullWidth = true }: IInputField) => {
    const { register, getFieldState, watch } = useFormContext()
    const { error } = getFieldState(name)
    const watchValue = watch(name)
    const isValidValue = isNotNil(watchValue) && !isEmpty(watchValue)

    return (
        <Box sx={wrapperSx}>
            <TextField
                {...register(name, {
                    setValueAs: (val: string) => val,
                })}
                inputProps={{
                    'data-testid': testId || name,
                }}
                InputLabelProps={{ shrink: isValidValue }}
                label={label}
                disabled={disabled}
                error={Boolean(error?.message)}
                helperText={error?.message}
                fullWidth={fullWidth}
            />
        </Box>
    )
}
