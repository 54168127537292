import { useGetCurrentArende } from '@local/src/features/StudiestodAnsokan/Hooks'
import { UtbetalningsforutsattningarApiResponse } from '@local/src/features/StudiestodAnsokan/Models/StudiestodansokanApiResponse'
import { format } from 'date-fns'
import { isNil } from 'ramda'
import React, { Fragment } from 'react'
import { Alert, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

export interface IVarningarForEkonomiskStod {
    utbetalningsforutsattningar: UtbetalningsforutsattningarApiResponse
}

const VarningarForEkonomiskStod = ({ utbetalningsforutsattningar }: IVarningarForEkonomiskStod) => {
    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokan.steps.radgivarensUnderlag' })

    const { arende, isLoading } = useGetCurrentArende()

    if (isNil(arende) || isNil(utbetalningsforutsattningar) || isLoading) return null

    const {
        hasLon,
        hasAvgangsvederlag,
        hasOmstallningsdagarSlutInnanStudiestart,
        hasFemarsdatumInnanStudiestart,
        hasPensionsdatumInnanStudiestart,
    } = utbetalningsforutsattningar
    const formatDateString = (date: Date) => format(date ? date : new Date(), 'yyyy-MM-dd')

    const sistaAnstallningsdagDateString = formatDateString(new Date(arende?.omstallningsArende?.inkomstuppgifter?.sistaDagMedLon))
    const avgangsvederlagSlutDatumDateString = formatDateString(
        new Date(arende?.omstallningsArende?.anstallningsuppgifter?.avgangsvederlagSlutDatum)
    )
    const slutDatumRadgivningDateString = formatDateString(new Date(arende?.omstallningsArende?.radgivning?.slutDatum))

    return (
        <Fragment>
            {hasLon && (
                <Alert severity="warning" data-testid="varningar-for-ekonomisk-stod-hasLon">
                    {t('varningSistaDagMedLon', { dateString: sistaAnstallningsdagDateString })}
                </Alert>
            )}

            {hasAvgangsvederlag && (
                <Alert severity="warning" data-testid="varningar-for-ekonomisk-stod-hasAvgangsvederlag">
                    {t('varningAvgangsvederlag', { dateString: avgangsvederlagSlutDatumDateString })}
                </Alert>
            )}

            {hasOmstallningsdagarSlutInnanStudiestart && (
                <Alert severity="warning" data-testid="varningar-for-ekonomisk-stod-hasOmstallningsdagarSlutInnanStudiestart">
                    {t('varningOmstallningsdagarSlutInnanStudiestart', { dateString: slutDatumRadgivningDateString })}
                </Alert>
            )}

            {hasFemarsdatumInnanStudiestart && (
                <Alert severity="warning" data-testid="varningar-for-ekonomisk-stod-hasFemarsdatumInnanStudiestart">
                    {t('varningFemArsGransen', { dateString: slutDatumRadgivningDateString })}
                </Alert>
            )}

            {hasPensionsdatumInnanStudiestart && (
                <Stack spacing={2}>
                    <Alert severity="warning" data-testid="varningar-for-ekonomisk-stod-hasPensionsdatumInnanStudiestart">
                        {t('varningPension', { dateString: slutDatumRadgivningDateString })}
                    </Alert>
                </Stack>
            )}
        </Fragment>
    )
}

export default VarningarForEkonomiskStod
