import { Checkbox } from '@local/src/components/FormComponents'
import React, { useEffect } from 'react'
import { Path } from 'react-hook-form'
import { KalenderhalvarApiModel } from '@local/src/services/SwaggerApi/data-contracts'
import { veckorQuantity } from '@local/src/features/StudiestodAnsokan/Components/Steps/RadgivarensUnderlag/Helpers/veckorQuantity'
import { Box, Typography } from '@mui/material'
import { useRadgivarensUnderlagFormContext } from '@local/src/features/StudiestodAnsokan/Components/Steps/RadgivarensUnderlag/Hooks'
import { useTranslation } from 'react-i18next'
import NumberInputField from '@local/src/components/FormComponents/InputField/NumberInputField'

interface Props {
    kalenderhalvarIndex: number
    utbildningsIndex: number
    isReadonly: boolean
}

const Studielon = ({ kalenderhalvarIndex, utbildningsIndex, isReadonly }: Props) => {
    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokan.steps.radgivarensUnderlag' })

    const kalenderhalvarPath = `utbildningar.${utbildningsIndex}.studieersattningAndStudielon.kalenderhalvar.${kalenderhalvarIndex}`
    const getKalenderhalvarPathPath = (path: Path<KalenderhalvarApiModel>) => `${kalenderhalvarPath}.${path}`

    const { watch, resetField, setValue } = useRadgivarensUnderlagFormContext()

    const hasStudielon = watch(`utbildningar.${utbildningsIndex}.studiestodTyp.hasStudielon`)
    const hasNoStudielon = watch(
        `utbildningar.${utbildningsIndex}.studieersattningAndStudielon.kalenderhalvar.${kalenderhalvarIndex}.hasNoStudielon`
    )
    const studielonValue = watch(
        `utbildningar.${utbildningsIndex}.studieersattningAndStudielon.kalenderhalvar.${kalenderhalvarIndex}.studielon`
    )

    const disableStudielonInputField = !hasStudielon || hasNoStudielon

    useEffect(() => {
        if (hasNoStudielon) {
            setValue(
                `utbildningar.${utbildningsIndex}.studieersattningAndStudielon.kalenderhalvar.${kalenderhalvarIndex}.studielon`,
                undefined
            )
        }
    }, [hasNoStudielon, utbildningsIndex, kalenderhalvarIndex, resetField])

    // TODO: array value is not properly updated by React Hook Form, need to pass from outside for now
    return (
        <Box flex={1}>
            <Typography mb={1}>{t('preliminarStudielonText')}</Typography>
            <NumberInputField
                label={t('kronorPerDagText')}
                name={getKalenderhalvarPathPath('studielon')}
                disabled={disableStudielonInputField || isReadonly}
            />
            <Checkbox label="Ingen studielön" name={getKalenderhalvarPathPath('hasNoStudielon')} disabled={!hasStudielon || isReadonly} />
            <Typography>
                {veckorQuantity(studielonValue)} {t('kronorPerFyraVeckorText')}
            </Typography>
        </Box>
    )
}

export default Studielon
