import { Button, Stack, Typography } from '@mui/material'
import React from 'react'

import { useRadgivarensUnderlagFormContext } from '../../Hooks'

interface Props {
    draftSubmitDescription: string
    draftButtonText: string
    submitButtonText: string
    isReadonly: boolean
}

const DraftSubmitButton = ({ draftSubmitDescription, draftButtonText, submitButtonText, isReadonly }: Props) => {
    const {
        setValue,
        formState: { isSubmitting },
    } = useRadgivarensUnderlagFormContext()
    const handleIsUpdate = () => setValue('isUpdate', true)
    const handleIsPublished = () => setValue('isUpdate', false)

    return isReadonly ? null : (
        <Stack spacing={2}>
            <Typography>{draftSubmitDescription}</Typography>
            <Stack direction="row" spacing={2}>
                <Button variant="outlined" type="submit" onClick={handleIsUpdate} data-testid="draft-button" disabled={isSubmitting}>
                    {draftButtonText}
                </Button>
                <Button variant="contained" type="submit" onClick={handleIsPublished} data-testid="submit-button" disabled={isSubmitting}>
                    {submitButtonText}
                </Button>
            </Stack>
        </Stack>
    )
}

export default DraftSubmitButton
