import React, { Fragment } from 'react'
import { UtbildningApiModel } from '@local/src/services/SwaggerApi/data-contracts'
import { isNotNil } from 'ramda'
import { formatToDate } from '@local/src/utils/helpers/dateTimeFormatHelper'
import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography, Grid } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useTranslation } from 'react-i18next'

import { useMapUtbildningstypValueToLabel } from '../../Hooks'
import LabelValue from '../LabelValue/LabelValue'
import { useRadgivarensUnderlagFormContext } from '../Steps/RadgivarensUnderlag/Hooks'

import { mapUtbildningsavgiftLabel, mapUtbildningslangd } from './Helpers'

interface Props {
    utbildning: UtbildningApiModel
    children?: React.ReactNode | React.ReactNode[]
    index: number
}

const Utbildning = ({ utbildning, children, index }: Props) => {
    const errors = useRadgivarensUnderlagFormContext()?.formState?.errors
    const [expanded, setExpanded] = React.useState(true)

    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokan.steps.radgivarensUnderlag.utbildning' })

    const currentErrors = errors?.utbildningar?.[index]
    const utbildningHasErroredFields = isNotNil(currentErrors)

    const expansionId = `accordion-id-${utbildning.id}`

    return (
        <Fragment>
            <Accordion
                expanded={expanded}
                onChange={() => setExpanded(!expanded)}
                sx={(theme) => ({
                    border: utbildningHasErroredFields ? `1px solid ${theme.palette.error.main}` : 'initial',
                })}
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`${expansionId}-content`} id={`${expansionId}-header`}>
                    <Stack spacing={1}>
                        <Typography sx={{ wordBreak: 'break-word' }} variant="h5">
                            {utbildning?.utbildningsnamn}
                        </Typography>
                        <Typography sx={{ wordBreak: 'break-word' }}>{utbildning?.anordnare}</Typography>
                    </Stack>
                </AccordionSummary>
                <AccordionDetails sx={{ pt: 0 }}>
                    <Stack spacing={2}>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={6}>
                                <LabelValue label={t('studiestartText')} value={formatToDate(utbildning?.studiestart)} />
                            </Grid>
                            <Grid item xs={6}>
                                <LabelValue
                                    label={t('utbildningstypText')}
                                    value={useMapUtbildningstypValueToLabel(utbildning?.utbildningstyp)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <LabelValue
                                    label={t('utbildningslangdText')}
                                    value={mapUtbildningslangd(utbildning?.antalTerminer, utbildning.utbildningLangdEnhet)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <LabelValue
                                    label={t('utbildningsavgiftText')}
                                    value={mapUtbildningsavgiftLabel(utbildning?.isKoptUtbildning)}
                                />
                            </Grid>
                            <Grid item>
                                <LabelValue label={t('studietaktText')} value={`${utbildning?.omfattning} %`} />
                            </Grid>
                        </Grid>
                        {children}
                    </Stack>
                </AccordionDetails>
            </Accordion>
        </Fragment>
    )
}

export default Utbildning
