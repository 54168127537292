import React, { Fragment } from 'react'
import { Divider, Stack, Typography } from '@mui/material'
import { ExternalLink } from '@local/src/components/ExternalLink'
import { useTranslation } from 'react-i18next'
import NumberInputField from '@local/src/components/FormComponents/InputField/NumberInputField'

interface Props {
    utbildningsIndex: number
    isReadonly: boolean
}

const KoptUtbildning = ({ isReadonly, utbildningsIndex }: Props) => {
    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokan.steps.radgivarensUnderlag' })

    return (
        <Fragment>
            <Divider />
            <Stack spacing={2}>
                <Typography variant="h5">{t('koptUtbildningHeader')}</Typography>
                <Typography>{t('koptUtbildningDescription')}</Typography>
                <ExternalLink
                    linkText={t('koptUtbildningKDBLinkText')}
                    testId="kopt-utbildning-KDB-link"
                    href={t('koptUtbildningKDBLink')}
                />
                <Typography>{t('koptUtbildningValutaomvandlareDescription')}</Typography>
                <ExternalLink
                    linkText={t('koptUtbildningValutaomvandlareLinkText')}
                    testId="kopt-utbildning-valutaomvandlare-link"
                    href={t('koptUtbildningValutaomvandlareLink')}
                />
                <NumberInputField
                    name={`utbildningar.${utbildningsIndex}.koptUtbildning.kostnad`}
                    label={t('koptUtbildningInputLabel')}
                    disabled={isReadonly}
                    testId={`utbildningar.${utbildningsIndex}.koptUtbildning.kostnad`}
                />
            </Stack>
        </Fragment>
    )
}

export default KoptUtbildning
