import { ReactQueryKey } from '@local/src/services/apiEndpointHelper'
import { HubConnection } from '@microsoft/signalr'
import { useQueryClient } from '@tanstack/react-query'
import { log_error } from '@local/src/utils/helpers/logger'

import useSignalRJoinGroup from './useSignalRJoinGroup'
import useSignalROnTargetMethod from './useSignalROnTargetMethod'

const useSignalRStudiestodListEvent = (hub: HubConnection, arendeId: string) => {
    const queryClient = useQueryClient()

    useSignalRJoinGroup({
        hub: hub,
        groupName: 'StudiestodEvent',
        groupIdentifier: arendeId,
    })

    useSignalROnTargetMethod({
        hub: hub,
        targetMethod: 'StudiestodNummerIsSetEvent',
        callBack: () => {
            queryClient
                .invalidateQueries([ReactQueryKey.studiestodList])
                .catch((error) => log_error('Error invalidateQueries: studiestodList, on StudiestodNummerIsSetEvent callback ', { error }))
        },
    })

    useSignalROnTargetMethod({
        hub: hub,
        targetMethod: 'StudiestodDeletedEvent',
        callBack: () => {
            queryClient
                .invalidateQueries([ReactQueryKey.studiestodList])
                .catch((error) => log_error('Error invalidateQueries: studiestodList, on StudiestodDeletedEvent callback ', { error }))
        },
    })
}

export default useSignalRStudiestodListEvent
