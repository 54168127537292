import { ApiResponseModel } from '@local/src/services/ApiResponseModel'
import { useEffect, useState } from 'react'
import { studiestodMedarbetareApi } from '@local/src/services/apiEndpointHelper'
import { apiRequestTryCatchWrapper } from '@local/src/services/apiRequestHelper'
import { useTranslation } from 'react-i18next'

import { useGetCurrentStudiestodansokan } from '../../../../Hooks'

export const useTaBeslutForStudiestodAnsokan = () => {
    const [beslutResponse, setBeslutResponse] = useState<ApiResponseModel<void>>(undefined)
    const [isPosting, setIsPosting] = useState(false)

    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokan.steps.beslut' })

    const {
        studiestodAnsokan: { isBeslutad, canTaBeslut, arendeId, id: ansokanId },
        invalidateQuery,
    } = useGetCurrentStudiestodansokan()

    useEffect(() => {
        setBeslutResponse(undefined)
    }, [isBeslutad])

    const handleTaBeslut = async () => {
        if (!canTaBeslut) {
            setBeslutResponse({
                hasError: true,

                isSuccess: false,
                error: {
                    errorMessage: t('canNotTaBeslutInfoMessage'),
                    errorType: 'Generic Error',
                    status: 500,
                },
            })
            return
        }
        setIsPosting(true)
        const res = await apiRequestTryCatchWrapper(
            'useTaBeslutForStudiestodAnsokan',
            studiestodMedarbetareApi.arendeAnsokanBeslutPartialUpdate,
            arendeId,
            ansokanId
        )

        setBeslutResponse(res)
        await invalidateQuery()

        setIsPosting(false)
    }

    return { isPosting, beslutResponse, handleTaBeslut }
}
