import { StudiestodApiModel } from '@local/src/services/SwaggerApi/data-contracts'
import React, { Fragment } from 'react'
import { Box, Divider, Stack, Typography } from '@mui/material'
import Icons from '@local/src/components/Icons'
import { useTranslation } from 'react-i18next'

import LabelValue from '../../../LabelValue/LabelValue'

const AnsokanOmStudiestodElements = (ansokan: StudiestodApiModel) => {
    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokan.steps.ansokanOmStudiestod' })

    const Villkor = () => (
        <section data-testid="studiestod-villkor">
            <Stack direction="row" spacing={2} alignItems="center">
                <Icons.Check />
                <Typography whiteSpace="break-spaces">{t('forsakranText')}</Typography>
            </Stack>
        </section>
    )

    const Motivering = ({ label, value }: { label: string; value: string }) => (
        <Box>
            <Typography fontWeight="bold">{label}</Typography>
            <Typography sx={{ wordBreak: 'break-word' }}>{value}</Typography>
        </Box>
    )

    const SyfteMedStudier = () => (
        <Motivering
            label={t('utbildningsforutsattningText')}
            value={
                ansokan?.utbildningsforutsattning === 'omskolning'
                    ? t('utbildningsforutsattningOmskolningText')
                    : t('utbildningsforutsattningVidareutbildningText')
            }
        />
    )

    const Nyanstallning = () => <Motivering label={t('nyanstallningText')} value={ansokan?.motivering?.nyanstallning} />

    const Arbetsmarknad = () => <Motivering label={t('arbetsmarknadText')} value={ansokan?.motivering?.arbetsmarknad} />

    const KompletteringErfarenhet = () => (
        <Motivering label={t('kompletteringErfarenhetText')} value={ansokan?.motivering?.kompletteringErfarenhet} />
    )

    const Ovrigt = () => <Motivering label={t('utbildningsalternativText')} value={ansokan?.motivering?.utbildningsalternativ} />

    const StudiestodFranTrr = () => (
        <Fragment>
            <Divider />
            <Typography variant="h5">{t('studiestodFranTrrTitle')}</Typography>
            <LabelValue label={t('antalTerminerStudiestodText')} value={`${ansokan.utbildningar[0].antalTerminerStudiestod} terminer`} />
        </Fragment>
    )

    const InformationOmCsn = () => {
        const renderJaNej = (isTrue: boolean) => (isTrue ? 'Ja' : 'Nej')
        return (
            <Fragment>
                <Divider />
                <Typography variant="h5">{t('informationOmCsnTitle')}</Typography>
                <LabelValue
                    label={t('studiemedelsberattigadUtbildningText')}
                    value={renderJaNej(ansokan.utbildningar[0].studiemedelsberattigadUtbildning)}
                />
                <LabelValue label={t('avserSokaCsnText')} value={renderJaNej(ansokan.utbildningar[0].avserSokaCsn)} />
                {ansokan.utbildningar[0].ejCsnAnledning && (
                    <LabelValue label={t('ejCsnAnledningText')} value={ansokan.utbildningar[0].ejCsnAnledning} />
                )}
                <LabelValue label={t('kraverProvningText')} value={renderJaNej(ansokan.utbildningar[0].kraverProvning)} />
            </Fragment>
        )
    }

    return { Villkor, SyfteMedStudier, Nyanstallning, Arbetsmarknad, KompletteringErfarenhet, Ovrigt, StudiestodFranTrr, InformationOmCsn }
}

export default AnsokanOmStudiestodElements
