import React from 'react'
import { useGetCurrentStudiestodansokan } from '@local/src/features/StudiestodAnsokan/Hooks'
import Icons from '@local/src/components/Icons'
import { Box, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

const GodkandAv = () => {
    const { studiestodAnsokan } = useGetCurrentStudiestodansokan()
    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokan.steps.beslut' })
    const beslutatAv = `${studiestodAnsokan?.beslut?.beslutatAvUser?.firstName} ${studiestodAnsokan?.beslut?.beslutatAvUser?.lastName}`

    return (
        <Stack
            alignItems={'center'}
            direction={'row'}
            mb={2}
            padding={2}
            data-testid="beslut-checkmark"
            borderRadius={1}
            justifyContent={'center'}
            sx={(theme) => ({ border: `1px solid ${theme.palette.grey[400]}` })}
        >
            <Box mr={3}>
                <Icons.Check />
            </Box>
            <Box>
                <Typography fontWeight="bold">{t('godkannAvHeaderText')}</Typography>
                <Typography>
                    {`${beslutatAv}`} {t('godkannAvLeadText')}
                </Typography>
            </Box>
        </Stack>
    )
}

export default GodkandAv
