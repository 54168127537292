import Loader from '@local/src/components/Loader'
import { Box } from '@mui/material'
import { BoxProps } from '@mui/material/Box/Box'
import React, { Fragment } from 'react'

interface Props extends BoxProps {
    headerText?: string
    children: React.ReactNode
    descriptionText?: string
    errorLoadingData?: boolean
    errorText?: string
    isLoading?: boolean
}

const PageWrapper = ({ errorLoadingData = false, descriptionText, isLoading, errorText, headerText, children, ...props }: Props) => {
    if (errorLoadingData) {
        return <p data-testid="error-loading-data">{errorText}</p>
    }

    return (
        <Box data-testid="page-wrapper" {...props}>
            {headerText && <h3>{headerText}</h3>}
            {descriptionText && <p>{descriptionText}</p>}
            {isLoading ? (
                <div data-testid="page-loader">
                    <Loader />
                </div>
            ) : (
                <Fragment>{children}</Fragment>
            )}
        </Box>
    )
}

export default PageWrapper
