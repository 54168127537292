import React from 'react'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Box, ThemeOptions, ThemeProvider, createTheme } from '@mui/material'
import { createReactQueryClient, initTranslations } from '@local/src/utils/appBootstrapper'
import { defaultLocale, themeOptions } from '@trr/mui-theme'
import { AppShellDataProps, AppShellDataProvider } from '@trr/app-shell-data'
import { injectStyle } from 'react-toastify/dist/inject-style'
import { SnackbarProvider } from 'notistack'

import StudierTab from './pages/StudierTab'
import { ToasterContainer } from './components/Toaster/Toaster'

initTranslations()
injectStyle()

const reactQueryClient = createReactQueryClient()

const customTheme: ThemeOptions = {
    components: {
        MuiAccordionSummary: {
            defaultProps: {
                sx: { userSelect: 'auto' },
            },
        },
    },
}

const theme = createTheme(themeOptions, defaultLocale, customTheme)

const App = ({ appShellData }: { appShellData: AppShellDataProps }) => {
    return (
        <QueryClientProvider client={reactQueryClient}>
            <ThemeProvider theme={theme}>
                <Box data-testid="medarbetare-studier-fe">
                    <AppShellDataProvider value={appShellData}>
                        <SnackbarProvider anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                            <StudierTab />
                            <ToasterContainer />
                        </SnackbarProvider>
                    </AppShellDataProvider>
                    <ReactQueryDevtools />
                </Box>
            </ThemeProvider>
        </QueryClientProvider>
    )
}

export default App
