import React from 'react'
import { CsnBeslutsperiodBeslutApiModel } from '@local/src/services/SwaggerApi/data-contracts'
import { Box, Grid2 as Grid, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import LabelValue from '../../../../LabelValue/LabelValue'

interface Props {
    studieperioder: CsnBeslutsperiodBeslutApiModel[] | null
}

const BeslutFranCsnListItemStudieperioder = ({ studieperioder }: Props) => {
    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokan.steps.csn' })

    const hasStudiePerioder = studieperioder && studieperioder.length > 0

    return (
        <Box data-testid="beslut-fran-csn-studieperioder">
            {hasStudiePerioder ? (
                studieperioder.map(({ fromVecka, tomVecka, omfattningBidrag, godkandStudieforsakran }, index) => (
                    <Stack key={index} spacing={1}>
                        <Typography variant="h6">
                            {t('studieperiod')} ({fromVecka}-{tomVecka})
                        </Typography>

                        <Grid container spacing={1}>
                            <Grid size={{ xs: 6 }}>
                                <LabelValue label={t('omfattning')} value={`${omfattningBidrag} %`} />
                            </Grid>
                            <Grid size={{ xs: 6 }}>
                                <LabelValue label={t('beslutGodkandStudieforsakran')} value={godkandStudieforsakran ? 'Ja' : 'Nej'} />
                            </Grid>
                        </Grid>
                    </Stack>
                ))
            ) : (
                <Typography data-testid="beslut-fran-csn-no-studieperioder">{t('beslutIngaStudieperioder')}</Typography>
            )}
        </Box>
    )
}

export default BeslutFranCsnListItemStudieperioder
