import { UtbildningLangdEnhet } from '@local/src/services/SwaggerApi/data-contracts'

const mapUtbildningslangd = (antalTerminer: string, utbildningslangdEnhet: UtbildningLangdEnhet) => {
    switch (utbildningslangdEnhet) {
        case UtbildningLangdEnhet.Terminer:
            return `${antalTerminer} terminer`
        case UtbildningLangdEnhet.Veckor:
            return `${antalTerminer} veckor`
        case UtbildningLangdEnhet.Dagar:
            return `${antalTerminer} dagar`
        default:
            return `${antalTerminer} okänd enhet`
    }
}

export default mapUtbildningslangd
