import { StudiestodApiResponse } from '@local/src/features/StudiestodAnsokan/Models/StudiestodansokanApiResponse'
import { Box, Typography, Switch, Drawer, Divider, IconButton, Stack, Button, Portal } from '@mui/material'
import React, { Fragment, useMemo, useState } from 'react'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Timeline } from '@mui/lab'
import useGetDomainEventsByAnsokanId from '@local/src/features/StudiestodAnsokan/Components/DomainEventsLog/Hooks/useGetDomainEventsByAnsokanId'
import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded'
import CloseIcon from '@mui/icons-material/Close'
import { useSignalRJoinGroup, useSignalROnTargetMethod } from '@local/src/hooks/SignalR'
import { useQueryClient } from '@tanstack/react-query'
import Loader from '@local/src/components/Loader'
import { useSignalR } from '@trr/app-shell-data'
import { timelineOppositeContentClasses } from '@mui/lab/TimelineOppositeContent'
import { getStudiestodTitle } from '@local/src/utils/helpers/studiestodAnsokanHelper'
import DomainEventTimeLineItem from '@local/src/features/StudiestodAnsokan/Components/DomainEventsLog/DomainEventTimeLineItem'

interface Props {
    studiestod: StudiestodApiResponse
}

const DomainEventsLog = ({ studiestod }: Props) => {
    const [open, setOpen] = useState(false)
    const [showCsnEvent, setShowCsnEvent] = useState(false)

    const { domainEvents, isFetching } = useGetDomainEventsByAnsokanId(studiestod?.arendeId, studiestod?.id, open)

    const signalR = useSignalR()
    const queryClient = useQueryClient()

    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    const studiestodId = studiestod?.id
    const medarbetare = signalR?.medarbetare

    useSignalRJoinGroup({
        hub: medarbetare,
        groupName: 'StudiestodEvent',
        groupIdentifier: studiestodId,
    })

    useSignalROnTargetMethod({
        hub: medarbetare,
        targetMethod: 'NewDomainEvent',
        callBack: () => {
            void queryClient.invalidateQueries(['domainEvents', studiestodId])
        },
    })

    const hasCsnEvents = domainEvents?.filter((f) => f.source === '/csn')?.length > 0
    const cardTitle = getStudiestodTitle(studiestod)

    const firstPublishedEventId = useMemo(
        () =>
            domainEvents.reduce(
                (lastId, obj) =>
                    ['StudiestodUpdatedEvent', 'StudiestodCreatedEvent'].includes(obj.subject) &&
                    obj.data?.Ansokan?.Publiceringsdatum !== null &&
                    obj.data?.Ansokan?.Publiceringsdatum !== undefined
                        ? obj.id
                        : lastId,
                null
            ),
        [domainEvents]
    )

    const firstUnderlagAddedEventId = useMemo(
        () => domainEvents.reduce((lastId, obj) => (obj.subject === 'StudiestodUnderlagAddedEvent' ? obj.id : lastId), null),
        [domainEvents]
    )

    const getEventsThroughFirstPublished = useMemo(() => {
        const index = domainEvents.findIndex((obj) => obj.id === firstPublishedEventId)
        if (index !== -1) {
            return domainEvents.slice(0, index + 1)
        }
        return domainEvents
    }, [domainEvents, firstPublishedEventId])

    const filteredDomainEvents = useMemo(() => {
        return getEventsThroughFirstPublished
            .map((obj) => {
                if (obj.id === firstUnderlagAddedEventId) {
                    return { ...obj, subject: 'StudiestodUnderlagAddedFirstTimeEvent' }
                }
                if (obj.id === firstPublishedEventId) {
                    return { ...obj, subject: 'StudiestodAnsokanPublishedFirstTimeEvent' }
                }
                return obj
            })
            .filter((obj) => obj && (showCsnEvent || obj.source !== '/csn'))
    }, [getEventsThroughFirstPublished, showCsnEvent, firstUnderlagAddedEventId, firstPublishedEventId])

    return (
        <Fragment>
            <Button
                variant="text"
                onClick={open ? handleClose : handleOpen}
                size="small"
                startIcon={open ? <CloseIcon /> : <HistoryRoundedIcon />}
            >
                Visa händelser
            </Button>
            <Portal>
                <Drawer
                    sx={{
                        flexShrink: 0,

                        '& .MuiDrawer-paper': {
                            width: {
                                xs: '100%',
                                sm: '60%',
                                md: '40%',
                                lg: '30%',
                                xl: 'calc((100% - 960px)/2)',
                            },
                        },
                    }}
                    variant="persistent"
                    anchor="right"
                    open={open}
                >
                    <Box sx={{ display: 'flex' }} alignItems="center" p={1} justifyContent="flex-start">
                        <IconButton onClick={handleClose}>{open ? <ChevronRightIcon /> : <ChevronLeftIcon />}</IconButton>
                        <Stack direction="column" spacing={1 / 2}>
                            <Typography variant="body2" color="text.secondary">
                                Händelser för:
                            </Typography>
                            <Typography
                                sx={{
                                    display: '-webkit-box',
                                    WebkitLineClamp: 2,
                                    WebkitBoxOrient: 'vertical',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    wordBreak: 'break-word',
                                }}
                                variant="subtitle1"
                                color="text.primary"
                                maxWidth={400}
                            >
                                {cardTitle}
                            </Typography>
                        </Stack>
                    </Box>
                    <Divider />
                    {hasCsnEvents && (
                        <Stack direction="row" spacing={1} alignItems="center" px={2} justifyContent="flex-start">
                            <Typography variant="subtitle2" color="text.secondary">
                                Visa även CSN-händelser
                            </Typography>
                            <Switch checked={showCsnEvent} onChange={(e, c) => setShowCsnEvent(c)} />
                        </Stack>
                    )}
                    <Divider />
                    <Box>
                        <Timeline
                            sx={{
                                paddingLeft: 0,
                                paddingRight: 0,
                                [`& .${timelineOppositeContentClasses.root}`]: {
                                    flex: 0.1,
                                },
                            }}
                        >
                            {isFetching ? (
                                <Loader />
                            ) : (
                                filteredDomainEvents.map((de) => (
                                    <DomainEventTimeLineItem
                                        key={de.id}
                                        domainEvent={de}
                                        prevEvents={domainEvents.filter((f) => new Date(f.time) < new Date(de.time))}
                                    />
                                ))
                            )}
                        </Timeline>
                    </Box>
                </Drawer>
            </Portal>
        </Fragment>
    )
}

export default DomainEventsLog
