import { useRouteMatch } from 'react-router-dom'
import { studiestodMedarbetareApi } from '@local/src/services/apiEndpointHelper'
import { UpdateUnderlagApiModel } from '@local/src/services/SwaggerApi/data-contracts'
import { apiRequestTryCatchWrapper } from '@local/src/services/apiRequestHelper'
import { studiestodAnsokanDetailPath } from '@local/src/features/StudiestodAnsokan/Utils/studiestodAnsokanConstants'

const useRadgivarensUnderlagSubmit = () => {
    const {
        params: { arendeId, ansokanId },
    } = useRouteMatch<{ arendeId: string; ansokanId: string }>({ path: studiestodAnsokanDetailPath })

    const submitRadgivarensUnderlag = (updateUnderlagRequestModel: UpdateUnderlagApiModel, shouldPublish: boolean) => {
        const queryData = { arendeId: arendeId, ansokanId: ansokanId, shouldPublish }
        return apiRequestTryCatchWrapper(
            'useRadgivarensUnderlagSubmit',
            studiestodMedarbetareApi.arendeAnsokanRadgivarunderlagPartialUpdate,
            queryData,
            updateUnderlagRequestModel
        )
    }

    return {
        submitRadgivarensUnderlag,
    }
}

export default useRadgivarensUnderlagSubmit
