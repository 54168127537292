import React from 'react'
import { Alert, AlertTitle, Stack, Typography } from '@mui/material'
import { ExternalLink } from '@local/src/components/ExternalLink'
import EmailIcon from '@mui/icons-material/Email'
import { useTranslation } from 'react-i18next'

import { AktivitetsstodEntry, IAktivitetsstodEntry } from './AktivitetsstodEntry'

const AktivitetsstodProcess = () => {
    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokan.steps.radgivarensUnderlag' })

    const aktivitetsstodList1: IAktivitetsstodEntry = {
        header: t('aktivitetsstodListEntry1Header'),
        content: t('aktivitetsstodListEntry1Content'),
        entries: [
            {
                header: t('aktivitetsstodListEntry1Entry1Header'),
                content: (
                    <Typography color="text.secondary" variant="subtitle2">
                        {t('aktivitetsstodListEntry1Entry1Content1')}
                    </Typography>
                ),
            },
            {
                header: t('aktivitetsstodListEntry1Entry2Header'),
                content: (
                    <Stack spacing={1}>
                        <Typography color="text.secondary" variant="subtitle2">
                            {t('aktivitetsstodListEntry1Entry2Content1')}
                        </Typography>
                        <Typography color="text.secondary" variant="subtitle2">
                            {t('aktivitetsstodListEntry1Entry2Content2')}
                        </Typography>
                        <Typography color="text.secondary" variant="subtitle2">
                            {t('aktivitetsstodListEntry1Entry2Content3')}
                        </Typography>
                    </Stack>
                ),
            },
            {
                header: t('aktivitetsstodListEntry1Entry3Header'),
                content: (
                    <Stack spacing={1}>
                        <Typography color="text.secondary" variant="subtitle2">
                            {t('aktivitetsstodListEntry1Entry3Content1')}
                        </Typography>
                        <Typography color="text.secondary" variant="subtitle2">
                            {t('aktivitetsstodListEntry1Entry3Content2')}
                        </Typography>
                        <ExternalLink
                            linkText={t('aktivitetsstodListEntry1Entry3ContentLinkName')}
                            testId="csn-external-link"
                            href={t('aktivitetsstodListEntry1Entry3ContentLink')}
                        />
                    </Stack>
                ),
            },
        ],
    }

    const aktivitetsstodList2: IAktivitetsstodEntry = {
        header: t('aktivitetsstodListEntry2Header'),
        content: t('aktivitetsstodListEntry2Content'),
        entries: [
            {
                header: t('aktivitetsstodListEntry2Entry1Header'),
            },
            {
                header: t('aktivitetsstodListEntry2Entry2Header'),
            },
            {
                header: t('aktivitetsstodListEntry2Entry3Header'),
                content: (
                    <ExternalLink
                        href={`mailto:${t('aktivitetsstodListEntry2Entry3ContentEmail')}`}
                        linkText={t('aktivitetsstodListEntry2Entry3Content')}
                        customIcon={<EmailIcon />}
                    />
                ),
            },
        ],
    }

    const aktivitetsstodList3: IAktivitetsstodEntry = {
        header: t('aktivitetsstodListEntry3Header'),
        content: t('aktivitetsstodListEntry3Content'),
    }

    return (
        <Alert icon={false} severity="warning">
            <AlertTitle>
                <Typography variant="h5">{t('aktivitetsstodHeader')}</Typography>
            </AlertTitle>
            <Stack spacing={1}>
                <AktivitetsstodEntry {...aktivitetsstodList1} />
                <AktivitetsstodEntry {...aktivitetsstodList2} />
                <AktivitetsstodEntry {...aktivitetsstodList3} />
            </Stack>
        </Alert>
    )
}

export default AktivitetsstodProcess
