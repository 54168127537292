import React, { Fragment, useState } from 'react'
import { useFieldArray } from 'react-hook-form'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material'
import Icons from '@local/src/components/Icons'
import { useRadgivarensUnderlagFormContext } from '@local/src/features/StudiestodAnsokan/Components/Steps/RadgivarensUnderlag/Hooks'
import { useTranslation } from 'react-i18next'
import useCommonTranslation from '@local/src/hooks/useCommonTranslation'

interface Props {
    showRemoveButton: boolean
    kalenderhalvarIndex: number
    utbildningsIndex: number
}

const TaBortKalenderhalvarButton = ({ showRemoveButton, kalenderhalvarIndex, utbildningsIndex }: Props) => {
    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokan.steps.radgivarensUnderlag' })
    const { tCommon } = useCommonTranslation()

    const [showWarningDialog, setShowWarningDialog] = useState<boolean>(false)
    const {
        control,
        formState: { dirtyFields },
    } = useRadgivarensUnderlagFormContext()
    const { remove } = useFieldArray({
        control,
        name: `utbildningar.${utbildningsIndex}.studieersattningAndStudielon.kalenderhalvar`,
    })
    const isDirty = dirtyFields?.utbildningar?.[utbildningsIndex]?.studieersattningAndStudielon?.kalenderhalvar?.[kalenderhalvarIndex]

    const checkIfDirty = () => {
        if (isDirty) {
            setShowWarningDialog(true)
        } else {
            remove(kalenderhalvarIndex)
        }
    }

    const onConfirm = () => {
        setShowWarningDialog(false)
        remove(kalenderhalvarIndex)
    }

    const onCancel = () => {
        setShowWarningDialog(false)
    }

    return (
        <Fragment>
            {showRemoveButton && (
                <IconButton onClick={checkIfDirty} data-testid="kalenderhalvar-remove-button">
                    <Icons.Delete size="small" color="success" />
                </IconButton>
            )}

            <Dialog open={showWarningDialog} data-testid="modal-studiestodstyp">
                <DialogTitle>{t('taBortKalenderhalvarModalHeader')}</DialogTitle>
                <DialogContent>{t('taBortKalenderhalvarModalDescription')}</DialogContent>
                <DialogActions>
                    <Button data-testid="dialog--cancel-button" onClick={onCancel} variant="outlined">
                        {tCommon('button_cancel')}
                    </Button>
                    <Button data-testid="dialog--confirm-button" onClick={onConfirm} variant="contained">
                        {tCommon('button_ok')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default TaBortKalenderhalvarButton
