import { Box, TextField } from '@mui/material'
import React from 'react'
import { useFormContext } from 'react-hook-form'

interface ITextArea {
    name: string
    label?: string
    disabled?: boolean
    rows?: number
    testId?: string
    placeholder?: string
}

export const TextArea = ({ name, label, disabled, rows = 6, testId, placeholder }: ITextArea) => {
    const { register, getFieldState } = useFormContext()

    const { error } = getFieldState(name)
    return (
        <Box position="relative" mb={3}>
            <TextField
                {...register(name)}
                inputProps={{
                    'data-testid': testId || name,
                    placeholder: placeholder,
                }}
                type={'text'}
                label={label}
                disabled={disabled}
                error={Boolean(error?.message)}
                helperText={error?.message}
                multiline
                fullWidth
                minRows={rows}
                maxRows={15}
            />
        </Box>
    )
}
