import { NekaStodApiModel } from '@local/src/services/SwaggerApi/data-contracts'
import { ShouldBeUndefinedStringSchema } from '@local/src/utils/Yup/YupHelper'
import { isNil } from 'ramda'
import { object as yupObject, SchemaOf, boolean as yupBoolean, string as yupString } from 'yup'

export const NekaStodDraftSchema = (): SchemaOf<NekaStodApiModel> =>
    yupObject({
        isStodNekat: yupBoolean().nullable(),
        orsak: yupString().nullable().when('isStodNekat', {
            is: false,
            then: ShouldBeUndefinedStringSchema,
        }),
    })
        .nullable()
        .transform((value) => (isNil(value?.isStodNekat) ? null : value))

export const NekaStodSchema = (): SchemaOf<NekaStodApiModel> =>
    yupObject({
        isStodNekat: yupBoolean().nullable().required('Bevilja ekonomiskt studiestöd behöver anges'),
        orsak: yupString()
            .nullable()
            .when('isStodNekat', {
                is: true,
                then: (orsak) => orsak.required('Orsak till nekat stöd behöver anges'),
                otherwise: ShouldBeUndefinedStringSchema,
            }),
    }).required()
