import { studiestodMedarbetareApi } from '@local/src/services/apiEndpointHelper'
import { apiGetTryCatchWrapper } from '@local/src/services/apiRequestHelper'
import { useQuery } from '@tanstack/react-query'
import { isNotNil } from 'ramda'

const useGetDomainEventsByAnsokanId = (arendeId: string, ansokanId: string, enabled: boolean) => {
    const { data: domainEvents, ...query } = useQuery({
        queryKey: ['domainEvents', ansokanId],
        queryFn: () =>
            apiGetTryCatchWrapper(
                'useGetDomainEventsByAnsokanId',
                studiestodMedarbetareApi.arendeAnsokanEventLogDetail,
                ansokanId,
                arendeId
            ),
        placeholderData: [],
        enabled: enabled && isNotNil(ansokanId),
    })

    return { domainEvents, ...query }
}

export default useGetDomainEventsByAnsokanId
