import { boolean as yupBoolean, number as yupNumber, object as yupObject, string as yupString } from 'yup'
import { MessageParams } from 'yup/lib/types'

export const shouldBeNullOrUndefined = (value: string | number | boolean | undefined | null) => value === null || value === undefined

export const nullableNumberSchema = () => yupNumber().nullable()
export const nullableBooleanSchema = () => yupBoolean().nullable()
export const requiredBooleanSchema = () => yupBoolean().required()

export const ShouldBeUndefinedObjectSchema = yupObject()
    .nullable()
    .default(undefined)
    .test((obj?) => obj === undefined || obj === null)

export const ShouldBeUndefinedNumberSchema = yupNumber()
    .nullable()
    .default(undefined)
    .test((value) => shouldBeNullOrUndefined(value))

export const ShouldBeUndefinedStringSchema = yupString()
    .nullable()
    .default(undefined)
    .test((value) => shouldBeNullOrUndefined(value))

export const ShouldBeUndefinedBooleanSchema = yupBoolean()
    .nullable()
    .default(undefined)
    .test((value) => shouldBeNullOrUndefined(value))

export const ShouldBeFalseBooleanSchema = yupBoolean().test(
    'Värdet måste vara falskt',
    'Värdet måste vara falskt',
    (value) => value === false
)

type MaxLengthParams = MessageParams & {
    max: number
}
type MinLengthParams = MessageParams & {
    min: number
}

const isStringType = (value: unknown) => typeof value === 'string' || Object.prototype.toString.call(value) === '[object String]'

export const maxLengthValidationText = (lengthParam: MaxLengthParams) =>
    `Max ${isStringType(lengthParam.value) ? 'antal tecken' : 'värde'} är ${lengthParam.max}`

export const minLengthValidationText = (lengthParam: MinLengthParams) =>
    `Minsta ${isStringType(lengthParam.value) ? 'antal tecken' : 'värde'} är ${lengthParam.min}`

export const emailValidationText = 'Inte giltig email'
export const requiredValidationText = 'Du måste ange ett värde'

export const requiredWithLabelValidationText = (messageParams: MessageParams) => `Du behöver ange ${messageParams.label}`
