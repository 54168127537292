import { getCookie } from '@local/src/utils/helpers/cookieHelper'
import { applicationInsights } from '@trr/app-shell-communication'

const logEnabled =
    process.env.IS_DEBUG === 'true' || window.location.search.includes('debug=true') || getCookie<boolean>('enable-studiestod-logging')

type LogLevel = 'debug' | 'info' | 'warning' | 'error'

export const log = (level: LogLevel = 'debug', message?: string, ...args: unknown[]) => {
    switch (level) {
        case 'debug':
            log_debug(message, args)
            break
        case 'info':
            log_info(message, args)
            break
        case 'warning':
            log_warning(message, args)
            break
        case 'error':
            log_error(message, args)
            break
        default:
            log_info(message, args)
            break
    }
}

export const log_debug = (message?: string, ...args: unknown[]) => logToConsol(console.debug, 'orange', message, args)
export const log_info = (message?: string, ...args: unknown[]) => logToConsol(console.info, 'aqua', message, args)
export const log_warning = (message?: string, ...args: unknown[]) => logToConsol(console.warn, 'limegreen', message, args)
export const log_error = (message?: string, ...args: unknown[]) => {
    logToConsol(console.error, 'red', message, args)
    logErrorToAppInsights(message, args)
}

const logToConsol = (loggerMethod: (...args: unknown[]) => void, color: string, message = '', args: unknown[]) => {
    if (logEnabled) {
        console.group(`%cMedarbetare-Studiestod-FE: ${message}`, `background: ${color}; font-weight: bold; color: black;`)
        loggerMethod(...args)
        console.groupEnd()
    }
}

export const mapArgsToObject = (args: unknown[]) => {
    const argsToObject = {}
    for (const arg of args) {
        Object.assign(argsToObject, arg)
    }
    return argsToObject
}

const logErrorToAppInsights = (message?: string, args?: unknown[]) => {
    const argsToObject = mapArgsToObject(args)
    applicationInsights.trackException({
        exception: new Error(`Medarbetare-studier-FE: ${message}`),
        properties: {
            repo: 'Medarbetare-Studier-Frontend',
            ...argsToObject,
        },
    })
}
