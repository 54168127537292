import React from 'react'
import { PageWrapper } from '@local/src/components/PageWrapper'
import { StudiestodListApiModel } from '@local/src/services/SwaggerApi/data-contracts'
import { Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

import AnsokanItemCard from './Components/AnsokanItemCard/AnsokanItemCard'
import NoResult from './Components/NoResult/NoResult'
import { useGetStudiestodList } from './Hooks/useGetStudiestodList'

interface Props {
    arendeId: string
    fornamn: string
}

const StudiestodAnsokanList = ({ arendeId, fornamn }: Props) => {
    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokanList' })

    const { studiestodsansokningar, isError, isFetching, isFetchedAfterMount } = useGetStudiestodList(arendeId)
    const isLoading = !isFetchedAfterMount && isFetching
    const hasAnsokanItems = !isLoading && studiestodsansokningar && studiestodsansokningar.length > 0

    return (
        <PageWrapper headerText={t('titleAktuell')} isLoading={isLoading} errorLoadingData={isError} errorText={t('hamtningsfelText')}>
            {hasAnsokanItems ? (
                <Stack spacing={4}>
                    {studiestodsansokningar.map((ansokan: StudiestodListApiModel) => (
                        <AnsokanItemCard key={ansokan.id} ansokan={ansokan} />
                    ))}
                </Stack>
            ) : (
                <NoResult content={t('noResultDescriptionAktuell')} fornamn={fornamn} testSelector="studiestod-ansokan-no-result" />
            )}
        </PageWrapper>
    )
}

export default StudiestodAnsokanList
