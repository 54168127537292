import { StudiestodUnlockState } from '@local/src/services/SwaggerApi/data-contracts'
import { useTranslation } from 'react-i18next'

interface UnlockContent {
    unlockHeaderText: string
    unlockDescriptionText: string
    unlockButtonText: string
    forbiddenToUnlockHeaderText: string
    forbiddenToUnlockDescriptionText: string
    stateUnlockSuccessText: string
    stateUnlockFailureText: string
}

interface CommonContent {
    beslutIsNeededToBeUnlockedFirstHeader: string
    beslutIsNeededToBeUnlockedFirstDescription: string
    beslutLinkText: string
}

type UnlockStep = keyof typeof StudiestodUnlockState
const useGetUnlockForEditingTextContent = (
    unlockStep: StudiestodUnlockState,
    isForbiddenToUnlockForEditing: boolean,
    isNeededToFirstUnlockBeslut: boolean,
    isRadgivarunderlagPublished: boolean
) => {
    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokan.studiestodUnlockForEditingActionBox' })

    const unlockContent: Record<UnlockStep, UnlockContent> = {
        Ansokan: {
            unlockHeaderText: t('ansokanIsLockedForEditingHeader'),
            unlockDescriptionText: t('ansokanIsLockedForEditingDescription'),
            unlockButtonText: t('ansokanIsLockedForEditingUnlockButtonText'),
            forbiddenToUnlockHeaderText: t('ansokanIsForbiddenToUnlockHeader'),
            forbiddenToUnlockDescriptionText: t('ansokanIsForbiddenToUnlockDescription'),
            stateUnlockSuccessText: isRadgivarunderlagPublished
                ? t('ansokanUnlockAnsokanAndUnderlagSuccessText')
                : t('ansokanUnlockSuccessText'),
            stateUnlockFailureText: t('ansokanUnlockFailureText'),
        },
        Radgivarunderlag: {
            unlockHeaderText: t('underlagIsLockedForEditingHeader'),
            unlockDescriptionText: t('underlagIsLockedForEditingDescription'),
            unlockButtonText: t('underlagIsLockedForEditingUnlockButtonText'),
            forbiddenToUnlockHeaderText: t('underlagIsForbiddenToUnlockHeader'),
            forbiddenToUnlockDescriptionText: t('underlagIsForbiddenToUnlockDescription'),
            stateUnlockSuccessText: t('underlagUnlockSuccessText'),
            stateUnlockFailureText: t('underlagUnlockFailureText'),
        },
        Beslut: {
            unlockHeaderText: t('beslutIsLockedForEditingHeader'),
            unlockDescriptionText: t('beslutIsLockedForEditingDescription'),
            unlockButtonText: t('beslutIsLockedForEditingUnlockButtonText'),
            forbiddenToUnlockHeaderText: t('beslutIsForbiddenToUnlockHeader'),
            forbiddenToUnlockDescriptionText: t('beslutIsForbiddenToUnlockDescription'),
            stateUnlockSuccessText: t('beslutUnlockSuccessText'),
            stateUnlockFailureText: t('beslutUnlockFailureText'),
        },
        Unknown: undefined,
    }

    const commonContent: CommonContent = {
        beslutIsNeededToBeUnlockedFirstHeader: t('beslutIsNeededToBeUnlockedFirstHeader'),
        beslutIsNeededToBeUnlockedFirstDescription: t('beslutIsNeededToBeUnlockedFirstDescription'),
        beslutLinkText: t('beslutLinkText'),
    }
    const unlockStepKey = StudiestodUnlockState[unlockStep] as UnlockStep
    if (isForbiddenToUnlockForEditing) {
        unlockContent[unlockStepKey].unlockHeaderText = unlockContent[unlockStepKey].forbiddenToUnlockHeaderText
        unlockContent[unlockStepKey].unlockDescriptionText = unlockContent[unlockStepKey].forbiddenToUnlockDescriptionText
    } else if (isNeededToFirstUnlockBeslut) {
        unlockContent[unlockStepKey].unlockHeaderText = commonContent.beslutIsNeededToBeUnlockedFirstHeader
        unlockContent[unlockStepKey].unlockDescriptionText = commonContent.beslutIsNeededToBeUnlockedFirstDescription
    }

    return { ...unlockContent[unlockStepKey], ...commonContent }
}

export default useGetUnlockForEditingTextContent
