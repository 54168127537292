import React from 'react'
import { Stack, Typography } from '@mui/material'
import { ExternalLink } from '@local/src/components/ExternalLink'
import { useTranslation } from 'react-i18next'

import BeslutFranCsnList from './BeslutFranCsnList'

interface Props {
    ansokanId: string
}

const BeslutFranCsn = ({ ansokanId }: Props) => {
    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokan.steps.csn' })

    return (
        <Stack spacing={2}>
            <Typography variant="h4">{t('beslutHeaderText')}</Typography>
            <Typography>{t('beslutBodyText')}</Typography>
            <ExternalLink linkText={t('csnExternalLinkText')} testId="csn-external-link" href={t('csnExternalLink')} />

            <BeslutFranCsnList ansokanId={ansokanId} />
        </Stack>
    )
}

export default BeslutFranCsn
