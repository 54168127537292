import { BeraknadUtbetalningsstartApiModel, Termin } from '@local/src/services/SwaggerApi/data-contracts'
import { nullableNumberSchema } from '@local/src/utils/Yup/YupHelper'
import { isNil } from 'ramda'
import { mixed as yupMixed, object as yupObject, SchemaOf } from 'yup'
import { FormValidationTexts } from '@local/src/content/resources'

import { generateMojligaArUtbetalningsstart } from '../../../Helpers/generateYears'

export const BeraknadUtbetalningsstartDraftSchema = (
    formValidationTexts?: FormValidationTexts
): SchemaOf<BeraknadUtbetalningsstartApiModel> =>
    yupObject().shape({
        year: nullableNumberSchema().test(
            'beraknadUtbetalningsstart not in range',
            formValidationTexts?.beraknadUtbetalningsstartNotInRange,
            (beraknadUtbetalningsstart?: number) => {
                if (isNil(beraknadUtbetalningsstart)) return true
                const { min, max } = generateMojligaArUtbetalningsstart()
                return beraknadUtbetalningsstart >= min && beraknadUtbetalningsstart <= max
            }
        ),
        termin: yupMixed().nullable().oneOf<Termin>([0, 1, null, undefined]),
    })

export const BeraknadUtbetalningsstartSchema = (formValidationTexts?: FormValidationTexts): SchemaOf<BeraknadUtbetalningsstartApiModel> =>
    BeraknadUtbetalningsstartDraftSchema(formValidationTexts).concat(
        yupObject({
            year: nullableNumberSchema().required(formValidationTexts?.year),
            termin: yupMixed().nullable().oneOf<Termin>([0, 1]).required(formValidationTexts?.termin),
        })
    )
