import { StudiestodListApiModel, UtbildningarListApiModel } from '@local/src/services/SwaggerApi/data-contracts'
import React from 'react'
import { formatToDate } from '@local/src/utils/helpers/dateTimeFormatHelper'
import { Stack, Typography } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined'

interface Props {
    utbildning: UtbildningarListApiModel
    ansokan: StudiestodListApiModel
}

export const UtbildningMetaData = ({ utbildning, ansokan }: OptionalExceptFor<Props, 'utbildning' | 'ansokan'>) => {
    return (
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
            {Boolean(utbildning.anordnare) && (
                <Stack spacing={1} direction="row" flex={1}>
                    <LocationOnOutlinedIcon />
                    <Typography sx={{ wordBreak: 'break-word' }} title="Skola/Anordnare">
                        {utbildning.anordnare}
                    </Typography>
                </Stack>
            )}
            {Boolean(ansokan.utbildningsforutsattning) && (
                <Stack spacing={1} direction="row" flex={1}>
                    <BookmarkBorderOutlinedIcon />
                    <Typography title="Syftet med studierna">
                        {ansokan.utbildningsforutsattning === 'vidareutbildning' ? 'Vidareutbildning' : 'Omskolning'}
                    </Typography>
                </Stack>
            )}
            {Boolean(utbildning.studiestart) && (
                <Stack spacing={1} direction="row" flex={1}>
                    <InfoOutlinedIcon />
                    <Typography title="Beräknad studiestart">{formatToDate(utbildning.studiestart)}</Typography>
                </Stack>
            )}
        </Stack>
    )
}
