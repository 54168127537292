import { IStudiestodEpiContent } from '@local/src/@types/StudiestodEpiContent'
import { defaultCSNYttrandenEpiContent } from '@local/src/features/StudiestodAnsokan/Components/Steps/CSN/Components/Yttranden/Models/CsnYttranden.content'
import { defaultStudierExcelRaknesnurrorContent } from '@local/src/features/StudiestodAnsokan/Components/Steps/RadgivarensUnderlag/Components/Insats/Components/StudieersattningStudielon/Components/ExcelLinks/Models/StudierExcelRaknesnurror.content'

export const epiFallbackContent: IStudiestodEpiContent = {
    studiertab: {
        studierExcelRaknesnurrorFile: defaultStudierExcelRaknesnurrorContent,
        studieAnsokan: {
            csnYttranden: defaultCSNYttrandenEpiContent,
        },
    },
}
