import { StudiestodStepEnum, StudiestodStepType } from '@local/src/features/StudiestodAnsokan/Models/StudiestodAnsokan.types'
import { StudiestodApiResponse } from '@local/src/features/StudiestodAnsokan/Models/StudiestodansokanApiResponse'
import { formatDateFromDateTime } from '@local/src/utils/helpers/dateTimeFormatHelper'
import { useTranslation } from 'react-i18next'

const useStudiestodSteps = (activeStep: StudiestodStepType, studiestodAnsokan: StudiestodApiResponse) => {
    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokan.studiestodStepper' })

    const steps = [
        {
            label: t('ansokanStepTitle'),
            stepEnum: StudiestodStepEnum.ANSOKAN,
            content:
                activeStep === StudiestodStepEnum.ANSOKAN
                    ? [
                          {
                              key: t('ansokanStepKey1'),
                              value: formatDateFromDateTime(studiestodAnsokan?.publiceringsdatum),
                          },
                          {
                              key: t('ansokanStepKey2'),
                              value: studiestodAnsokan?.isUppdateradEfterPublicering
                                  ? formatDateFromDateTime(studiestodAnsokan.senastUppdateradDatum)
                                  : null,
                          },
                          {
                              key: t('ansokanStepKey3'),
                              value: formatDateFromDateTime(studiestodAnsokan?.ejRedigerbarDatum),
                          },
                      ]
                    : [
                          {
                              key: t('ansokanStepKey3'),
                              value: formatDateFromDateTime(studiestodAnsokan?.ejRedigerbarDatum),
                          },
                      ],
        },
        {
            label: t('radgivarensunderlagStepTitle'),
            stepEnum: StudiestodStepEnum.UNDERLAG,
            content: [
                {
                    key: t('radgivarensunderlagStepKey1'),
                    value: formatDateFromDateTime(studiestodAnsokan?.radgivarunderlag?.publiceringsdatum),
                },
            ],
        },
        {
            label: t('beslutStepTitle'),
            stepEnum: StudiestodStepEnum.BESLUT,
            content: [
                {
                    key: t('beslutStepKey1'),
                    value: formatDateFromDateTime(studiestodAnsokan?.beslut?.beslutsDatum),
                },
            ],
        },
        {
            label: t('csnStepTitle'),
            stepEnum: StudiestodStepEnum.CSN,
            content: [],
        },
        {
            label: t('studieintygStepTitle'),
            stepEnum: StudiestodStepEnum.STUDIEINTYG,
            content: [],
        },
    ]

    const getCurrentActiveStepAsNumber = (activeStep: StudiestodStepType) => {
        switch (activeStep) {
            case StudiestodStepEnum.ANSOKAN:
                return 0
            case StudiestodStepEnum.UNDERLAG:
                return 1
            case StudiestodStepEnum.BESLUT:
                return 2
            case StudiestodStepEnum.CSN:
                return 3
            case StudiestodStepEnum.STUDIEINTYG:
                return 4
            default:
                return 0
        }
    }

    return { steps, activeStepAsNumber: getCurrentActiveStepAsNumber(activeStep) }
}

export default useStudiestodSteps
