import { Alert, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
    hasFakturor: boolean
    hasErsattning: boolean
}

const FakturorErsattningAlert = ({ hasFakturor, hasErsattning }: Props) => {
    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokan.setEjAktuellAction.fakturorErsattningAlert' })

    const getAlertTranslationKey = () => {
        if (hasFakturor && hasErsattning) {
            return 'hasFakturorAndErsattning'
        } else if (hasFakturor) {
            return 'hasFakturor'
        } else {
            return 'hasErsattning'
        }
    }

    const translationKey = getAlertTranslationKey()

    return (
        <Alert severity="warning" data-testid={`fakturor-ersattning-alert-${translationKey}`}>
            <Typography>{t(translationKey)}</Typography>
        </Alert>
    )
}

export default FakturorErsattningAlert
