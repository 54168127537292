import { Typography } from '@mui/material'
import React from 'react'

interface Props {
    content: string
    fornamn: string
    testSelector?: string
}
const NoResult = ({ content, fornamn, testSelector = 'no-result-information' }: Props) => (
    <Typography data-testid={testSelector}>
        {fornamn} {content}
    </Typography>
)

export default NoResult
