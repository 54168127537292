import { useQuery } from '@tanstack/react-query'
import { ReactQueryKey, csnArendeBegaranApi } from '@local/src/services/apiEndpointHelper'
import { apiGetTryCatchWrapper } from '@local/src/services/apiRequestHelper'
import { CsnYttrandeKoppladFilter } from '@local/src/services/SwaggerApi/data-contracts'

export const useGetBegardaYttranden = (personnummer: string, isKoppladeToAnsokan: boolean) => {
    const { data: begaranYttranden, ...query } = useQuery({
        queryKey: [ReactQueryKey.getBegardaYttranden, personnummer, isKoppladeToAnsokan],
        queryFn: () =>
            apiGetTryCatchWrapper('useGetBegardaYttranden', csnArendeBegaranApi.csnArendeKlientBegaranYttrandeDetail, {
                personnummer,
                ansokankopplad: isKoppladeToAnsokan ? CsnYttrandeKoppladFilter.Kopplad : CsnYttrandeKoppladFilter.Okopplad,
            }),
        placeholderData: [],
        enabled: Boolean(personnummer),
    })

    return { begaranYttranden, ...query }
}
