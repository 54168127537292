import React, { Fragment } from 'react'
import { IChange, Operation } from 'json-diff-ts'
import { Stack, Typography } from '@mui/material'
import { DomainEventLogApiModel } from '@local/src/services/SwaggerApi/data-contracts'
import { diffWords, Change } from 'diff'

import { FormatAddOrRemoveValue, FormatTitle, FormatChangeValue } from './Utils/domainEventHelper'

interface Props {
    changes: IChange[]
    domainEvent: DomainEventLogApiModel
}

const HighlightedText = ({ originalText, updatedText }: { originalText: string; updatedText: string }) => {
    const diffResult = diffWords(originalText, updatedText)
    const highlightedText = diffResult.map((change: Change, index) => {
        if (change.added) {
            return (
                <Typography key={index} sx={{ wordBreak: 'break-word' }} variant="caption" color="green">
                    {' '}
                    {change.value}
                </Typography>
            )
        }
        if (change.removed) {
            return (
                <Typography key={index} sx={{ wordBreak: 'break-word', textDecoration: 'line-through' }} variant="caption" color="error">
                    {change.value}
                </Typography>
            )
        }
        return (
            <Typography variant="caption" key={index}>
                {change.value}
            </Typography>
        )
    })

    return (
        <Typography component="span" variant="caption" sx={{ wordBreak: 'break-word' }}>
            {highlightedText}
        </Typography>
    )
}

const ChangeDiff = ({ change, domainEvent }: { change: IChange; domainEvent: DomainEventLogApiModel }) => {
    if (change.changes?.length > 0) {
        return (
            <Fragment>
                <Typography py={1 / 2} variant="body2">
                    {FormatTitle(change.key, domainEvent)}:
                </Typography>
                <Stack pl={1}>
                    <DomainEventDiffItem changes={change.changes} domainEvent={domainEvent} />
                </Stack>
            </Fragment>
        )
    }

    if (change.type === Operation.UPDATE) {
        const title = FormatTitle(change.key, domainEvent)
        const oldValue = FormatChangeValue(change.key, change.oldValue)
        const newValue = FormatChangeValue(change.key, change.value)
        const isLongText = oldValue.length > 60 || newValue.length > 60

        if (isLongText) {
            return (
                <Stack py={1 / 2}>
                    <Typography component="span" variant="caption" sx={{ wordBreak: 'break-word' }}>
                        <Typography variant="caption" fontWeight="bold">
                            {title}{' '}
                        </Typography>
                        <Typography variant="caption">ändrat</Typography>
                    </Typography>
                    <HighlightedText originalText={oldValue} updatedText={newValue} />
                </Stack>
            )
        }

        return (
            <Stack direction={'row'}>
                <Typography pb={1 / 4} component="span" variant="caption" sx={{ wordBreak: 'break-word' }}>
                    <Typography variant="caption" fontWeight="bold">
                        {title}{' '}
                    </Typography>
                    <Typography variant="caption">ändrat från </Typography>
                    <Typography variant="caption" color="error">
                        {oldValue}{' '}
                    </Typography>
                    <Typography variant="caption">till </Typography>
                    <Typography variant="caption" color="green">
                        {newValue}
                    </Typography>
                </Typography>
            </Stack>
        )
    }

    if (change.type === Operation.ADD && change.value !== null) {
        const title = FormatTitle(change.key, domainEvent)
        const value = FormatAddOrRemoveValue(change.key, change.value)
        return (
            <Stack direction="row" py={1 / 2}>
                <Typography component="span" variant="caption" sx={{ wordBreak: 'break-word' }}>
                    <Typography variant="caption">Lagt till</Typography>
                    <Typography variant="caption" fontWeight="bold" color="green">
                        {' '}
                        {title} {value}
                    </Typography>
                </Typography>
            </Stack>
        )
    }

    if (change.type === Operation.REMOVE && change.value !== null) {
        const title = FormatTitle(change.key, domainEvent)
        const value = FormatAddOrRemoveValue(change.key, change.value)
        return (
            <Stack direction="row" py={1 / 2}>
                <Typography component="span" variant="caption" sx={{ wordBreak: 'break-word' }}>
                    <Typography variant="caption">Tagit bort</Typography>
                    <Typography variant="caption" color="error" fontWeight="bold">
                        {' '}
                        {title} {value}
                    </Typography>
                </Typography>
            </Stack>
        )
    }

    return null
}

const DomainEventDiffItem = ({ changes, domainEvent }: Props) => {
    return (
        <Fragment>
            {changes.map((item) => (
                <ChangeDiff key={`${domainEvent.id}-${item.key}-${item.type}`} change={item} domainEvent={domainEvent} />
            ))}
        </Fragment>
    )
}

export default DomainEventDiffItem
