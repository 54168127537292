import { QueryClient } from '@tanstack/react-query'
import { createTheme, ThemeOptions } from '@mui/material'
import { defaultLocale, themeOptions } from '@trr/mui-theme'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { translation } from '@local/src/content/resources'

export const createReactQueryClient = () =>
    new QueryClient({
        defaultOptions: {
            queries: {
                retry: 1,
                refetchOnWindowFocus: false,
                refetchOnMount: true,
            },
        },
    })

export const createTrrMuiTheme = () => {
    const customTheme: ThemeOptions = {
        components: {
            MuiCard: {
                defaultProps: {
                    sx: (theme) => ({
                        padding: theme.spacing(2),
                        [theme.breakpoints.down('md')]: {
                            padding: 0,
                        },
                        borderColor: theme.palette.neutral.divider,
                    }),
                },
            },
            MuiStepIcon: {
                styleOverrides: {
                    root: {
                        height: '32px !important',
                        width: '32px !important',
                    },
                },
            },
        },
    }

    const theme = createTheme(themeOptions, defaultLocale, customTheme)

    return theme
}

export const initTranslations = () => {
    void i18n
        .use(initReactI18next) // passes i18n down to react-i18next
        .init({
            resources: {
                sv: {
                    translation,
                },
            },
            ns: 'translation',
            lng: 'sv',
            fallbackLng: 'sv',
            defaultNS: 'translation',
            fallbackNS: 'translation',
        })
}
