import { AdHocYttrandeUtbildningApiModel } from '@local/src/services/SwaggerApi/data-contracts'
import { isNil } from 'ramda'

const getUtbildningsnamn = (utb: AdHocYttrandeUtbildningApiModel, noUtbildningContentText: string) => {
    if (isNil(utb.csnUtbildning)) {
        return utb.ejCsnUtbildning?.utbildningsnamn
    }
    const hasOspecificeradUtbildning = utb.csnUtbildning.ospecificeradUtbildning && utb.csnUtbildning.kurserUtbildning?.length > 0
    if (hasOspecificeradUtbildning) {
        const kurserAmount = utb.csnUtbildning.kurserUtbildning?.length
        return `${kurserAmount} st ${utb.csnUtbildning.utbildningsnamn}`
    }
    if (utb.csnUtbildning.utbildningsnamn) {
        return utb.csnUtbildning?.utbildningsnamn
    }

    return `${noUtbildningContentText} ${utb.csnUtbildning.utbildningskod}`
}

const getUtbildningsanordnareOrSkolnamn = (utb: AdHocYttrandeUtbildningApiModel, noSkolnamnContentText: string) => {
    if (isNil(utb.csnUtbildning)) {
        return utb.ejCsnUtbildning?.utbildningsanordnare
    }
    if (utb.csnUtbildning.skolnamn) {
        return utb.csnUtbildning?.skolnamn
    }

    return `${noSkolnamnContentText} ${utb.csnUtbildning.utbildningskod}`
}

export { getUtbildningsnamn, getUtbildningsanordnareOrSkolnamn }
