import { Dropdown, TextInputField } from '@local/src/components/FormComponents'
import { Anledning, UtbildningUnderlagApiModel } from '@local/src/services/SwaggerApi/data-contracts'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import { generateBeskrivningOptions, generateSkalOptions } from './Models/SkalTillStudielon.data'

interface Props {
    utbildningsIndex: number
    currentUtbildning: UtbildningUnderlagApiModel
    isReadonly: boolean
}

const SkalTillStudielon = ({ utbildningsIndex, currentUtbildning, isReadonly }: Props) => {
    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokan.steps.radgivarensUnderlag' })

    const renderDropdown = currentUtbildning.studieersattningAndStudielon?.motiveringStudielon?.anledning === Anledning.EjCSNBerattigad
    return (
        <Fragment>
            <Dropdown
                name={`utbildningar.${utbildningsIndex}.studieersattningAndStudielon.motiveringStudielon.anledning`}
                label={t('skalTillStudielonText')}
                disabled={isReadonly}
                options={generateSkalOptions({
                    skalEjCsnBerattigadText: t('skalEjCsnBerattigadText'),
                    skalEkonomiskSituationText: t('skalEkonomiskSituationText'),
                    skalPersonligSituationText: t('skalPersonligSituationText'),
                    skalAnnatText: t('skalAnnatText'),
                })}
            />
            {renderDropdown ? (
                <Dropdown
                    name={`utbildningar.${utbildningsIndex}.studieersattningAndStudielon.motiveringStudielon.beskrivning`}
                    label={t('angeOrsakText')}
                    disabled={isReadonly}
                    options={generateBeskrivningOptions({
                        beskrivningSlutPaCsn: t('beskrivningSlutPaCsn'),
                        beskrivningAlder: t('beskrivningAlder'),
                        beskrivningTidigareSkuld: t('beskrivningTidigareSkuld'),
                        beskrivningOtillrackligaStudieresultat: t('beskrivningOtillrackligaStudieresultat'),
                    })}
                />
            ) : (
                <TextInputField
                    name={`utbildningar.${utbildningsIndex}.studieersattningAndStudielon.motiveringStudielon.beskrivning`}
                    label={t('kortfattadMotiveringText')}
                    disabled={isReadonly}
                />
            )}
        </Fragment>
    )
}

export default SkalTillStudielon
