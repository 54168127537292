import { KompletterandeStudiestodApiModel } from '@local/src/services/SwaggerApi/data-contracts'
import { nullableNumberSchema } from '@local/src/utils/Yup/YupHelper'
import { SchemaOf, object as yupObject } from 'yup'
import { FormValidationTexts } from '@local/src/content/resources'

import {
    BeraknadUtbetalningsstartSchema,
    BeraknadUtbetalningsstartDraftSchema,
} from './StudieersattningAndStudielonSchema/BeraknadUtbetalningsstartSchema'

export const KompletterandeStudiestodDraftSchema = (
    formValidationTexts?: FormValidationTexts
): SchemaOf<KompletterandeStudiestodApiModel> =>
    yupObject({
        preliminarErsattning: nullableNumberSchema()
            .integer(formValidationTexts?.heltal)
            .min(1, formValidationTexts?.kompletterandeStudiestodPreliminarErsattningMin)
            .max(100000, formValidationTexts?.kompletterandeStudiestodPreliminarErsattningMax),
        utbildningslangd: nullableNumberSchema()
            .integer(formValidationTexts?.heltal)
            .min(1, formValidationTexts?.kompletterandeStudiestodUtbildningslangdMin)
            .max(220, formValidationTexts?.kompletterandeStudiestodUtbildningslangdMax),
        beraknadUtbetalningsstart: BeraknadUtbetalningsstartDraftSchema(formValidationTexts),
    }).nullable()

export const KompletterandeStudiestodSchema = (formValidationTexts?: FormValidationTexts): SchemaOf<KompletterandeStudiestodApiModel> =>
    KompletterandeStudiestodDraftSchema(formValidationTexts).concat(
        yupObject({
            preliminarErsattning: nullableNumberSchema().required(
                formValidationTexts?.kompletterandeStudiestodPreliminarErsattningRequired
            ),
            utbildningslangd: nullableNumberSchema().required(formValidationTexts?.kompletterandeStudiestodUtbildningslangdRequired),
            beraknadUtbetalningsstart: BeraknadUtbetalningsstartSchema(formValidationTexts),
        })
    )
