import React from 'react'
import { Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { Studieersattning, Studielon, TaBortKalenderhalvarButton } from './Components'

interface Props {
    utbildningsIndex: number
    kalenderhalvarIndex: number
    isReadonly: boolean
}

const Kalenderhalvar = ({ utbildningsIndex, kalenderhalvarIndex, isReadonly }: Props) => {
    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokan.steps.radgivarensUnderlag' })

    const showRemoveButton = kalenderhalvarIndex > 0

    return (
        <Stack>
            <Stack direction="row" alignItems="center" spacing={2}>
                {!isReadonly && (
                    <TaBortKalenderhalvarButton
                        showRemoveButton={showRemoveButton}
                        kalenderhalvarIndex={kalenderhalvarIndex}
                        utbildningsIndex={utbildningsIndex}
                    />
                )}
                <Typography variant={'h4'}>
                    {t('kalenderhalvarTitle')} {kalenderhalvarIndex + 1}
                </Typography>
            </Stack>
            <Stack direction="row" spacing={2}>
                <Studieersattning kalenderhalvarIndex={kalenderhalvarIndex} utbildningsIndex={utbildningsIndex} isReadonly={isReadonly} />
                <Studielon kalenderhalvarIndex={kalenderhalvarIndex} utbildningsIndex={utbildningsIndex} isReadonly={isReadonly} />
            </Stack>
        </Stack>
    )
}

export default Kalenderhalvar
