import { Stack, Typography } from '@mui/material'
import React, { ReactNode } from 'react'

interface Props {
    label: string
    value: ReactNode
}

const LabelValue = ({ label, value }: Props) => (
    <Stack data-testid={'label-value'}>
        <Typography variant="caption" color="text.secondary">
            {label}
        </Typography>
        <Typography sx={{ wordBreak: 'break-word' }}>{value}</Typography>
    </Stack>
)

export default LabelValue
