import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useRouteMatch } from 'react-router-dom'
import { ReactQueryKey, studiestodApi } from '@local/src/services/apiEndpointHelper'
import { apiGetTryCatchWrapper } from '@local/src/services/apiRequestHelper'

import { studiestodAnsokanDetailPath } from '../../Utils/studiestodAnsokanConstants'

const useGetCurrentStudiestodansokanStudieintyg = () => {
    const { isExact, params } = useRouteMatch<{ arendeId: string; ansokanId: string }>({
        path: studiestodAnsokanDetailPath,
    })

    const getStudieintygByAnsokanId = (arendeId: string, ansokanId: string) =>
        apiGetTryCatchWrapper('useGetCurrentStudiestodansokanStudieintyg', studiestodApi.arendeAnsokanDocumentsDetail, arendeId, ansokanId)
    const queryClient = useQueryClient()
    const queryKey = [ReactQueryKey.getStudieintygByAnsokanId, params?.arendeId, params?.ansokanId]

    const { data: studieintygList, ...query } = useQuery({
        queryKey: queryKey,
        queryFn: () => getStudieintygByAnsokanId(params?.arendeId, params?.ansokanId),
        enabled: isExact,
        placeholderData: [],
    })

    const invalidateQuery = async () => await queryClient.invalidateQueries(queryKey)

    return { studieintygList, ...query, invalidateQuery }
}
export default useGetCurrentStudiestodansokanStudieintyg
