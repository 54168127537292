import { useGetCurrentStudiestodansokan } from '@local/src/features/StudiestodAnsokan/Hooks'
import React, { Fragment } from 'react'
import { Alert, AlertTitle, Divider, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { useRadgivarensUnderlagFormContext } from '../../../../Hooks'

import { PreliminarErsattning, SkalTillStudielon, KalenderhalvarContainer } from './Components'
import ExcelLinks from './Components/ExcelLinks/ExcelLinks'
import VarningarForEkonomiskStod from './Components/VarningarForEkonomisktStod/VarningarForEkonomisktStod'

interface Props {
    utbildningsIndex: number
    isReadonly: boolean
}

const StudieersattningStudielon = ({ utbildningsIndex, isReadonly }: Props) => {
    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokan.steps.radgivarensUnderlag' })
    const { watch } = useRadgivarensUnderlagFormContext()
    const { utbildningar } = watch()
    const { studiestodAnsokan } = useGetCurrentStudiestodansokan()

    const currentUtbildning = utbildningar[utbildningsIndex]
    const renderSkalTillStudielon = currentUtbildning.studiestodTyp.hasStudielon
    const isStudieersattningOrStudielon = currentUtbildning.studieersattningAndStudielon

    const showYoungerThan40Alert = currentUtbildning.studiestodTyp.hasStudieersattning && studiestodAnsokan?.isYoungerThan40

    return (
        <Fragment>
            <Divider />
            <Stack spacing={2}>
                <Typography data-testid="studieersattning-studielon-header" variant="h5">
                    {t('studieersattningStudielonHeader')}
                </Typography>
                {showYoungerThan40Alert && (
                    <Alert severity="warning" data-testid="younger-than-40-alert">
                        <AlertTitle>{t('studieersattningStudielonYoungerThan40AlertHeader')}</AlertTitle>
                        {t('studieersattningStudielonYoungerThan40AlertDescription')}
                    </Alert>
                )}
                {renderSkalTillStudielon && (
                    <SkalTillStudielon currentUtbildning={currentUtbildning} utbildningsIndex={utbildningsIndex} isReadonly={isReadonly} />
                )}
                <PreliminarErsattning utbildningsIndex={utbildningsIndex} isReadonly={isReadonly} />
                <ExcelLinks />
                <VarningarForEkonomiskStod utbetalningsforutsattningar={studiestodAnsokan?.radgivarunderlag?.utbetalningsforutsattningar} />
                {isStudieersattningOrStudielon && <KalenderhalvarContainer utbildningsIndex={utbildningsIndex} isReadonly={isReadonly} />}
            </Stack>
        </Fragment>
    )
}

export default StudieersattningStudielon
