import React, { useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, List, ListItem, ListItemIcon, Stack, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CircleIcon from '@mui/icons-material/Circle'

export const BeslutAccordionItemContent = ({ content }: { content: string }) => <Typography variant="subtitle2">{content}</Typography>

export const BeslutAccordionItem = ({
    heading,
    subHeading,
    children,
    expansionId,
}: {
    heading: string
    subHeading?: string
    children?: React.ReactNode | React.ReactNode[]
    expansionId: string
}) => {
    const [toggled, setToggled] = useState<boolean>(false)
    const handleToggleClicked = () => setToggled(!toggled)
    return (
        <Accordion sx={{ mb: 2, border: 'none', boxShadow: 'none' }} expanded={toggled} onChange={() => handleToggleClicked()}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`${expansionId}-content`} id={`${expansionId}-header`}>
                <Stack>
                    {heading && <Typography fontWeight="bold"> {heading} </Typography>}
                    {subHeading && (
                        <Typography variant="subtitle2" color="text.secondary">
                            {subHeading}
                        </Typography>
                    )}
                </Stack>
            </AccordionSummary>
            <AccordionDetails>{children}</AccordionDetails>
        </Accordion>
    )
}

export const BeslutListItem = ({ heading, items }: { heading: string; items: string[] }) => (
    <List>
        <Typography variant="subtitle2" mb={2}>
            {heading}
        </Typography>
        {items.map(
            (item, index) =>
                item.length > 0 && (
                    <ListItem divider={false} key={index}>
                        <ListItemIcon sx={{ minWidth: 24 }}>
                            <CircleIcon sx={{ fontSize: 8 }} />
                        </ListItemIcon>
                        <Typography variant="subtitle2">{item}</Typography>
                    </ListItem>
                )
        )}
    </List>
)
