import SubmitResponse from '@local/src/features/StudiestodAnsokan/Components/SubmitResponse/SubmitResponse'
import useSetEjAktuell from '@local/src/features/StudiestodAnsokan/Hooks/API/useSetEjAktuell'
import useCommonTranslation from '@local/src/hooks/useCommonTranslation'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined'

import FakturorErsattningAlert from './Components/FakturorErsattningAlert'

interface Orsak {
    orsakMedarbetare: string
    orsakKund: string
}

interface Props {
    isEjAktuell: boolean
    hasFakturor: boolean
    hasErsattning: boolean
}

const SetEjAktuellAction = ({ isEjAktuell, hasFakturor, hasErsattning }: Props) => {
    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokan.setEjAktuellAction' })
    const { tCommon } = useCommonTranslation()
    const isEjAktuellString = isEjAktuell ? 'ejAktuell' : 'aktuell'
    const translationOptions = { context: isEjAktuellString }

    const content = {
        buttonOpenDialog: t('buttonOpenDialog', 'buttonOpenDialog', translationOptions),
        dialogTitle: t('dialogTitle', 'dialogTitle', translationOptions),
        dialogContentText: t('dialogContentText', 'dialogContentText', translationOptions),
        dialogButtonCancel: tCommon('button_cancel'),
        dialogButtonConfirm: t('dialogButtonConfirm', 'dialogButtonConfirm', translationOptions),
        snackbarSuccess: t('snackbarSuccess', 'SnackbarSuccess', translationOptions),
        selectOrsakLabel: t('selectOrsakLabel'),
        orsaker: t('orsaker', { returnObjects: true }),
        orsakNotSelectedErrorText: t('orsakNotSelectedErrorText'),
    }

    const [selectedOrsak, setSelectedOrsak] = useState<Orsak>(null)
    const [hasError, setHasError] = useState(false)

    useEffect(() => {
        if (selectedOrsak) setHasError(false)
    }, [selectedOrsak])

    const handleChange = (event: SelectChangeEvent) => {
        const index = parseInt(event.target.value)
        const orsak = content.orsaker[index]
        setSelectedOrsak(orsak)
    }

    const [openDialog, setOpenDialog] = useState(false)
    const handleOnOpenDialog = () => setOpenDialog(true)
    const handleOnCloseDialog = () => {
        setHasError(false)
        setOpenDialog(false)
        setSelectedOrsak(null)
    }

    const { apiResponse, setEjAktuellMutation } = useSetEjAktuell()

    const handleOnConfirmDialog = () => {
        const isError = !selectedOrsak && !isEjAktuell
        if (isError) {
            setHasError(true)
            return
        }

        void setEjAktuellMutation.mutateAsync({
            setEjAktuell: !isEjAktuell,
            orsakMedarbetare: isEjAktuell ? null : selectedOrsak?.orsakMedarbetare,
            orsakKund: isEjAktuell ? null : selectedOrsak?.orsakKund,
        })

        setHasError(false)
        setOpenDialog(false)
        setSelectedOrsak(null)
    }

    const showAlert = !isEjAktuell && (hasFakturor || hasErsattning)

    return (
        <Stack spacing={2}>
            <Button
                variant="text"
                onClick={handleOnOpenDialog}
                data-testid={`open-set-ej-aktuell-dialog-button-${isEjAktuellString}`}
                disabled={setEjAktuellMutation.isLoading}
                size="small"
                startIcon={<ArchiveOutlinedIcon />}
            >
                {content.buttonOpenDialog}
            </Button>

            <Dialog fullWidth open={openDialog} data-testid={`set-ej-aktuell-dialog-${isEjAktuellString}`} onClose={handleOnCloseDialog}>
                <DialogTitle>{content.dialogTitle}</DialogTitle>
                <DialogContent>
                    <Stack spacing={2}>
                        {showAlert && <FakturorErsattningAlert hasFakturor={hasFakturor} hasErsattning={hasErsattning} />}

                        <DialogContentText>{content.dialogContentText}</DialogContentText>
                        {!isEjAktuell && (
                            <FormControl error={hasError} fullWidth>
                                <InputLabel id="ej-aktuell-orsak-label">{content.selectOrsakLabel}</InputLabel>
                                <Select
                                    labelId="ej-aktuell-orsak-label"
                                    id="ej-aktuell-orsak-select"
                                    value={selectedOrsak?.orsakMedarbetare}
                                    label={content.selectOrsakLabel}
                                    onChange={handleChange}
                                    data-testid="ej-aktuell-orsak-select"
                                >
                                    {content.orsaker.map((option, index) => (
                                        <MenuItem key={index} value={index}>
                                            {option?.orsakMedarbetare}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {hasError && <FormHelperText>{content.orsakNotSelectedErrorText}</FormHelperText>}
                            </FormControl>
                        )}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant="text" onClick={handleOnCloseDialog} data-testid="dialog--cancel-button">
                        {content.dialogButtonCancel}
                    </Button>
                    <Button variant="text" onClick={handleOnConfirmDialog} data-testid="dialog--confirm-button">
                        {content.dialogButtonConfirm}
                    </Button>
                </DialogActions>
            </Dialog>
            <SubmitResponse apiResponse={apiResponse} successMessage={content.snackbarSuccess} />
        </Stack>
    )
}

export default SetEjAktuellAction
