/* eslint-disable @typescript-eslint/no-misused-promises */
import { useGetCurrentStudiestodansokan } from '@local/src/features/StudiestodAnsokan/Hooks'
import React, { useEffect, useState } from 'react'
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material'
import { ExternalLink } from '@local/src/components/ExternalLink'
import EmailIcon from '@mui/icons-material/Email'
import { useTranslation } from 'react-i18next'

import { useTaBeslutForStudiestodAnsokan } from '../../Hooks/useTaBeslutForStudiestodAnsokan'
import { BeslutAccordionItem, BeslutAccordionItemContent } from '../BeslutComponents'
import SubmitResponse from '../../../../SubmitResponse/SubmitResponse'

const TaBeslutSpecialfall = () => {
    const [modalIsActive, setModalIsActive] = useState<boolean>(false)

    const { handleTaBeslut, isPosting, beslutResponse } = useTaBeslutForStudiestodAnsokan()

    const {
        studiestodAnsokan: { specialfall },
    } = useGetCurrentStudiestodansokan()

    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokan.steps.beslut' })

    useEffect(() => {
        if (beslutResponse?.hasError) {
            setModalIsActive(false)
        }
    }, [beslutResponse])

    const handleCloseModal = () => setModalIsActive(false)
    const handleOpenModal = () => setModalIsActive(true)

    return (
        <Stack spacing={2} data-testid="before-submit-view">
            <Typography>{t('descriptionText')}</Typography>
            <Typography variant="h5">{t('beforeBeslutAccordionHeader')}</Typography>

            <Box>
                {specialfall?.isEjCsnBerattigad && <Alert severity={'info'}>{t('isEjCsnBerattigadWarningText')}</Alert>}
                {specialfall?.isKombinationStudieersattningStudielon && (
                    <Alert severity={'info'}>{t('isKombinationStudieersattningStudielonWarningText')}</Alert>
                )}
                {specialfall?.isStudielonOverstigerMaxbelopp && (
                    <Alert severity={'info'}>{t('isStudielonOverstigerMaxbeloppWarningText')}</Alert>
                )}
            </Box>
            <BeslutAccordionItem heading={t('beforeBeslutAccordionEntry1Header')} expansionId="ta-beslut-specialfall-accordion-1">
                <Stack spacing={1}>
                    <Typography>{t('beforeBeslutAccordionEntry1Description')}</Typography>
                    <ExternalLink
                        href={`mailto:${t('beforeBeslutAccordionEntry1Email')}`}
                        linkText={t('beforeBeslutAccordionEntry1DisplayTextEmail')}
                        customIcon={<EmailIcon />}
                    />
                </Stack>
            </BeslutAccordionItem>
            <BeslutAccordionItem heading={t('beforeBeslutAccordionEntry2Header')} expansionId="ta-beslut-specialfall-accordion-2">
                <BeslutAccordionItemContent content={t('beforeBeslutAccordionEntry2Description')} />
            </BeslutAccordionItem>
            <BeslutAccordionItem heading={t('beforeBeslutAccordionEntry3Header')} expansionId="ta-beslut-specialfall-accordion-3">
                <BeslutAccordionItemContent content={t('beforeBeslutAccordionEntry3Description')} />
            </BeslutAccordionItem>
            <Button variant="contained" data-testid="ta-beslut-specialfall" onClick={handleOpenModal} disabled={isPosting}>
                {t('submitButtonText')}
            </Button>
            <SubmitResponse
                apiResponse={beslutResponse}
                successMessage={t('studiestodsansokanGodkandSnackbarSuccessText')}
                failureMessage={t('studiestodsansokanGodkandSnackbarFailureText')}
            />
            <Dialog open={modalIsActive} data-testid="special-beslut-modal">
                <DialogTitle>{t('specialBeslutModalHeader')}</DialogTitle>
                <DialogContent>{t('specialBeslutModalDescription')}</DialogContent>
                <DialogActions>
                    <Button data-testid="special-beslut-modal--cancel-button" onClick={handleCloseModal} variant="outlined">
                        {t('specialBeslutModalSecondaryButton')}
                    </Button>
                    <Button data-testid="special-beslut-modal--confirm-button" onClick={handleTaBeslut} variant="contained">
                        {t('specialBeslutModalPrimaryButton')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Stack>
    )
}

export default TaBeslutSpecialfall
