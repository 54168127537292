import { mapUtbildningslangd } from '@local/src/features/StudiestodAnsokan/Components/Utbildning/Helpers'
import { useGetCurrentStudiestodansokan } from '@local/src/features/StudiestodAnsokan/Hooks'
import React, { Fragment } from 'react'
import { FieldPath } from 'react-hook-form'
import { Divider, Stack, Typography } from '@mui/material'
import { ExternalLink } from '@local/src/components/ExternalLink'
import LabelValue from '@local/src/features/StudiestodAnsokan/Components/LabelValue/LabelValue'
import { useTranslation } from 'react-i18next'
import NumberInputField from '@local/src/components/FormComponents/InputField/NumberInputField'

import { RadgivarensUnderlagForm } from '../../../../Models/RadgivarensUnderlagForm'

interface Props {
    utbildningsIndex: number
    isReadonly: boolean
}

const KortvarigtStudiestod = ({ utbildningsIndex, isReadonly }: Props) => {
    const { studiestodAnsokan } = useGetCurrentStudiestodansokan()

    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokan.steps.radgivarensUnderlag' })

    const fieldPath: FieldPath<RadgivarensUnderlagForm> = `utbildningar.${utbildningsIndex}.kortvarigtStudiestod`

    const currentUtbildningUnderlag = studiestodAnsokan.radgivarunderlag?.utbildningar[utbildningsIndex]
    const antalTerminer = currentUtbildningUnderlag?.utbildning?.antalTerminer
    const utbildningLangdEnhet = currentUtbildningUnderlag?.utbildning?.utbildningLangdEnhet
    const utbildningslangdLabel = mapUtbildningslangd(antalTerminer, utbildningLangdEnhet)

    return (
        <Fragment>
            <Divider />
            <Stack spacing={2}>
                <Typography variant="h5">{t('kortvarigtStudiestodHeader')}</Typography>
                <Typography>{t('kortvarigtStudiestodLeadText')}</Typography>
                <ExternalLink
                    linkText={t('kortvarigtStudiestodErsattningLinkText')}
                    testId="kortvarigt-studiestod-ersattning-link"
                    href={t('kortvarigtStudiestodErsattningLink')}
                />
                <LabelValue label={t('kortvarigtStudiestodUtbildningslangdLabel')} value={utbildningslangdLabel} />
                <NumberInputField
                    name={`${fieldPath}.utbildningslangd`}
                    label={t('kortvarigtStudiestodUtbildningslangdText')}
                    disabled={isReadonly}
                />
                <NumberInputField
                    name={`${fieldPath}.preliminarErsattning`}
                    label={t('kortvarigtStudiestodPreliminarErsattningText')}
                    disabled={isReadonly}
                />
            </Stack>
        </Fragment>
    )
}

export default KortvarigtStudiestod
