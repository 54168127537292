import { StudiestodAnsokanState } from '@local/src/services/SwaggerApi/data-contracts'
import React from 'react'
import { Chip } from '@mui/material'
import useCommonTranslation from '@local/src/hooks/useCommonTranslation'

interface Props {
    status: StudiestodAnsokanState
}

const StudiestodStatusChip = ({ status }: Props) => {
    const { tCommon } = useCommonTranslation()

    const StatusLabels: Record<number, string> = {
        2: 'ny',
        3: 'handlaggs',
        4: 'handlaggs',
        5: 'handlaggs',
        6: 'handlaggs',
        7: 'godkand',
        8: 'ejAktuell',
    }

    const getStatusChipVariant = () => {
        switch (status) {
            case StudiestodAnsokanState.Publicerad:
            case StudiestodAnsokanState.Handlaggs:
            case StudiestodAnsokanState.Last:
            case StudiestodAnsokanState.UnderlagSparad:
            case StudiestodAnsokanState.UnderlagPublicerad:
                return 'status-info'
            case StudiestodAnsokanState.Beslutad:
                return 'status-success'
            case StudiestodAnsokanState.EjAktuell:
                return 'status-warning'
            default:
                return 'status-info'
        }
    }

    return (
        <Chip
            variant={getStatusChipVariant()}
            label={tCommon('status', 'status', { context: StatusLabels[status] })}
            data-testid={`studiestod-status-chip-${StatusLabels[status]}`}
        />
    )
}

export default StudiestodStatusChip
