/* eslint-disable @typescript-eslint/no-misused-promises */
import React from 'react'
import { useGetCurrentStudiestodansokan, useLockStudiestodansokan } from '@local/src/features/StudiestodAnsokan/Hooks'
import { Box, Button, Stack, Typography } from '@mui/material'
import Icons from '@local/src/components/Icons'
import { useTranslation } from 'react-i18next'

import SubmitResponse from '../../../SubmitResponse/SubmitResponse'

const Klarmarkera = () => {
    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokan.steps.ansokanOmStudiestod' })

    const { studiestodAnsokan } = useGetCurrentStudiestodansokan()
    const { apiResponse, isPosting, handleLockStudieansokan } = useLockStudiestodansokan()

    const handleKlarmarkeraClicked = async () => handleLockStudieansokan()

    if (studiestodAnsokan.isRedigerbar) {
        return (
            <Stack spacing={2}>
                <Typography>{t('markeraKlarText')}</Typography>
                <Box>
                    <Button onClick={handleKlarmarkeraClicked} data-testid="ansokan-markera-klar" disabled={isPosting}>
                        {t('markeraKlarButtonText')}
                    </Button>
                </Box>
                <SubmitResponse
                    apiResponse={apiResponse}
                    successMessage={t('markeradSomKlarSnackbarSuccessText')}
                    failureMessage={t('markeradSomKlarSnackbarFailureText')}
                />
            </Stack>
        )
    }

    return (
        <Stack spacing={2} direction="row" alignContent="center" alignItems="center">
            <Icons.Check />
            <Typography>{t('klarmarkeradCheckboxText')}</Typography>
        </Stack>
    )
}

export default Klarmarkera
