import { useGetCurrentStudiestodansokan } from '@local/src/features/StudiestodAnsokan/Hooks'
import React, { Fragment } from 'react'
import { Divider, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { BeslutAccordionItem, BeslutAccordionItemContent, BeslutListItem } from '../BeslutComponents'
import GodkandAv from '../GodkandAv/GodkandAv'

const Beslutad = () => {
    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokan.steps.beslut' })

    const { hasStudielonOrStudieErsattning } = useGetCurrentStudiestodansokan()

    return (
        <Stack spacing={2} data-testid="beslutad-normalfall-content">
            <Divider />
            <GodkandAv />
            {hasStudielonOrStudieErsattning ? (
                <Fragment>
                    <Typography sx={{ mb: 2 }} variant="h5" data-testid="beslutad-normalfall-accordion-content">
                        {t('accordionHeader')}
                    </Typography>
                    <BeslutAccordionItem heading={t('accordionEntry1Header')} expansionId="normalfall-accordion-1">
                        <BeslutAccordionItemContent content={t('accordionEntry1Description')} />
                    </BeslutAccordionItem>
                    <BeslutAccordionItem heading={t('accordionEntry2Header')} expansionId="normalfall-accordion-2">
                        <BeslutAccordionItemContent content={t('accordionEntry2Description')} />
                    </BeslutAccordionItem>
                    <BeslutAccordionItem
                        heading={t('accordionEntry3Header')}
                        subHeading={t('accordionEntry3SubHeader')}
                        expansionId="normalfall-accordion-3"
                    >
                        <BeslutListItem
                            heading={t('accordionEntry3List1Header')}
                            items={[t('accordionEntry3List1Item1'), t('accordionEntry3List1Item2')]}
                        />
                        <BeslutListItem
                            heading={t('accordionEntry3List2Header')}
                            items={[
                                t('accordionEntry3List2Item1'),
                                t('accordionEntry3List2Item2'),
                                t('accordionEntry3List2Item3'),
                                t('accordionEntry3List2Item4'),
                                t('accordionEntry3List2Item5'),
                                t('accordionEntry3List2Item6'),
                                t('accordionEntry3List2Item7'),
                            ]}
                        />
                    </BeslutAccordionItem>
                </Fragment>
            ) : null}
        </Stack>
    )
}

export default Beslutad
