import { RadioButtonGroup } from '@local/src/components/FormComponents'
import { RadgivarensUnderlagForm } from '@local/src/features/StudiestodAnsokan/Components/Steps/RadgivarensUnderlag/Models/RadgivarensUnderlagForm'
import React from 'react'
import { FieldPath } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface Props {
    isReadonly: boolean
    utbildningIndex: number
}

const YttrandeToCsnRadioButton = ({ isReadonly, utbildningIndex }: Props) => {
    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokan.steps.radgivarensUnderlag.yttrandeToCsnRadioButton' })
    const name: FieldPath<RadgivarensUnderlagForm> = `utbildningar.${utbildningIndex}.yttrandeToCsn.hasYttrandeToCsn`
    return (
        <RadioButtonGroup
            name={name}
            label={t('label')}
            valueType="boolean"
            radios={[
                { label: 'Ja', value: true, disabled: isReadonly },
                { label: 'Nej', value: false, disabled: isReadonly },
            ]}
            row
        />
    )
}

export default YttrandeToCsnRadioButton
