import React from 'react'
import { useGetCurrentStudiestodansokan } from '@local/src/features/StudiestodAnsokan/Hooks'
import { Stack, Typography } from '@mui/material'
import { ExternalLink } from '@local/src/components/ExternalLink'
import { useTranslation } from 'react-i18next'

import BeslutFranCsn from './Components/BeslutFranCsn'
import Yttranden from './Components/Yttranden'

const CSN = () => {
    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokan.steps.csn' })

    const { studiestodAnsokan, isEjAktuell } = useGetCurrentStudiestodansokan()

    return (
        <Stack spacing={2} data-testid="form-steppers-section-csn">
            <Typography variant="h4">{t('headerText')}</Typography>
            <Typography>{t('leadText')}</Typography>
            <ExternalLink linkText={t('checklistorLinkText')} testId="checklistor-link" href={t('csnButtonLink')} />

            <Yttranden arendeId={studiestodAnsokan?.arendeId} ansokanId={studiestodAnsokan.id} isEjAktuell={isEjAktuell} />

            <BeslutFranCsn ansokanId={studiestodAnsokan.id} />
        </Stack>
    )
}

export default CSN
