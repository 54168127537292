import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useRouteMatch } from 'react-router-dom'
import { isNil, isNotNil } from 'ramda'
import { ReactQueryKey, studiestodApi } from '@local/src/services/apiEndpointHelper'
import { ApiErrorResponse } from '@local/src/services/ApiResponseModel'
import { apiGetTryCatchWrapper } from '@local/src/services/apiRequestHelper'
import { StudiestodAnsokanState } from '@local/src/services/SwaggerApi/data-contracts'

import { studiestodAnsokanDetailPath } from '../../Utils/studiestodAnsokanConstants'
import { StudiestodApiResponse } from '../../Models/StudiestodansokanApiResponse'

const useGetCurrentStudiestodansokan = () => {
    const { isExact, params } = useRouteMatch<{ arendeId: string; ansokanId: string }>({
        path: studiestodAnsokanDetailPath,
    })
    const queryClient = useQueryClient()
    const queryKey = [ReactQueryKey.getStudiestodsansokanById, params?.arendeId, params?.ansokanId]

    const getStudiestodsansokanById = (arendeId: string, ansokanId: string): Promise<StudiestodApiResponse> =>
        apiGetTryCatchWrapper('useGetCurrentStudiestodansokan', studiestodApi.arendeAnsokanDetail, arendeId, ansokanId)

    const { data: studiestodAnsokan, ...query } = useQuery<StudiestodApiResponse, ApiErrorResponse>({
        queryKey: queryKey,
        queryFn: () => getStudiestodsansokanById(params?.arendeId, params?.ansokanId),
        enabled: isExact,
    })

    const invalidateQuery = async () => await queryClient.invalidateQueries(queryKey)
    const hasStudielonOrStudieErsattning = !isNil(
        studiestodAnsokan?.radgivarunderlag?.utbildningar?.find((u) => !isNil(u.studieersattningAndStudielon))
    )

    const isEjAktuell =
        studiestodAnsokan?.studiestodAnsokanState === StudiestodAnsokanState.EjAktuell && isNotNil(studiestodAnsokan?.ejAktuell)
    return { studiestodAnsokan, ...query, invalidateQuery, hasStudielonOrStudieErsattning, isEjAktuell }
}
export default useGetCurrentStudiestodansokan
