import { isNil } from 'ramda'
import React, { Fragment } from 'react'
import { Alert, Divider, Stack, Typography } from '@mui/material'
import { StudiestodUnlockState } from '@local/src/services/SwaggerApi/data-contracts'
import { useTranslation } from 'react-i18next'

import { useGetCurrentStudiestodansokan } from '../../../Hooks'
import NyaAvtaletHighlightBox from '../../NyaAvtaletHighlightBox/NyaAvtaletHighlightBox'
import StudiestodUnlockStateActionBox from '../../StudiestodUnlockForEditingActionBox'

import Beslutad from './Components/Beslutad/Beslutad'
import BeslutadSpecialfall from './Components/Beslutad/BeslutadSpecialfall'
import TaBeslut from './Components/TaBeslut/TaBeslut'
import TaBeslutSpecialfall from './Components/TaBeslut/TaBeslutSpecialfall'

const Beslut = () => {
    const { studiestodAnsokan, isEjAktuell } = useGetCurrentStudiestodansokan()

    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokan.steps.beslut' })

    const harTagitBeslut = !isNil(studiestodAnsokan.beslut)
    const isBeslutSpecialfall = !isNil(studiestodAnsokan.specialfall)
    const isBeslut = !isBeslutSpecialfall

    const showBeslutadSpecialfall = isBeslutSpecialfall && harTagitBeslut && !isEjAktuell
    const showTaBeslutSpecialfall = isBeslutSpecialfall && !harTagitBeslut && !isEjAktuell

    const showBeslutad = isBeslut && harTagitBeslut
    const showTaBeslut = isBeslut && !harTagitBeslut && !isEjAktuell

    const showUnlockForEditingButton = studiestodAnsokan.isBeslutad && !isEjAktuell
    const showInfoBoxAfterGodkant = !studiestodAnsokan.isBeslutad && !isEjAktuell

    return (
        <Stack spacing={2} data-testid="form-steppers-section-beslut">
            <Typography variant="h4">{t('headerText')}</Typography>
            <NyaAvtaletHighlightBox
                isVisible={!studiestodAnsokan?.isLegacyAnsokan}
                title={t('informationBoxNyaAvtaletTitle')}
                content={t('informationBoxNyaAvtaletContent')}
                link={t('informationBoxNyaAvtaletLink')}
                linkText={t('informationBoxNyaAvtaletLinkText')}
            />

            {showUnlockForEditingButton && <StudiestodUnlockStateActionBox unlockStep={StudiestodUnlockState.Beslut} />}

            {showTaBeslut && <TaBeslut />}
            {showTaBeslutSpecialfall && <TaBeslutSpecialfall />}

            {showBeslutad && <Beslutad />}
            {showBeslutadSpecialfall && <BeslutadSpecialfall />}

            {showInfoBoxAfterGodkant && (
                <Fragment>
                    <Divider />
                    <Alert severity={'info'}>{t('infoBoxAfterGodkandText')}</Alert>
                </Fragment>
            )}
        </Stack>
    )
}

export default Beslut
