import React from 'react'
import { Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

import InformationWithWarningIcon from '../../../../../InformationWithWarningIcon/InformationWithWarningIcon'
import { useRadgivarensUnderlagFormContext } from '../../../../../../Hooks'

import { LaggTillKalenderhalvarButton } from './Components'
import Kalenderhalvar from './Kalenderhalvar'

interface Props {
    utbildningsIndex: number
    isReadonly: boolean
}

const KalenderhalvarContainer = ({ utbildningsIndex, isReadonly }: Props) => {
    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokan.steps.radgivarensUnderlag' })

    const { watch } = useRadgivarensUnderlagFormContext()
    const { utbildningar } = watch()
    const kalenderhalvar = utbildningar?.[utbildningsIndex].studieersattningAndStudielon?.kalenderhalvar

    return (
        <Stack spacing={2}>
            {kalenderhalvar?.map((_, kalenderhalvarIndex) => (
                <Kalenderhalvar
                    isReadonly={isReadonly}
                    key={kalenderhalvarIndex}
                    utbildningsIndex={utbildningsIndex}
                    kalenderhalvarIndex={kalenderhalvarIndex}
                />
            ))}
            {!isReadonly && <LaggTillKalenderhalvarButton utbildningsIndex={utbildningsIndex} />}
            <InformationWithWarningIcon warningText={t('aterbetalningsskyldigText')} />
        </Stack>
    )
}

export default KalenderhalvarContainer
