import React, { Fragment } from 'react'
import { Card, CardContent, CardHeader, Typography, Stack, Divider } from '@mui/material'
import { formatDateFromDateTime } from '@local/src/utils/helpers/dateTimeFormatHelper'
import { CsnBeslutsutfall } from '@local/src/services/SwaggerApi/data-contracts'
import { useTranslation } from 'react-i18next'

import { useGetBeslutFromCsnByAnsokanId } from '../../Hooks/useGetBeslutFromCsnByAnsokanId'

import BeslutFranCsnListItemBaseInformation from './BeslutFranCsnListItemBaseInformation'
import BeslutFranCsnListItemUtbildningar from './BeslutFranCsnListItemUtbildningar'
import BeslutFranCsnListItemStudieperioder from './BeslutFranCsnListItemStudieperioder'

interface Props {
    ansokanId: string
}

const BeslutFranCsnList = ({ ansokanId }: Props) => {
    const { data: beslutFranCsn, isSuccess } = useGetBeslutFromCsnByAnsokanId(ansokanId)

    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokan.steps.csn' })

    const hasBeslutFranCsn = isSuccess && beslutFranCsn.length > 0

    return hasBeslutFranCsn ? (
        <Stack spacing={3}>
            {beslutFranCsn.map(({ inkomFranCsn, beslutstyp, beslutsutfall, utbildningar, beslutsperioder, id }) => (
                <Card data-testid="beslut-fran-csn-list-item" key={id} sx={{ p: 0 }}>
                    <CardHeader
                        titleTypographyProps={{ variant: 'h6' }}
                        title={`${t('beslutInkommet')}: ${formatDateFromDateTime(inkomFranCsn)}`}
                        sx={{ pb: 0, mb: 2 }}
                    />
                    <CardContent sx={{ pt: 0 }}>
                        <Stack spacing={2}>
                            <BeslutFranCsnListItemBaseInformation
                                inkomFranCsn={inkomFranCsn}
                                beslutstyp={beslutstyp}
                                beslutsutfall={beslutsutfall}
                            />
                            <Divider />
                            {beslutsutfall === CsnBeslutsutfall.Bifall ? (
                                <Fragment>
                                    <BeslutFranCsnListItemUtbildningar utbildningar={utbildningar} />
                                    <BeslutFranCsnListItemStudieperioder studieperioder={beslutsperioder} />
                                </Fragment>
                            ) : (
                                <Typography variant="subtitle1">{t('beslutNoMoreInfo')}</Typography>
                            )}
                        </Stack>
                    </CardContent>
                </Card>
            ))}
        </Stack>
    ) : (
        <Typography data-testid="beslut-fran-csn-no-beslut">{t('beslutIngaBeslut')}</Typography>
    )
}

export default BeslutFranCsnList
