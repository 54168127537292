import { useQuery } from '@tanstack/react-query'
import { ReactQueryKey, studiestodMedarbetareApi } from '@local/src/services/apiEndpointHelper'
import { apiGetTryCatchWrapper } from '@local/src/services/apiRequestHelper'

export const useGetStudiestodList = (arendeId: string, ejAktuell = false) => {
    const { data: studiestodsansokningar, ...query } = useQuery({
        queryKey: [ReactQueryKey.studiestodList, arendeId, ejAktuell],
        queryFn: async () => {
            const data = await apiGetTryCatchWrapper('useGetStudiestodList', studiestodMedarbetareApi.arendeAnsokanDetail, {
                arendeId,
                ejAktuell,
            })

            return data.filter((s) => s.isPublicerad)
        },
        keepPreviousData: true,
        placeholderData: [],
    })

    return { studiestodsansokningar, ...query }
}
