import { RadioButtonGroup, TextArea } from '@local/src/components/FormComponents'
import React, { ReactNode } from 'react'
import { Stack, Typography } from '@mui/material'
import InfoPopover from '@local/src/components/InfoPopover'

import { AktivitetsstodProcess } from './Components'
import { useRadgivarensUnderlagFormContext } from './Hooks'

interface Props {
    label: string
    tooltipContent?: ReactNode
    placeholderText?: string
    isReadonly?: boolean
    subLabel?: string
    popoverContent?: {
        content: string
        link?: string
        linkText?: string
    }
}

const HarAktivitetsstod = ({ label, isReadonly, popoverContent }: Props) => {
    const { watch } = useRadgivarensUnderlagFormContext()
    const { hasAktivitetstod } = watch()

    const isAktivitetsstodRadioButtonTrue = hasAktivitetstod === true

    return (
        <Stack id="hasAktivitetstod" spacing={1}>
            <Stack direction="row" alignItems="flex-start" spacing={1}>
                <Typography fontWeight="bold">{label}</Typography>
                <InfoPopover content={popoverContent.content} />
            </Stack>
            <RadioButtonGroup
                name="hasAktivitetstod"
                label=""
                valueType="boolean"
                radios={[
                    { label: 'Ja', value: true, disabled: isReadonly },
                    { label: 'Nej', value: false, disabled: isReadonly },
                ]}
            />

            {isAktivitetsstodRadioButtonTrue ? <AktivitetsstodProcess /> : null}
        </Stack>
    )
}

const MotiveringArbetsmarknad = ({ label, placeholderText, isReadonly, popoverContent }: Props) => (
    <Stack id="motiveringArbetsmarknad" spacing={1}>
        <Stack direction="row" alignItems="flex-start" spacing={1}>
            <Typography fontWeight="bold">{label}</Typography>
            <InfoPopover content={popoverContent.content} />
        </Stack>
        <TextArea disabled={isReadonly} name="motiveringArbetsmarknad" placeholder={placeholderText} testId="motiveringArbetsmarknad" />
    </Stack>
)

const MotiveringUtbildningsmalet = ({ label, placeholderText, isReadonly, subLabel, popoverContent }: Props) => (
    <Stack id="motiveringUtbildningsmalet" spacing={1}>
        <Stack direction="row" alignItems="flex-start" spacing={1}>
            <Typography fontWeight="bold">{label}</Typography>
            <InfoPopover content={popoverContent.content} link={popoverContent.link} linkText={popoverContent.linkText} />
        </Stack>
        <Typography variant="body2">{subLabel}</Typography>
        <TextArea disabled={isReadonly} name="motiveringUtbildningsmal" placeholder={placeholderText} />
    </Stack>
)

export { HarAktivitetsstod, MotiveringArbetsmarknad, MotiveringUtbildningsmalet }
