import { useState } from 'react'
import { studiestodMedarbetareApi } from '@local/src/services/apiEndpointHelper'
import { useMutation } from '@tanstack/react-query'
import { apiRequestTryCatchWrapper } from '@local/src/services/apiRequestHelper'
import { EjAktuellApiModel } from '@local/src/services/SwaggerApi/data-contracts'

import useGetCurrentStudiestodansokan from './useGetCurrentStudiestodansokan'

interface SetEjAktuellParams extends EjAktuellApiModel {
    setEjAktuell: boolean
}

const useSetEjAktuell = () => {
    const [apiResponse, setApiResponse] = useState(undefined)
    const { studiestodAnsokan, invalidateQuery } = useGetCurrentStudiestodansokan()

    const setEjAktuellMutation = useMutation({
        mutationFn: (requestModel: SetEjAktuellParams) => {
            setApiResponse(undefined)
            return apiRequestTryCatchWrapper(
                'useSetEjAktuell',
                studiestodMedarbetareApi.arendeAnsokanEjAktuellPartialUpdate,
                studiestodAnsokan.arendeId,
                studiestodAnsokan.id,
                requestModel
            )
        },
        onSuccess: async (response) => {
            setApiResponse(response)
            await invalidateQuery()
        },
        onError: (response: { message?: string }) => {
            setApiResponse({
                hasError: true,
                isSuccess: false,
                errorMessage: response?.message,
                errorType: 'Generic Error',
            })
        },
    })

    return { apiResponse, setEjAktuellMutation }
}

export default useSetEjAktuell
