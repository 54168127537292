import { useEffect, useState } from 'react'
import { getBaseRequestParams, studiestodMedarbetareApi } from '@local/src/services/apiEndpointHelper'

import useGetCurrentStudiestodansokan from './useGetCurrentStudiestodansokan'

const useHandlaggAnsokanStudiestod = () => {
    const { studiestodAnsokan, invalidateQuery, isFetching } = useGetCurrentStudiestodansokan()
    const [isPosting, setIsPosting] = useState(false)

    const handlaggAnsokanStudiestod = async () => {
        if (isPosting) return
        setIsPosting(true)
        await studiestodMedarbetareApi.arendeAnsokanHandlaggPartialUpdate(
            studiestodAnsokan?.arendeId,
            studiestodAnsokan?.id,
            getBaseRequestParams()
        )
        await invalidateQuery()
        setIsPosting(false)
    }

    useEffect(() => {
        if (isFetching) return
        if (studiestodAnsokan?.isHandlaggs) return
        void handlaggAnsokanStudiestod()
    }, [studiestodAnsokan?.isHandlaggs, isFetching])

    return { isHandlaggs: studiestodAnsokan?.isHandlaggs }
}

export default useHandlaggAnsokanStudiestod
