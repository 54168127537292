import { StudiestodApiModel, StudiestodListApiModel } from '@local/src/services/SwaggerApi/data-contracts'
import { zonedTimeToUtc } from 'date-fns-tz'

export const mojligaArUtbetalningsstart = (): {
    label: string
    value: number
}[] => {
    const currentYear = zonedTimeToUtc(new Date(), 'Europe/Stockholm').getUTCFullYear()

    return generateMojligaArUtbetalningsstart(currentYear - 1, currentYear + 4)
}

export const generateMojligaArUtbetalningsstart = (start: number, end: number): { label: string; value: number }[] => {
    const interval: number = end - start + 1
    return Array.from({ length: interval }, (element, index) => {
        const currentPosition = start + index
        return {
            label: currentPosition.toString(),
            value: currentPosition,
        }
    })
}

export const getStudiestodTitle = (studiestod: StudiestodApiModel | StudiestodListApiModel) =>
    studiestod?.studiestodNummer ? `#${studiestod?.studiestodNummer} - ${studiestod?.ansokanNamn}` : studiestod?.ansokanNamn
