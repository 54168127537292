import { Dropdown, RadioButtonGroup } from '@local/src/components/FormComponents'
import { generateMojligaArDropdownOptionsUtbetalningsstart } from '@local/src/features/StudiestodAnsokan/Components/Steps/RadgivarensUnderlag/Helpers/generateYears'
import React, { Fragment } from 'react'
import { zonedTimeToUtc } from 'date-fns-tz'
import { useGetCurrentStudiestodansokan } from '@local/src/features/StudiestodAnsokan/Hooks'
import { formatToDate } from '@local/src/utils/helpers/dateTimeFormatHelper'
import { Box, Grid2 as Grid, Typography } from '@mui/material'
import LabelValue from '@local/src/features/StudiestodAnsokan/Components/LabelValue/LabelValue'
import { useTranslation } from 'react-i18next'

interface Props {
    utbildningsIndex: number
    isReadonly: boolean
}

const PreliminarErsattning = ({ utbildningsIndex, isReadonly }: Props) => {
    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokan.steps.radgivarensUnderlag' })

    const { studiestodAnsokan } = useGetCurrentStudiestodansokan()
    const beraknadStudiestartValue = studiestodAnsokan?.utbildningar?.[utbildningsIndex]?.studiestart

    const mojligaArUtbetalningsstart = () => {
        const currentYear = zonedTimeToUtc(new Date(), 'Europe/Stockholm').getUTCFullYear()
        return generateMojligaArDropdownOptionsUtbetalningsstart(currentYear - 1, currentYear + 4)
    }

    return (
        <Fragment>
            <Typography variant="h6">{t('preliminarErsattningHeader')}</Typography>
            <Grid container>
                <Grid size={{ xs: 6 }} container direction="column">
                    <Grid>
                        <LabelValue label={t('beraknadStudiestartText')} value={formatToDate(beraknadStudiestartValue)} />
                    </Grid>
                </Grid>
                <Grid size={{ xs: 6 }} container direction="column">
                    <Grid>
                        <Typography variant="caption" color="text.secondary">
                            {t('beraknadUtbetalningstartHeader')}
                        </Typography>
                        <Box mt={1}>
                            <Dropdown
                                name={`utbildningar.${utbildningsIndex}.studieersattningAndStudielon.beraknadUtbetalningsstart.year`}
                                label="År"
                                options={mojligaArUtbetalningsstart()}
                                disabled={isReadonly}
                            />
                        </Box>
                    </Grid>
                    <Grid>
                        <RadioButtonGroup
                            name={`utbildningar.${utbildningsIndex}.studieersattningAndStudielon.beraknadUtbetalningsstart.termin`}
                            testId={`utbildningar.${utbildningsIndex}.studieersattningAndStudielon.beraknadUtbetalningsstart.termin`}
                            label=""
                            valueType="number"
                            row
                            isReadOnly={isReadonly}
                            radios={[
                                {
                                    label: t('varTerminLabel'),
                                    value: 0,
                                    disabled: isReadonly,
                                },
                                {
                                    label: t('hostTerminLabel'),
                                    value: 1,
                                    disabled: isReadonly,
                                },
                            ]}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Fragment>
    )
}

export default PreliminarErsattning
