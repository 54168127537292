import { Dropdown, RadioButtonGroup } from '@local/src/components/FormComponents'
import { SelectOption } from '@local/src/components/FormComponents/Dropdown/Dropdown.types'
import { useRadgivarensUnderlagFormContext } from '@local/src/features/StudiestodAnsokan/Components/Steps/RadgivarensUnderlag/Hooks'
import { RadgivarensUnderlagForm } from '@local/src/features/StudiestodAnsokan/Components/Steps/RadgivarensUnderlag/Models/RadgivarensUnderlagForm'
import React, { useEffect, useState } from 'react'
import { FieldPath } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material'
import { isNotNil } from 'ramda'

interface Props {
    isReadonly: boolean
    utbildningIndex: number
}

const NekaStod = ({ isReadonly, utbildningIndex }: Props) => {
    const { watch, setValue, resetField } = useRadgivarensUnderlagFormContext()
    const { nekaStod, studiestodTyp, omstallningsinsatsTyp } = watch(`utbildningar.${utbildningIndex}`)

    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokan.steps.radgivarensUnderlag.nekaStod' })
    const { t: tDelete } = useTranslation('translation', { keyPrefix: 'studiestodAnsokan.steps.radgivarensUnderlag.confirmDeleteStod' })
    const orsaker = t('orsaker', { returnObjects: true })
    const savedOrsak = nekaStod?.orsak
    const savedOrsakIsDeleted = savedOrsak && !orsaker.includes(savedOrsak)
    const [showModal, setShowModal] = useState(false)

    const isAnyTrue = (obj?: object) => isNotNil(obj) && Object.values(obj).some((value) => value === true)
    useEffect(() => {
        if (isReadonly) return
        const anyStodSelected = isAnyTrue(studiestodTyp)
        const anyInsatsSelected = isAnyTrue(omstallningsinsatsTyp)
        if (nekaStod?.isStodNekat) {
            resetField(`utbildningar.${utbildningIndex}.nekaStod.orsak`)
        }
        if (nekaStod?.isStodNekat && (anyStodSelected || anyInsatsSelected)) {
            setShowModal(true)
        }
        if (nekaStod?.isStodNekat === false) {
            setValue(`utbildningar.${utbildningIndex}.nekaStod.orsak`, null)
            if (!anyStodSelected) {
                resetField(`utbildningar.${utbildningIndex}.studiestodTyp`)
                resetField(`utbildningar.${utbildningIndex}.studieersattningAndStudielon`)
                resetField(`utbildningar.${utbildningIndex}.koptUtbildning`)
                resetField(`utbildningar.${utbildningIndex}.kortvarigtStudiestod`)
                resetField(`utbildningar.${utbildningIndex}.kompletterandeStudiestod`)
            }
            if (!anyInsatsSelected) {
                resetField(`utbildningar.${utbildningIndex}.omstallningsinsatsTyp`)
            }
        }
    }, [nekaStod?.isStodNekat, isReadonly])

    if (savedOrsakIsDeleted) orsaker.push(savedOrsak)

    const orsakerOptions: SelectOption[] = orsaker.map((orsak) => ({ label: orsak, value: orsak }))

    const name: FieldPath<RadgivarensUnderlagForm> = `utbildningar.${utbildningIndex}.nekaStod`

    const onConfirm = () => {
        setValue(`utbildningar.${utbildningIndex}.studiestodTyp`, {
            hasKompletterandeStudiestod: false,
            hasKoptUtbildning: false,
            hasKortvarigtStudiestod: false,
            hasStudieersattning: false,
            hasStudielon: false,
        })
        setValue(`utbildningar.${utbildningIndex}.omstallningsinsatsTyp`, {
            hasEnstakaResor: false,
            hasLogi: false,
            hasProgramvaror: false,
            hasRegelbundnaResor: false,
            hasUtbildningskostnader: false,
        })
        setValue(`utbildningar.${utbildningIndex}.studieersattningAndStudielon`, undefined)
        setValue(`utbildningar.${utbildningIndex}.koptUtbildning`, undefined)
        setValue(`utbildningar.${utbildningIndex}.kortvarigtStudiestod`, undefined)
        setValue(`utbildningar.${utbildningIndex}.kompletterandeStudiestod`, undefined)
        setShowModal(false)
    }

    const onCancel = () => {
        setShowModal(false)
        setValue(`utbildningar.${utbildningIndex}.nekaStod.isStodNekat`, false)
    }

    return (
        <Stack spacing={2}>
            <RadioButtonGroup
                name={`${name}.isStodNekat`}
                label={t('radioButtonLabel')}
                valueType="boolean"
                radios={[
                    { label: 'Ja', value: false, disabled: isReadonly },
                    { label: 'Nej', value: true, disabled: isReadonly },
                ]}
                row
            />
            {nekaStod?.isStodNekat && (
                <Dropdown disabled={isReadonly} label={t('dropdownLabel')} name={`${name}.orsak`} options={orsakerOptions} />
            )}
            <Dialog open={showModal} data-testid="modal-studiestodstyp">
                <DialogTitle>{tDelete('header')}</DialogTitle>
                <DialogContent>{tDelete('description')}</DialogContent>
                <DialogActions>
                    <Button data-testid="dialog--cancel-button" onClick={onCancel} variant="outlined">
                        {tDelete('cancel')}
                    </Button>
                    <Button data-testid="dialog--confirm-button" onClick={onConfirm} variant="contained">
                        {tDelete('confirm')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Stack>
    )
}

export default NekaStod
