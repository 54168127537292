import { FormValidationTexts } from '@local/src/content/resources'
import { KoptUtbildningApiModel } from '@local/src/services/SwaggerApi/data-contracts'
import { nullableNumberSchema } from '@local/src/utils/Yup/YupHelper'
import { SchemaOf, object as yupObject } from 'yup'

export const KoptUtbildningDraftSchema = (formValidationTexts?: FormValidationTexts): SchemaOf<KoptUtbildningApiModel> =>
    yupObject({
        kostnad: nullableNumberSchema()
            .integer(formValidationTexts?.heltal)
            .min(1, formValidationTexts?.kostnadMin)
            .max(1000000, formValidationTexts?.kostnadMax),
    }).nullable()

export const KoptUtbildningSchema = (formValidationTexts?: FormValidationTexts): SchemaOf<KoptUtbildningApiModel> =>
    KoptUtbildningDraftSchema(formValidationTexts).concat(
        yupObject({
            kostnad: nullableNumberSchema().required(formValidationTexts?.kostnad),
        })
    )
