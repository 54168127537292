import React from 'react'
import { Box, List, ListItem, ListItemIcon, Stack, Typography } from '@mui/material'
import CircleIcon from '@mui/icons-material/Circle'

export interface IAktivitetsstodEntry {
    header: string
    content: string
    entries?: IAktivitetsstodEntryItem[]
}

export interface IAktivitetsstodEntryItem {
    header: string | JSX.Element
    content?: JSX.Element
}

export const AktivitetsstodEntry = ({ header, content, entries }: IAktivitetsstodEntry): JSX.Element => {
    const listItems = entries ? (
        <List>
            {entries.map((listItemRow, index) => (
                <ListItem key={index} divider={false}>
                    <Box sx={{ width: '100%' }}>
                        <Stack direction="row" alignItems="center">
                            <ListItemIcon sx={{ minWidth: 24 }}>
                                <CircleIcon sx={{ fontSize: 8 }} />
                            </ListItemIcon>
                            <Typography variant="subtitle2">{listItemRow.header}</Typography>
                        </Stack>
                        <Box sx={{ ml: 3 }}>{listItemRow.content}</Box>
                    </Box>
                </ListItem>
            ))}
        </List>
    ) : null

    return (
        <Box>
            <Typography variant="h6">{header}</Typography>
            <Typography variant="subtitle2" color="text.secondary">
                {content}
            </Typography>
            {listItems}
        </Box>
    )
}
