import { zonedTimeToUtc } from 'date-fns-tz'

export const generateMojligaArDropdownOptionsUtbetalningsstart = (start: number, end: number): { label: string; value: number }[] => {
  const interval: number = end - start + 1
  return Array.from({ length: interval }, (element, index) => {
    const currentPosition = start + index
    return {
      label: currentPosition.toString(),
      value: currentPosition,
    }
  })
}

export const generateMojligaArUtbetalningsstart = () => {
  const currentYear = zonedTimeToUtc(new Date(), 'Europe/Stockholm').getUTCFullYear()
  const minYear = currentYear - 1
  const maxYear = currentYear + 4
  return { current: currentYear, min: minYear, max: maxYear }
}
