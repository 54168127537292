interface IConfiguration {
    API_URL: string
    AUTHORITY_URL: string
    AUTHORITY_CLIENT_ID: string
    MEDIA_URL: string
    REPLACEABLE: string
}

// This configuration will be used for local development
const compileTimeConfiguration = {
    API_URL: process.env.API_URL ?? '',
    AUTHORITY_URL: process.env.AUTHORITY_URL ?? '',
    AUTHORITY_CLIENT_ID: process.env.AUTHORITY_CLIENT_ID ?? '',
    MEDIA_URL: process.env.MEDIA_URL ?? '',
}

// This is the object that will be replaced in the release pipeline, do not change this
const APP_CONFIGURATION = {
    REPLACEABLE: 'CONFIG',
}

const getConfig = (): IConfiguration => ({
    ...compileTimeConfiguration,
    ...APP_CONFIGURATION,
})

export default getConfig
