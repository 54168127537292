import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ArendeTyp } from '@local/src/@types/Arenden.types'
import { getDefaultFormData } from '@local/src/features/StudiestodAnsokan/Components/Steps/RadgivarensUnderlag/Helpers'
import { useTranslation } from 'react-i18next'

import { StudiestodApiResponse } from '../Models/StudiestodansokanApiResponse'
import { RadgivarensUnderlagForm } from '../Components/Steps/RadgivarensUnderlag/Models/RadgivarensUnderlagForm'
import {
    RadgivarensUnderlagDraftSchema,
    RadgivarensUnderlagSubmitSchema,
} from '../Components/Steps/RadgivarensUnderlag/Schemas/RadgivarensUnderlag.schema'

const useUpdateUnderlagForm = (studiestodAnsokan: StudiestodApiResponse, arendeTyp: ArendeTyp) => {
    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokan.steps.radgivarensUnderlag' })

    const formValidationTexts = t('formValidationTexts', { returnObjects: true })

    const defaultFormData = getDefaultFormData(studiestodAnsokan)

    const draftSchema = RadgivarensUnderlagDraftSchema(formValidationTexts, arendeTyp)

    const submitSchema = RadgivarensUnderlagSubmitSchema(
        formValidationTexts,
        arendeTyp,
        studiestodAnsokan?.showOnlyStudiestodsTypStudieersattningAndStudielon
    )

    const formMethods = useForm<RadgivarensUnderlagForm>({
        values: defaultFormData,
        resolver: (data: RadgivarensUnderlagForm, context, options) => {
            return yupResolver(data.isUpdate ? draftSchema : submitSchema)(data, context, options)
        },
    })

    return formMethods
}

export default useUpdateUnderlagForm
