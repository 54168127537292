import { Link, Stack, Typography } from '@mui/material'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import React from 'react'

interface Props {
    customIcon?: React.ReactNode | React.ReactNode[]
    linkText: string
    href: string
    testId?: string
    withoutIcon?: boolean
}

export const ExternalLink = ({ customIcon, linkText, href, testId, withoutIcon }: Props) => {
    const icon = customIcon ? customIcon : <OpenInNewIcon />
    return (
        <Link
            component="a"
            underline="hover"
            target="_blank"
            rel="noopener noreferrer"
            data-testid={testId}
            href={href}
            maxWidth="fit-content"
        >
            <Stack direction="row" alignItems="center" spacing={1}>
                {!withoutIcon && icon}
                <Typography>{linkText}</Typography>
            </Stack>
        </Link>
    )
}
