import { StudieersattningAndStudielonApiModel } from '@local/src/services/SwaggerApi/data-contracts'
import { object as yupObject, array as yupArray, SchemaOf } from 'yup'
import { FormValidationTexts } from '@local/src/content/resources'

import { BeraknadUtbetalningsstartDraftSchema, BeraknadUtbetalningsstartSchema } from './BeraknadUtbetalningsstartSchema'
import { KalenderhalvarDraftSchema, KalenderhalvarSchema, KalenderhalvarSchemaProps } from './KalenderhalvarSchema'
import {
    MotiveringStudielonSchema,
    MotiveringStudielonDraftSchema,
    MotiveringStudielonWhenOnlyStudieersattningSchema,
} from './MotiveringStudielonSchema'

export const KalenderhalvarValidation = (
    { isStudieersattningRequired, isStudielonRequired }: KalenderhalvarSchemaProps,
    formValidationTexts?: FormValidationTexts
) => yupArray().required().min(1).max(4).of(KalenderhalvarSchema({ isStudieersattningRequired, isStudielonRequired }, formValidationTexts))

export const KalenderhalvarDraftValidation = (
    { isStudieersattningRequired, isStudielonRequired }: KalenderhalvarSchemaProps,
    formValidationTexts?: FormValidationTexts
) =>
    yupArray()
        .required()
        .min(1)
        .max(4)
        .of(KalenderhalvarDraftSchema({ isStudieersattningRequired, isStudielonRequired }, formValidationTexts))

export const StudieersattningAndStudielonBaseSchema: SchemaOf<StudieersattningAndStudielonApiModel> = yupObject()

export const StudieersattningAndStudielonSchema = (
    formValidationTexts?: FormValidationTexts
): SchemaOf<StudieersattningAndStudielonApiModel> =>
    StudieersattningAndStudielonDraftSchema(formValidationTexts).concat(
        yupObject({
            beraknadUtbetalningsstart: BeraknadUtbetalningsstartSchema(formValidationTexts),
            kalenderhalvar: KalenderhalvarValidation({ isStudieersattningRequired: true, isStudielonRequired: true }, formValidationTexts),
            motiveringStudielon: MotiveringStudielonSchema(formValidationTexts),
        })
    )

export const StudieersattningAndStudielonDraftSchema = (
    formValidationTexts?: FormValidationTexts
): SchemaOf<StudieersattningAndStudielonApiModel> =>
    yupObject({
        beraknadUtbetalningsstart: BeraknadUtbetalningsstartDraftSchema(formValidationTexts),
        kalenderhalvar: KalenderhalvarDraftValidation({ isStudieersattningRequired: true, isStudielonRequired: true }, formValidationTexts),
        motiveringStudielon: MotiveringStudielonDraftSchema(),
    })

export const HasOnlyStudieersattningDraftSchema = (
    formValidationTexts?: FormValidationTexts
): SchemaOf<StudieersattningAndStudielonApiModel> =>
    yupObject().shape({
        beraknadUtbetalningsstart: BeraknadUtbetalningsstartDraftSchema(formValidationTexts),
        kalenderhalvar: KalenderhalvarDraftValidation(
            { isStudieersattningRequired: true, isStudielonRequired: false },
            formValidationTexts
        ),
        motiveringStudielon: MotiveringStudielonWhenOnlyStudieersattningSchema(),
    })

export const HasOnlyStudieersattningSchema = (formValidationTexts?: FormValidationTexts): SchemaOf<StudieersattningAndStudielonApiModel> =>
    HasOnlyStudieersattningDraftSchema().concat(
        yupObject().shape({
            beraknadUtbetalningsstart: BeraknadUtbetalningsstartSchema(formValidationTexts),
            kalenderhalvar: KalenderhalvarValidation({ isStudieersattningRequired: true, isStudielonRequired: false }, formValidationTexts),
            motiveringStudielon: MotiveringStudielonWhenOnlyStudieersattningSchema(),
        })
    )

export const HasOnlyStudielonSchema = (formValidationTexts?: FormValidationTexts): SchemaOf<StudieersattningAndStudielonApiModel> =>
    HasOnlyStudielonDraftSchema(formValidationTexts).concat(
        yupObject({
            beraknadUtbetalningsstart: BeraknadUtbetalningsstartSchema(formValidationTexts),
            kalenderhalvar: KalenderhalvarValidation({ isStudieersattningRequired: false, isStudielonRequired: true }, formValidationTexts),
            motiveringStudielon: MotiveringStudielonSchema(formValidationTexts),
        })
    )

export const HasOnlyStudielonDraftSchema = (formValidationTexts?: FormValidationTexts): SchemaOf<StudieersattningAndStudielonApiModel> =>
    yupObject({
        beraknadUtbetalningsstart: BeraknadUtbetalningsstartDraftSchema(formValidationTexts),
        kalenderhalvar: KalenderhalvarDraftValidation(
            { isStudieersattningRequired: false, isStudielonRequired: true },
            formValidationTexts
        ),
        motiveringStudielon: MotiveringStudielonDraftSchema(),
    })
