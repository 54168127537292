import { useGetCurrentStudiestodansokan } from '@local/src/features/StudiestodAnsokan/Hooks'
import React, { Fragment } from 'react'
import { Divider, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { useRadgivarensUnderlagFormContext } from '../../../../Hooks'

import { PreliminarErsattning, SkalTillStudielon, KalenderhalvarContainer } from './Components'
import ExcelLinks from './Components/ExcelLinks/ExcelLinks'
import VarningarForEkonomiskStod from './Components/VarningarForEkonomisktStod/VarningarForEkonomisktStod'

interface Props {
    utbildningsIndex: number
    isReadonly: boolean
}

const StudieersattningStudielon = ({ utbildningsIndex, isReadonly }: Props) => {
    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokan.steps.radgivarensUnderlag' })
    const { watch } = useRadgivarensUnderlagFormContext()
    const { utbildningar } = watch()
    const { studiestodAnsokan } = useGetCurrentStudiestodansokan()

    const currentUtbildning = utbildningar[utbildningsIndex]
    const renderSkalTillStudielon = currentUtbildning.studiestodTyp.hasStudielon
    const isStudiestodOrStudielon = currentUtbildning.studieersattningAndStudielon

    return (
        <Fragment>
            <Divider />
            <Stack spacing={2}>
                <Typography variant="h5">{t('studieersattningStudielonHeader')}</Typography>
                {renderSkalTillStudielon && (
                    <SkalTillStudielon currentUtbildning={currentUtbildning} utbildningsIndex={utbildningsIndex} isReadonly={isReadonly} />
                )}
                <PreliminarErsattning utbildningsIndex={utbildningsIndex} isReadonly={isReadonly} />
                <ExcelLinks />
                <VarningarForEkonomiskStod utbetalningsforutsattningar={studiestodAnsokan?.radgivarunderlag?.utbetalningsforutsattningar} />
                {isStudiestodOrStudielon && <KalenderhalvarContainer utbildningsIndex={utbildningsIndex} isReadonly={isReadonly} />}
            </Stack>
        </Fragment>
    )
}

export default StudieersattningStudielon
