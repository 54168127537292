import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material'
import React from 'react'
import { useController } from 'react-hook-form'
import { isNotNil } from 'ramda'

import { SelectOption } from './Dropdown.types'

interface Props {
    label: string
    name: string
    options: SelectOption[]
    testId?: string
    disabled?: boolean
}

const Dropdown = ({ label, name, testId, options, disabled = false }: Props) => {
    const {
        field,
        fieldState: { error },
    } = useController({ name })
    return (
        <FormControl fullWidth error={Boolean(error)} disabled={disabled}>
            <InputLabel shrink={isNotNil(field.value)} sx={{ fontWeight: 400 }}>
                {label}
            </InputLabel>
            <Select
                ref={field.ref}
                onChange={(event) => {
                    field.onChange(event.target.value)
                }}
                onBlur={field.onBlur}
                name={field.name}
                value={field.value}
                label={label}
                data-testid={testId || name}
                MenuProps={{
                    sx: {
                        maxHeight: '500px',
                    },
                }}
            >
                {options.map((option) => (
                    <MenuItem key={`${option.value}`} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
            <FormHelperText>{error?.message}</FormHelperText>
        </FormControl>
    )
}

export default Dropdown
