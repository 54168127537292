import { useQuery } from '@tanstack/react-query'
import { useRouteMatch } from 'react-router-dom'
import { fetchRequest } from '@local/src/utils/network'
import { ICaseDetails } from '@local/src/@types/Case.types'
import { ReactQueryKey } from '@local/src/services/apiEndpointHelper'
import { useAuthentication } from '@trr/app-shell-data'

import { studiestodTabPage } from '../../Utils/studiestodAnsokanConstants'

const useGetCurrentArende = (arendeId?: string) => {
    const { params } = useRouteMatch<{ arendeId: string }>({
        path: studiestodTabPage,
    })

    const currentArendeId = arendeId || params?.arendeId

    const { idToken } = useAuthentication()

    const getArendeById = async () => {
        try {
            const response: Response = await fetchRequest({
                method: 'GET',
                id_token: idToken,
                url: `/arenden/${currentArendeId}`,
                apiVersion: '2.0',
            })
            if (response.ok) {
                return (await response.json()) as ICaseDetails
            }
        } catch (error) {
            console.log(error)
        }

        return undefined
    }

    const { data: arende, ...query } = useQuery({
        queryKey: [ReactQueryKey.getArendeById, currentArendeId],
        queryFn: getArendeById,
        enabled: Boolean(currentArendeId),
        staleTime: 2 * 60 * 1000,
    })

    return { arende, ...query }
}
export default useGetCurrentArende
