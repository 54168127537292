import { Alert, AlertTitle, Stack, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
    orsak: string
}

const EjAktuellAlert = ({ orsak }: Props) => {
    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokan.ejAktuellAlert' })
    return (
        <Alert severity="warning" data-testid="ej-aktuell-alert">
            <AlertTitle>{t('title')}</AlertTitle>
            <Stack spacing={1}>
                <Typography>{t('description')}</Typography>
                <Typography>Orsak: {orsak}</Typography>
            </Stack>
        </Alert>
    )
}

export default EjAktuellAlert
