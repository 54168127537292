import React from 'react'
import Icons from '@local/src/components/Icons'
import { Stack, Typography } from '@mui/material'
import { ExternalLink } from '@local/src/components/ExternalLink'

interface Props {
    warningText: string
    linkText?: string
    link?: string
}

const InformationWithWarningIcon = ({ warningText, linkText, link }: Props) => (
    <Stack direction="row" spacing={1} alignItems={'center'}>
        <Icons.Warning />
        <Typography variant="body2">{warningText}</Typography>
        {linkText && <ExternalLink linkText={linkText} href={link} />}
    </Stack>
)

export default InformationWithWarningIcon
