import React, { Fragment } from 'react'
import { CsnUtbildningBeslutApiModel } from '@local/src/services/SwaggerApi/data-contracts'
import { Box, ListItem, ListItemText, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface Props {
    utbildningar: CsnUtbildningBeslutApiModel[] | null
}

const BeslutFranCsnListItemUtbildningar = ({ utbildningar }: Props) => {
    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokan.steps.csn' })

    const hasUtbildningar = utbildningar && utbildningar.length > 0

    return (
        <Box data-testid="beslut-fran-csn-utbildningar">
            {hasUtbildningar ? (
                <Fragment>
                    <Typography variant="h6">{t('utbildningar')}</Typography>
                    {utbildningar.map(({ utbildningsnamn, utbildningsanordnare, ort }, index) => (
                        <ListItem key={index}>
                            <ListItemText primary={utbildningsnamn} secondary={`${utbildningsanordnare}, ${ort}`} />
                        </ListItem>
                    ))}
                </Fragment>
            ) : (
                <Typography data-testid="beslut-fran-csn-no-utbildningar">{t('beslutIngaUtbildningar')}</Typography>
            )}
        </Box>
    )
}

export default BeslutFranCsnListItemUtbildningar
