import { log_error, log_info } from '@local/src/utils/helpers/logger'
import { HubConnection, HubConnectionState } from '@microsoft/signalr'
import { isNil } from 'ramda'
import { useEffect, useState } from 'react'

type StudiestodGroup = 'StudiestodEvent'

interface Props {
    hub: HubConnection
    groupName: StudiestodGroup
    groupIdentifier: string
}

const useSignalRJoinGroup = ({ hub, groupName, groupIdentifier }: Props) => {
    const [hubConnectionState, setHubConnectionState] = useState<HubConnectionState>(hub?.state ?? HubConnectionState.Disconnected)

    useEffect(() => {
        setHubConnectionState(hub?.state)
    }, [hub?.state])

    useEffect(() => {
        if (hubConnectionState !== HubConnectionState.Connected || isNil(groupIdentifier)) return

        const fullGroupName = `${groupName}-${groupIdentifier}`

        log_info('AddToGroup (hub method invoke) effect', { hubConnectionState, groupName, groupIdentifier, fullGroupName })
        hub.invoke('AddToGroup', fullGroupName).catch((error) => log_error('Error SignalR: AddToGroup', { error, fullGroupName }))

        return () => {
            if (hub && hub.state === HubConnectionState.Connected) {
                log_info('AddToGroup (hub method invoke) effect cleanup', { hubConnectionState, groupName, groupIdentifier, fullGroupName })
                hub.invoke('RemoveFromGroup', fullGroupName).catch((error) => log_error('Error SignalR: RemoveFromGroup', { error }))
            }
        }
    }, [hub, groupName, hubConnectionState, groupIdentifier])
}

export default useSignalRJoinGroup
