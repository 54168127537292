import { useTranslation } from 'react-i18next'

const useMapUtbildningstypValueToLabel = (utbildningstyp: string) => {
    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokan.steps.radgivarensUnderlag.utbildning' })

    if (!utbildningstyp) return ''

    return {
        'universitet-hogskola': t('universitetHogskolaText'),
        yrkeshogskola: t('yrkeshogskolaText'),
        arbetsmarknadsutbildning: t('arbetsmarknadsutbildningText'),
        gymnasieutbildning: t('gymnasieutbildningText'),
        annat: t('annatText'),
    }[utbildningstyp]
}

export default useMapUtbildningstypValueToLabel
