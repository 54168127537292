import { ApiErrorResponse } from '@local/services/ApiResponseModel'
import { TrrValidationErrorField } from '@local/services/SwaggerApi/data-contracts'
import { List, ListItem, ListItemText, Stack, Typography } from '@mui/material'
import React from 'react'

interface Props {
    error?: ApiErrorResponse
}

const ErrorResponse = ({ error }: Props) => {
    return (
        <Stack data-testid="api-error-response" spacing={2}>
            <Typography color="error" variant="h6" data-testid="api-error-response-header">
                {error?.errorMessage}
            </Typography>
            <List>
                {error?.validationErrors?.map((err: TrrValidationErrorField, index) => (
                    <ListItem key={index}>
                        <ListItemText primary={err.propertyName} secondary={err.errorMessage} />
                    </ListItem>
                ))}
            </List>
        </Stack>
    )
}

export default ErrorResponse
