import React, { Fragment } from 'react'
import { Alert, Divider, List, ListItem, Stack, Typography } from '@mui/material'
import Loader from '@local/src/components/Loader'
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined'
import { useTranslation } from 'react-i18next'

import { useGetCurrentStudiestodansokanStudieintyg } from '../../../Hooks'

import StudieintygItem from './Components/StudieintygItem/StudieintygItem'

const Studieintyg = () => {
    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokan.steps.studieintyg' })

    const { studieintygList, isError, isFetching } = useGetCurrentStudiestodansokanStudieintyg()

    const hasStudieintyg = studieintygList?.length > 0

    if (isFetching || isError)
        return (
            <Stack spacing={2} data-testid="form-steppers-section-studieintyg">
                <Typography variant="h4">{t('headerText')}</Typography>
                {isFetching && <Loader />}
                {isError && <Typography data-testid="error-loading-data">Hämtning misslyckades</Typography>}
            </Stack>
        )

    return (
        <Stack data-testid="studieintyg" spacing={2}>
            <Typography variant="h4">{t('headerText')}</Typography>
            <Stack direction={'row'} spacing={1} alignItems="flex-start">
                <LightbulbOutlinedIcon color="primary" fontSize="small" />
                <Typography>{t('tidigareDokumentInfoText')}</Typography>
            </Stack>
            <Typography variant="h5">{t('leadText')}</Typography>
            {!hasStudieintyg && <Divider />}
            <Typography data-testid={hasStudieintyg ? 'studieintyg-result' : 'no-studieintyg-result'}>
                {hasStudieintyg ? t('uppladdadAvText') : t('noStudieintygText')}
            </Typography>
            {hasStudieintyg && (
                <Fragment>
                    <Alert severity="warning">
                        <Typography data-testid="studieintyg-gdpr">{t('gdprInfoText')}</Typography>
                    </Alert>
                    <List data-testid="studieintyg-list">
                        <Divider />
                        <Stack spacing={2}>
                            {studieintygList.map((intyg) => (
                                <ListItem key={intyg.dokumentId} disableGutters data-testid="studieintyg-list-item">
                                    <StudieintygItem intyg={intyg} />
                                </ListItem>
                            ))}
                        </Stack>
                    </List>
                </Fragment>
            )}
        </Stack>
    )
}

export default Studieintyg
