import React, { useState } from 'react'
import { StudiestodListApiModel } from '@local/src/services/SwaggerApi/data-contracts'
import { Stack, Collapse, Typography, Box } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import { useTranslation } from 'react-i18next'
import Loader from '@local/src/components/Loader'

import AnsokanItemCard from './Components/AnsokanItemCard/AnsokanItemCard'
import NoResult from './Components/NoResult/NoResult'
import { useGetStudiestodList } from './Hooks/useGetStudiestodList'

interface Props {
    arendeId: string
    fornamn: string
}

const EjAktuellStudiestodAnsokanList = ({ arendeId, fornamn }: Props) => {
    const { studiestodsansokningar, isError, isFetching, isFetchedAfterMount } = useGetStudiestodList(arendeId, true)
    const isLoading = !isFetchedAfterMount && isFetching
    const hasAnsokanItems = !isError && !isLoading && studiestodsansokningar && studiestodsansokningar.length > 0

    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokanList' })

    const countStudiestodsansokningar = hasAnsokanItems ? studiestodsansokningar.length : 0
    const title = `${t('titleEjAktuell')} (${countStudiestodsansokningar})`

    const [isExpanded, setIsExpanded] = useState(false)
    const ExpandIcon = () => (isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />)

    return (
        <Box>
            <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                onClick={() => setIsExpanded(!isExpanded)}
                sx={{ cursor: 'pointer', width: 'fit-content' }}
                data-testid="ej-aktuell-studiestod-ansokan-list"
            >
                <Typography variant="h5">{title}</Typography>
                <ExpandIcon />
            </Stack>

            <Collapse in={isExpanded}>
                <Stack spacing={2} mt={2}>
                    {isLoading && <Loader />}

                    {isError ? (
                        <Typography>{t('hamtningsfelText')}</Typography>
                    ) : hasAnsokanItems ? (
                        <Stack spacing={4}>
                            {studiestodsansokningar.map((ansokan: StudiestodListApiModel) => (
                                <AnsokanItemCard key={ansokan.id} ansokan={ansokan} isEjAktuell />
                            ))}
                        </Stack>
                    ) : (
                        <NoResult
                            content={t('noResultDescriptionEjAktuell')}
                            fornamn={fornamn}
                            testSelector="ej-aktuell-studiestod-ansokan-no-result"
                        />
                    )}
                </Stack>
            </Collapse>
        </Box>
    )
}

export default EjAktuellStudiestodAnsokanList
