import React from 'react'

import { StudiestodStepEnum, StudiestodStepType } from '../../Models/StudiestodAnsokan.types'
import { AnsokanOmStudiestod, RadgivarensUnderlag, Beslut, CSN, Studieintyg } from '../Steps'

interface Props {
    activeStep: StudiestodStepType
}

const StudiestodActiveStepComponent = ({ activeStep }: Props) => {
    switch (activeStep) {
        case StudiestodStepEnum.ANSOKAN:
            return <AnsokanOmStudiestod />
        case StudiestodStepEnum.UNDERLAG: {
            return <RadgivarensUnderlag />
        }
        case StudiestodStepEnum.BESLUT: {
            return <Beslut />
        }
        case StudiestodStepEnum.CSN: {
            return <CSN />
        }
        case StudiestodStepEnum.STUDIEINTYG: {
            return <Studieintyg />
        }
    }
}

export default StudiestodActiveStepComponent
