import React from 'react'
import InfoIcon from '@mui/icons-material/Info'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { Alert, AlertTitle, Button, Link, Stack, Typography } from '@mui/material'
import { StudiestodUnlockState } from '@local/src/services/SwaggerApi/data-contracts'

import { useGetCurrentStudiestodansokan, useUnlockStudiestodAnsokanState } from '../../Hooks'
import SubmitResponse from '../SubmitResponse/SubmitResponse'

import useGetUnlockForEditingTextContent from './Hooks/useGetUnlockForEditingTextContent'

interface Props {
    unlockStep: StudiestodUnlockState
}

const StudiestodUnlockForEditingActionBox = ({ unlockStep }: Props) => {
    const {
        studiestodAnsokan: { isForbiddenToUnlockForEditing, isBeslutad, arendeId, id, radgivarunderlag },
    } = useGetCurrentStudiestodansokan()

    const isNeededToFirstUnlockBeslut = unlockStep !== StudiestodUnlockState.Beslut && isBeslutad

    const { unlockHeaderText, unlockDescriptionText, unlockButtonText, stateUnlockSuccessText, beslutLinkText } =
        useGetUnlockForEditingTextContent(
            unlockStep,
            isForbiddenToUnlockForEditing,
            isNeededToFirstUnlockBeslut,
            radgivarunderlag.isPublished
        )

    const { isPosting, apiResponse, handleUnlockStudiestodAnsokanState } = useUnlockStudiestodAnsokanState(unlockStep)

    const handleOnClick = () => {
        void handleUnlockStudiestodAnsokanState()
    }

    const Action = () => {
        const beslutLink = `/medarbetare/arenden/alla/arende/${arendeId}/studier/ansokan/${id}/beslut`

        if (isNeededToFirstUnlockBeslut) {
            return (
                <Link data-testid="unlock-state-action" href={beslutLink}>
                    {beslutLinkText}
                </Link>
            )
        } else {
            return (
                <Button
                    data-testid="unlock-state-action"
                    sx={{ width: 'fit-content' }}
                    disabled={isPosting}
                    size="small"
                    variant="outlined"
                    onClick={handleOnClick}
                >
                    {unlockButtonText}
                </Button>
            )
        }
    }

    return (
        <Alert
            data-testid={`unlock-state-action-box${isForbiddenToUnlockForEditing && '-forbidden'}`}
            severity={isForbiddenToUnlockForEditing ? 'success' : 'info'}
            icon={isForbiddenToUnlockForEditing ? <CheckCircleIcon color="success" /> : <InfoIcon color="info" />}
        >
            <AlertTitle>
                <Typography fontWeight="bold">{unlockHeaderText}</Typography>
            </AlertTitle>
            <Stack spacing={2}>
                <Typography variant="body2">{unlockDescriptionText}</Typography>
                {!isForbiddenToUnlockForEditing && <Action />}

                <SubmitResponse apiResponse={apiResponse} successMessage={stateUnlockSuccessText} />
            </Stack>
        </Alert>
    )
}

export default StudiestodUnlockForEditingActionBox
