export interface StudierExcelRaknesnurror {
    studierExcelRaknesnurraStudieersattningLink: string
    studierExcelRaknesnurraStudielonLink: string
    kompletterandeStudiestodRaknesnurraLink: string
    kompletterandeStudiestodRaknesnurraRemainingUrl: string
}

export const defaultStudierExcelRaknesnurrorContent: StudierExcelRaknesnurror = {
    studierExcelRaknesnurraStudieersattningLink: 'epi:studierExcelRaknesnurraStudieersattningLink',
    studierExcelRaknesnurraStudielonLink: 'epi:studierExcelRaknesnurraStudielonLink',
    kompletterandeStudiestodRaknesnurraLink: 'epi:kompletterandeStudiestodRaknesnurraLink',
    kompletterandeStudiestodRaknesnurraRemainingUrl: 'epi:kompletterandeStudiestodRaknesnurraRemainingUrl',
}
