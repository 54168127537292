/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    BeslutChangeLogApiModel,
    CsnArendeBeslutPeriodIsRegisteredPartialUpdateParams,
    CsnYttrandeBeslutApiModel,
    SearchBeslutRequestApiModel,
    SearchBeslutResponseApiModel,
    TrrProblemDetails,
    TrrValidationProblemDetails,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class CsnArendeBeslut<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags CsnArendeBeslut
     * @name CsnArendeBeslutSearchCreate
     * @request POST:/studiestod/csn-arende/beslut/search
     * @secure
     * @response `200` `SearchBeslutResponseApiModel` OK
     * @response `400` `TrrValidationProblemDetails` Bad Request
     * @response `500` `TrrProblemDetails` Internal Server Error
     */
    csnArendeBeslutSearchCreate = (data: SearchBeslutRequestApiModel, params: RequestParams = {}) =>
        this.request<SearchBeslutResponseApiModel, TrrValidationProblemDetails | TrrProblemDetails>({
            path: `/studiestod/csn-arende/beslut/search`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        })
    /**
     * No description
     *
     * @tags CsnArendeBeslut
     * @name CsnArendeBeslutHistorikDetail
     * @request GET:/studiestod/csn-arende/{grundansokanId}/beslut/historik
     * @secure
     * @response `200` `(BeslutChangeLogApiModel)[]` OK
     * @response `500` `TrrProblemDetails` Internal Server Error
     */
    csnArendeBeslutHistorikDetail = (grundansokanId: string, params: RequestParams = {}) =>
        this.request<BeslutChangeLogApiModel[], TrrProblemDetails>({
            path: `/studiestod/csn-arende/${grundansokanId}/beslut/historik`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        })
    /**
     * No description
     *
     * @tags CsnArendeBeslut
     * @name CsnArendeBeslutPeriodIsRegisteredPartialUpdate
     * @request PATCH:/studiestod/csn-arende/{grundansokanId}/beslut/period/{periodId}/is-registered
     * @secure
     * @response `204` `void` No Content
     * @response `500` `TrrProblemDetails` Internal Server Error
     */
    csnArendeBeslutPeriodIsRegisteredPartialUpdate = (
        { grundansokanId, periodId, ...query }: CsnArendeBeslutPeriodIsRegisteredPartialUpdateParams,
        params: RequestParams = {}
    ) =>
        this.request<void, TrrProblemDetails>({
            path: `/studiestod/csn-arende/${grundansokanId}/beslut/period/${periodId}/is-registered`,
            method: 'PATCH',
            query: query,
            secure: true,
            ...params,
        })
    /**
     * No description
     *
     * @tags CsnArendeBeslut
     * @name CsnBeslutAnsokanIdDetail
     * @request GET:/studiestod/csn/beslut/ansokanId/{studiestodId}
     * @secure
     * @response `200` `(CsnYttrandeBeslutApiModel)[]` OK
     * @response `500` `TrrProblemDetails` Internal Server Error
     */
    csnBeslutAnsokanIdDetail = (studiestodId: string, params: RequestParams = {}) =>
        this.request<CsnYttrandeBeslutApiModel[], TrrProblemDetails>({
            path: `/studiestod/csn/beslut/ansokanId/${studiestodId}`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        })
}
