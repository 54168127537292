import { FormValidationTexts } from '@local/src/content/resources'
import { KalenderhalvarApiModel } from '@local/src/services/SwaggerApi/data-contracts'
import { maxLengthValidationText, ShouldBeUndefinedNumberSchema } from '@local/src/utils/Yup/YupHelper'
import { number as yupNumber, object as yupObject, boolean as yupBoolean, SchemaOf } from 'yup'

export interface KalenderhalvarSchemaProps {
    isStudieersattningRequired: boolean
    isStudielonRequired: boolean
}
export const KalenderhalvarDraftSchema = (
    { isStudieersattningRequired, isStudielonRequired }: KalenderhalvarSchemaProps,
    formValidationTexts?: FormValidationTexts
): SchemaOf<KalenderhalvarApiModel> =>
    yupObject().shape({
        hasNoStudielon: yupBoolean().nullable(),
        studieersattning: yupNumber().when({
            is: () => isStudieersattningRequired,
            then: yupNumber().nullable().integer(formValidationTexts?.heltal).max(999999, maxLengthValidationText).min(0),
            otherwise: ShouldBeUndefinedNumberSchema,
        }),
        studielon: yupNumber().when('hasNoStudielon', {
            is: (hasNoStudielon: boolean) => isStudielonRequired && hasNoStudielon === false,
            then: yupNumber().integer(formValidationTexts?.heltal).max(999999, maxLengthValidationText).min(0).nullable(),
            otherwise: ShouldBeUndefinedNumberSchema,
        }),
    })

export const KalenderhalvarSchema = (
    { isStudieersattningRequired, isStudielonRequired }: KalenderhalvarSchemaProps,
    formValidationTexts?: FormValidationTexts
): SchemaOf<KalenderhalvarApiModel> =>
    KalenderhalvarDraftSchema({ isStudieersattningRequired, isStudielonRequired }, formValidationTexts).concat(
        yupObject().shape({
            studieersattning: yupNumber().when({
                is: () => isStudieersattningRequired,
                then: yupNumber()
                    .nullable()
                    .integer(formValidationTexts?.heltal)
                    .required(formValidationTexts?.studieersattning)
                    .max(999999, maxLengthValidationText)
                    .min(0),
                otherwise: ShouldBeUndefinedNumberSchema,
            }),
            studielon: yupNumber().when('hasNoStudielon', {
                is: (hasNoStudielon: boolean) => isStudielonRequired && hasNoStudielon === false,
                then: yupNumber()
                    .required(formValidationTexts?.studielon)
                    .integer(formValidationTexts?.heltal)
                    .max(999999, maxLengthValidationText)
                    .min(0),
                otherwise: ShouldBeUndefinedNumberSchema,
            }),
        })
    )
