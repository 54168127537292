/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    CreateAdHocYttrandeApiModel,
    CsnAdHocYttrandeApiModel,
    CsnAdHocYttrandeCreateParams,
    CsnAdHocYttrandeUpdateParams,
    TrrProblemDetails,
    TrrValidationProblemDetails,
    UpdateAdHocYttrandeApiModel,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class CsnAdHocYttrande<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags CsnAdHocYttrande
     * @name CsnAdHocYttrandeDetail
     * @request GET:/studiestod/csn/ad-hoc-yttrande/{adHocYttrandeId}
     * @secure
     * @response `200` `CsnAdHocYttrandeApiModel` OK
     * @response `400` `TrrValidationProblemDetails` Bad Request
     * @response `500` `TrrProblemDetails` Internal Server Error
     */
    csnAdHocYttrandeDetail = (adHocYttrandeId: string, params: RequestParams = {}) =>
        this.request<CsnAdHocYttrandeApiModel, TrrValidationProblemDetails | TrrProblemDetails>({
            path: `/studiestod/csn/ad-hoc-yttrande/${adHocYttrandeId}`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        })
    /**
     * No description
     *
     * @tags CsnAdHocYttrande
     * @name CsnAdHocYttrandeUpdate
     * @request PUT:/studiestod/csn/ad-hoc-yttrande/{adHocYttrandeId}
     * @secure
     * @response `202` `void` Accepted
     * @response `400` `TrrValidationProblemDetails` Bad Request
     * @response `500` `TrrProblemDetails` Internal Server Error
     */
    csnAdHocYttrandeUpdate = (
        { adHocYttrandeId, ...query }: CsnAdHocYttrandeUpdateParams,
        data: UpdateAdHocYttrandeApiModel,
        params: RequestParams = {}
    ) =>
        this.request<void, TrrValidationProblemDetails | TrrProblemDetails>({
            path: `/studiestod/csn/ad-hoc-yttrande/${adHocYttrandeId}`,
            method: 'PUT',
            query: query,
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        })
    /**
     * No description
     *
     * @tags CsnAdHocYttrande
     * @name CsnAdHocYttrandeDelete
     * @request DELETE:/studiestod/csn/ad-hoc-yttrande/{adHocYttrandeId}
     * @secure
     * @response `204` `void` No Content
     * @response `500` `TrrProblemDetails` Internal Server Error
     */
    csnAdHocYttrandeDelete = (adHocYttrandeId: string, params: RequestParams = {}) =>
        this.request<void, TrrProblemDetails>({
            path: `/studiestod/csn/ad-hoc-yttrande/${adHocYttrandeId}`,
            method: 'DELETE',
            secure: true,
            ...params,
        })
    /**
     * No description
     *
     * @tags CsnAdHocYttrande
     * @name CsnAdHocYttrandeCreate
     * @request POST:/studiestod/csn/ad-hoc-yttrande
     * @secure
     * @response `202` `void` Accepted
     * @response `400` `TrrValidationProblemDetails` Bad Request
     * @response `500` `TrrProblemDetails` Internal Server Error
     */
    csnAdHocYttrandeCreate = (query: CsnAdHocYttrandeCreateParams, data: CreateAdHocYttrandeApiModel, params: RequestParams = {}) =>
        this.request<void, TrrValidationProblemDetails | TrrProblemDetails>({
            path: `/studiestod/csn/ad-hoc-yttrande`,
            method: 'POST',
            query: query,
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        })
    /**
     * No description
     *
     * @tags CsnAdHocYttrande
     * @name CsnAnsokanCsnArendeAdHocYttrandeDetail
     * @request GET:/studiestod/csn/ansokan/{ansokanId}/csn-arende/ad-hoc-yttrande
     * @secure
     * @response `200` `(CsnAdHocYttrandeApiModel)[]` OK
     * @response `500` `TrrProblemDetails` Internal Server Error
     */
    csnAnsokanCsnArendeAdHocYttrandeDetail = (ansokanId: string, params: RequestParams = {}) =>
        this.request<CsnAdHocYttrandeApiModel[], TrrProblemDetails>({
            path: `/studiestod/csn/ansokan/${ansokanId}/csn-arende/ad-hoc-yttrande`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        })
}
