import { ApiResponseModel } from '@local/services/ApiResponseModel'
import SubmitResponseAlert from '@local/src/features/StudiestodAnsokan/Components/SubmitResponse/SubmitResponseAlert'
import { Portal } from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { HTMLAttributes, useEffect, useState } from 'react'

interface Props {
    apiResponse: ApiResponseModel<unknown>
    successMessage: string
    hideApiResponseErrorAlert?: boolean
}

const SubmitResponse = ({ apiResponse, successMessage, hideApiResponseErrorAlert = false }: Props) => {
    const { enqueueSnackbar } = useSnackbar()
    const [showErrorAlert, setShowErrorAlert] = useState(false)

    const studierTabContainer = document.getElementById('medarbetare-studier-fe')
    const portalContainer = document.createElement('div')
    studierTabContainer?.insertBefore(portalContainer, studierTabContainer.firstChild)

    useEffect(() => {
        setShowErrorAlert(false)
        if (apiResponse !== undefined) {
            const isSuccess = apiResponse.isSuccess
            const hasError = apiResponse.hasError

            if (isSuccess) {
                enqueueSnackbar({
                    message: successMessage,
                    SnackbarProps: { 'data-testid': 'snackbar-success' } as HTMLAttributes<HTMLDivElement>,
                })
            }

            if (hasError && !hideApiResponseErrorAlert && studierTabContainer) {
                setShowErrorAlert(true)
            }
        }
    }, [apiResponse])

    useEffect(() => {
        if (showErrorAlert) {
            portalContainer.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
    }, [showErrorAlert])

    if (showErrorAlert) {
        return (
            <Portal container={portalContainer}>
                <SubmitResponseAlert apiResponse={apiResponse} onClose={setShowErrorAlert} />
            </Portal>
        )
    }

    return <></>
}

export default SubmitResponse
