import { StudiestodTypApiModel } from '@local/src/services/SwaggerApi/data-contracts'
import { requiredBooleanSchema, ShouldBeFalseBooleanSchema } from '@local/src/utils/Yup/YupHelper'
import { ArendeTyp } from '@local/src/@types/Arenden.types'
import { object as yupObject, SchemaOf, boolean as yupBoolean } from 'yup'

const StudiestodTypSchema = (arendeTyp: ArendeTyp, isLegacy: boolean): SchemaOf<StudiestodTypApiModel> =>
    yupObject({
        hasStudieersattning: requiredBooleanSchema().when({
            is: () => ArendeTypeIs(ArendeTyp.Anstallning, arendeTyp),
            then: ShouldBeFalseBooleanSchema,
        }),
        hasStudielon: requiredBooleanSchema().when({
            is: () => ArendeTypeIs(ArendeTyp.Anstallning, arendeTyp),
            then: ShouldBeFalseBooleanSchema,
        }),
        hasKompletterandeStudiestod: requiredBooleanSchema().when({
            is: () => isLegacy,
            then: ShouldBeFalseBooleanSchema,
        }),
        hasKoptUtbildning: requiredBooleanSchema().when({
            is: () => isLegacy,
            then: ShouldBeFalseBooleanSchema,
        }),
        hasKortvarigtStudiestod: requiredBooleanSchema().when({
            is: () => isLegacy,
            then: ShouldBeFalseBooleanSchema,
        }),
    }).required()

const ArendeTypeIs = (arendeTyp: ArendeTyp, shouldBeArendeTyp: ArendeTyp) => arendeTyp === shouldBeArendeTyp // OmstallningsArende = 1

export const HasKompletterandeStudiestodBooleanSchema = yupBoolean().isFalse('Får endast anges om Yttrande till CSN är satt till Ja')

export default StudiestodTypSchema
