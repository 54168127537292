import { PageWrapper } from '@local/src/components/PageWrapper'
import { BegaranCsnYttrandeListItemApiModel } from '@local/src/services/SwaggerApi/data-contracts'
import React from 'react'
import { Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { useGetCurrentArende } from '../StudiestodAnsokan/Hooks'

import { useGetBegardaYttranden } from './Hooks/useGetBegardaYttranden'
import BegartYttrandeListItemCard from './Components/BegartYttrandeListItemCard/BegartYttrandeListItemCard'

interface Props {
    arendeId: string
}

const BegardaYttrandenList = ({ arendeId }: Props) => {
    const { t } = useTranslation('translation', { keyPrefix: 'begardaYttrandenList' })

    const { arende } = useGetCurrentArende(arendeId)
    const { begaranYttranden, isError, isFetching } = useGetBegardaYttranden(arende?.klient?.personnummer, false)

    const hasBegartYttranden = !isFetching && begaranYttranden?.length > 0

    return (
        <PageWrapper isLoading={isFetching} errorLoadingData={isError} errorText={t('hamtningsfelText')} headerText={t('okoppladeHeader')}>
            <Stack spacing={2}>
                <Typography>{t('okoppladeDescription')}</Typography>
                {hasBegartYttranden ? (
                    begaranYttranden?.map((begartYttrande: BegaranCsnYttrandeListItemApiModel, index) => (
                        <BegartYttrandeListItemCard arendeId={arendeId} yttrande={begartYttrande} key={index} />
                    ))
                ) : (
                    <Typography data-testid="no-result-begaran-yttrande">{t('noResults')}</Typography>
                )}
            </Stack>
        </PageWrapper>
    )
}

export default BegardaYttrandenList
