import { StudiestodTypApiModel } from '@local/src/services/SwaggerApi/data-contracts'
import { object as yupObject, SchemaOf, boolean as yupBoolean } from 'yup'

const StudiestodTypDraftSchema = (): SchemaOf<StudiestodTypApiModel> =>
    yupObject({
        hasStudieersattning: yupBoolean().nullable(),
        hasStudielon: yupBoolean().nullable(),
        hasKompletterandeStudiestod: yupBoolean().nullable(),
        hasKoptUtbildning: yupBoolean().nullable(),
        hasKortvarigtStudiestod: yupBoolean().nullable(),
    }).required()

export default StudiestodTypDraftSchema
