/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    ArendeAnsokanDetailParams1,
    ArendeAnsokanRadgivarunderlagPartialUpdateParams,
    ArendeAnsokanRadgivarunderlagV2PartialUpdateParams,
    DomainEventLogApiModel,
    StudieansokanMinimalInfoApiModel,
    StudieintygListApiModel,
    StudiestodListApiModel,
    StudiestodUnlockState,
    TrrProblemDetails,
    UpdateEjAktuellApiModel,
    UpdateUnderlagApiModel,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class StudiestodMedarbetare<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags StudiestodMedarbetare
     * @name ArendeAnsokanDetail
     * @request GET:/studiestod/arende/{arendeId}/ansokan
     * @secure
     * @response `200` `(StudiestodListApiModel)[]` OK
     * @response `500` `TrrProblemDetails` Internal Server Error
     */
    arendeAnsokanDetail = ({ arendeId, ...query }: ArendeAnsokanDetailParams1, params: RequestParams = {}) =>
        this.request<StudiestodListApiModel[], TrrProblemDetails>({
            path: `/studiestod/arende/${arendeId}/ansokan`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        })
    /**
     * No description
     *
     * @tags StudiestodMedarbetare
     * @name SimpleDetail
     * @request GET:/studiestod/{ansokanId}/simple
     * @secure
     * @response `200` `StudieansokanMinimalInfoApiModel` OK
     * @response `500` `TrrProblemDetails` Internal Server Error
     */
    simpleDetail = (ansokanId: string, params: RequestParams = {}) =>
        this.request<StudieansokanMinimalInfoApiModel, TrrProblemDetails>({
            path: `/studiestod/${ansokanId}/simple`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        })
    /**
     * No description
     *
     * @tags StudiestodMedarbetare
     * @name ArendeAnsokanEventLogDetail
     * @request GET:/studiestod/arende/{arendeId}/ansokan/{studiestodId}/event-log
     * @secure
     * @response `200` `(DomainEventLogApiModel)[]` OK
     * @response `500` `TrrProblemDetails` Internal Server Error
     */
    arendeAnsokanEventLogDetail = (studiestodId: string, arendeId: string, params: RequestParams = {}) =>
        this.request<DomainEventLogApiModel[], TrrProblemDetails>({
            path: `/studiestod/arende/${arendeId}/ansokan/${studiestodId}/event-log`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        })
    /**
     * No description
     *
     * @tags StudiestodMedarbetare
     * @name ArendeAnsokanHandlaggPartialUpdate
     * @request PATCH:/studiestod/arende/{arendeId}/ansokan/{ansokanId}/handlagg
     * @secure
     * @response `204` `void` No Content
     * @response `500` `TrrProblemDetails` Internal Server Error
     */
    arendeAnsokanHandlaggPartialUpdate = (arendeId: string, ansokanId: string, params: RequestParams = {}) =>
        this.request<void, TrrProblemDetails>({
            path: `/studiestod/arende/${arendeId}/ansokan/${ansokanId}/handlagg`,
            method: 'PATCH',
            secure: true,
            ...params,
        })
    /**
     * No description
     *
     * @tags StudiestodMedarbetare
     * @name ArendeAnsokanRadgivarunderlagV2PartialUpdate
     * @request PATCH:/studiestod/arende/{arendeId}/ansokan/{ansokanId}/radgivarunderlagV2
     * @secure
     * @response `204` `void` No Content
     * @response `500` `TrrProblemDetails` Internal Server Error
     */
    arendeAnsokanRadgivarunderlagV2PartialUpdate = (
        { arendeId, ansokanId, ...query }: ArendeAnsokanRadgivarunderlagV2PartialUpdateParams,
        data: UpdateUnderlagApiModel,
        params: RequestParams = {}
    ) =>
        this.request<void, TrrProblemDetails>({
            path: `/studiestod/arende/${arendeId}/ansokan/${ansokanId}/radgivarunderlagV2`,
            method: 'PATCH',
            query: query,
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        })
    /**
     * No description
     *
     * @tags StudiestodMedarbetare
     * @name ArendeAnsokanRadgivarunderlagPartialUpdate
     * @request PATCH:/studiestod/arende/{arendeId}/ansokan/{ansokanId}/radgivarunderlag
     * @secure
     * @response `204` `void` No Content
     * @response `500` `TrrProblemDetails` Internal Server Error
     */
    arendeAnsokanRadgivarunderlagPartialUpdate = (
        { arendeId, ansokanId, ...query }: ArendeAnsokanRadgivarunderlagPartialUpdateParams,
        data: UpdateUnderlagApiModel,
        params: RequestParams = {}
    ) =>
        this.request<void, TrrProblemDetails>({
            path: `/studiestod/arende/${arendeId}/ansokan/${ansokanId}/radgivarunderlag`,
            method: 'PATCH',
            query: query,
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        })
    /**
     * No description
     *
     * @tags StudiestodMedarbetare
     * @name ArendeAnsokanBeslutPartialUpdate
     * @request PATCH:/studiestod/arende/{arendeId}/ansokan/{ansokanId}/beslut
     * @secure
     * @response `204` `void` No Content
     * @response `500` `TrrProblemDetails` Internal Server Error
     */
    arendeAnsokanBeslutPartialUpdate = (arendeId: string, ansokanId: string, params: RequestParams = {}) =>
        this.request<void, TrrProblemDetails>({
            path: `/studiestod/arende/${arendeId}/ansokan/${ansokanId}/beslut`,
            method: 'PATCH',
            secure: true,
            ...params,
        })
    /**
     * No description
     *
     * @tags StudiestodMedarbetare
     * @name ArendeAnsokanLasPartialUpdate
     * @request PATCH:/studiestod/arende/{arendeId}/ansokan/{ansokanId}/las
     * @secure
     * @response `204` `void` No Content
     * @response `500` `TrrProblemDetails` Internal Server Error
     */
    arendeAnsokanLasPartialUpdate = (arendeId: string, ansokanId: string, params: RequestParams = {}) =>
        this.request<void, TrrProblemDetails>({
            path: `/studiestod/arende/${arendeId}/ansokan/${ansokanId}/las`,
            method: 'PATCH',
            secure: true,
            ...params,
        })
    /**
     * No description
     *
     * @tags StudiestodMedarbetare
     * @name ArendeAnsokanUnlockPartialUpdate
     * @request PATCH:/studiestod/arende/{arendeId}/ansokan/{ansokanId}/unlock/{state}
     * @secure
     * @response `204` `void` No Content
     * @response `500` `TrrProblemDetails` Internal Server Error
     */
    arendeAnsokanUnlockPartialUpdate = (arendeId: string, ansokanId: string, state: StudiestodUnlockState, params: RequestParams = {}) =>
        this.request<void, TrrProblemDetails>({
            path: `/studiestod/arende/${arendeId}/ansokan/${ansokanId}/unlock/${state}`,
            method: 'PATCH',
            secure: true,
            ...params,
        })
    /**
     * No description
     *
     * @tags StudiestodMedarbetare
     * @name ArendeAnsokanEjAktuellPartialUpdate
     * @request PATCH:/studiestod/arende/{arendeId}/ansokan/{id}/ej-aktuell
     * @secure
     * @response `204` `void` No Content
     * @response `500` `TrrProblemDetails` Internal Server Error
     */
    arendeAnsokanEjAktuellPartialUpdate = (arendeId: string, id: string, data: UpdateEjAktuellApiModel, params: RequestParams = {}) =>
        this.request<void, TrrProblemDetails>({
            path: `/studiestod/arende/${arendeId}/ansokan/${id}/ej-aktuell`,
            method: 'PATCH',
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        })
    /**
     * No description
     *
     * @tags StudiestodMedarbetare
     * @name ArendeDocumentsDetail
     * @request GET:/studiestod/arende/{arendeId}/documents
     * @secure
     * @response `200` `(StudieintygListApiModel)[]` OK
     * @response `500` `TrrProblemDetails` Internal Server Error
     */
    arendeDocumentsDetail = (arendeId: string, params: RequestParams = {}) =>
        this.request<StudieintygListApiModel[], TrrProblemDetails>({
            path: `/studiestod/arende/${arendeId}/documents`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        })
    /**
     * No description
     *
     * @tags StudiestodMedarbetare
     * @name ArendeDocumentDetail
     * @request GET:/studiestod/arende/{arendeId}/document/{documentId}
     * @secure
     * @response `200` `void` OK
     * @response `500` `TrrProblemDetails` Internal Server Error
     */
    arendeDocumentDetail = (arendeId: string, documentId: string, params: RequestParams = {}) =>
        this.request<void, TrrProblemDetails>({
            path: `/studiestod/arende/${arendeId}/document/${documentId}`,
            method: 'GET',
            secure: true,
            ...params,
        })
}
