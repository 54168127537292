export enum StudiestodStepEnum {
    ANSOKAN = 'ansokan',
    UNDERLAG = 'underlag',
    BESLUT = 'beslut',
    CSN = 'csn',
    STUDIEINTYG = 'studieintyg',
}

export type StudiestodStepType =
    | StudiestodStepEnum.ANSOKAN
    | StudiestodStepEnum.UNDERLAG
    | StudiestodStepEnum.BESLUT
    | StudiestodStepEnum.CSN
    | StudiestodStepEnum.STUDIEINTYG
