import { useEffect, useState } from 'react'
import { studiestodMedarbetareApi } from '@local/src/services/apiEndpointHelper'
import { apiRequestTryCatchWrapper } from '@local/src/services/apiRequestHelper'

import useGetCurrentStudiestodansokan from './useGetCurrentStudiestodansokan'

const useLockStudiestodansokan = () => {
    const [isPosting, setIsPosting] = useState(false)
    const [apiResponse, setApiResponse] = useState(undefined)
    const { studiestodAnsokan, invalidateQuery } = useGetCurrentStudiestodansokan()

    useEffect(() => {
        setApiResponse(undefined)
    }, [studiestodAnsokan.isRedigerbar])

    const handleLockStudieansokan = async () => {
        setIsPosting(true)
        const resp = await apiRequestTryCatchWrapper(
            'useLockStudiestodansokan',
            studiestodMedarbetareApi.arendeAnsokanLasPartialUpdate,
            studiestodAnsokan.arendeId,
            studiestodAnsokan.id
        )
        setApiResponse(resp)
        if (resp.isSuccess) {
            await invalidateQuery()
        }
        setIsPosting(false)
    }

    return { isPosting, apiResponse, handleLockStudieansokan }
}

export default useLockStudiestodansokan
