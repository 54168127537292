import React from 'react'
import { Divider, Stack, Typography } from '@mui/material'
import { StudiestodUnlockState } from '@local/src/services/SwaggerApi/data-contracts'
import { useTranslation } from 'react-i18next'

import { useGetCurrentStudiestodansokan, useHandlaggAnsokanStudiestod } from '../../../Hooks'
import NyaAvtaletHighlightBox from '../../NyaAvtaletHighlightBox/NyaAvtaletHighlightBox'
import Utbildning from '../../Utbildning/Utbildning'
import StudiestodUnlockForEditingActionBox from '../../StudiestodUnlockForEditingActionBox'

import AnsokanOmStudiestodElements from './Components/AnsokanOmStudiestodElements'
import Klarmarkera from './Components/Klarmarkera'

const AnsokanOmStudiestod = () => {
    const { studiestodAnsokan, isEjAktuell } = useGetCurrentStudiestodansokan()
    useHandlaggAnsokanStudiestod()

    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokan.steps.ansokanOmStudiestod' })

    const showUnlockForEditingButton = !isEjAktuell && !studiestodAnsokan?.isRedigerbar

    const { Villkor, SyfteMedStudier, Nyanstallning, Arbetsmarknad, KompletteringErfarenhet, Ovrigt, StudiestodFranTrr, InformationOmCsn } =
        AnsokanOmStudiestodElements(studiestodAnsokan)

    return (
        <Stack spacing={2} data-testid="form-steppers-section-ansokan">
            <NyaAvtaletHighlightBox
                isVisible={studiestodAnsokan?.showOnlyStudiestodsTypStudieersattningAndStudielon}
                title={t('preNyaAvtaletHighlightBoxHeader')}
                content={t('preNyaAvtaletHighlightBoxText')}
            />
            {showUnlockForEditingButton && <StudiestodUnlockForEditingActionBox unlockStep={StudiestodUnlockState.Ansokan} />}
            <Typography variant="h4">{t('headerText')}</Typography>
            <Typography>{t('leadText')}</Typography>
            <Divider />
            <Typography variant="h5">{t('motiveringText')}</Typography>
            <SyfteMedStudier />
            <Nyanstallning />
            <Arbetsmarknad />
            <KompletteringErfarenhet />
            <Ovrigt />
            {studiestodAnsokan?.isLegacyUnderlag && <StudiestodFranTrr />}
            {studiestodAnsokan?.isLegacyUnderlag && <InformationOmCsn />}
            <Divider />
            <Typography variant="h5">{t('utbildningarText')}</Typography>
            {studiestodAnsokan?.utbildningar.map((ut, index) => <Utbildning key={ut.id} utbildning={ut} index={index} />)}
            <Divider />
            <Villkor />
            <Divider />
            {!isEjAktuell && <Klarmarkera />}
            {showUnlockForEditingButton && <StudiestodUnlockForEditingActionBox unlockStep={StudiestodUnlockState.Ansokan} />}
        </Stack>
    )
}
export default AnsokanOmStudiestod
