/* eslint-disable @typescript-eslint/no-misused-promises */
import React from 'react'
import { Button, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { useTaBeslutForStudiestodAnsokan } from '../../Hooks/useTaBeslutForStudiestodAnsokan'
import SubmitResponse from '../../../../SubmitResponse/SubmitResponse'

const TaBeslut = () => {
    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokan.steps.beslut' })

    const { handleTaBeslut, isPosting, beslutResponse } = useTaBeslutForStudiestodAnsokan()

    return (
        <Stack spacing={2}>
            <Typography data-testid="description">{t('descriptionText')}</Typography>
            <Button
                data-testid="ta-beslut-normalfall"
                onClick={handleTaBeslut}
                variant="contained"
                disabled={isPosting}
                sx={{ width: 'fit-content' }}
            >
                {t('godkannAnsokanButtonText')}
            </Button>
            <SubmitResponse apiResponse={beslutResponse} successMessage={t('studiestodsansokanGodkandSnackbarSuccessText')} />
        </Stack>
    )
}

export default TaBeslut
