import AddCircleIcon from '@mui/icons-material/AddCircle'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import AutoStoriesIcon from '@mui/icons-material/AutoStories'
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import CheckIcon from '@mui/icons-material/Check'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import DeleteIcon from '@mui/icons-material/Delete'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import ErrorIcon from '@mui/icons-material/Error'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import InfoIcon from '@mui/icons-material/Info'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded'
import WarningRoundedIcon from '@mui/icons-material/WarningRounded'
import WatchLaterIcon from '@mui/icons-material/WatchLater'
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined'
import LightbulbIcon from '@mui/icons-material/Lightbulb'
import React from 'react'

interface IconProps {
    size?: 'inherit' | 'large' | 'medium' | 'small'
    outlined?: boolean
    color?: 'inherit' | 'action' | 'disabled' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'
    title?: string
}

const Error = ({ size = 'medium', outlined = false }: IconProps) =>
    outlined ? <ErrorOutlineIcon color="error" fontSize={size} /> : <ErrorIcon color="error" fontSize={size} />

const Warning = ({ size = 'medium', outlined = false }: IconProps) =>
    outlined ? <WarningAmberRoundedIcon color="error" fontSize={size} /> : <WarningRoundedIcon color="warning" fontSize={size} />

const Info = ({ size = 'medium', outlined = false, color }: IconProps) =>
    outlined ? <InfoOutlinedIcon color="error" fontSize={size} /> : <InfoIcon color={color ? color : 'info'} fontSize={size} />

const Check = ({ size = 'medium', outlined = false, color, title }: IconProps) =>
    outlined ? (
        <CheckIcon color="error" fontSize={size} titleAccess={title} />
    ) : (
        <CheckIcon color={color ? color : 'success'} fontSize={size} titleAccess={title} />
    )

const Success = ({ size = 'medium', outlined = false }: IconProps) =>
    outlined ? <CheckCircleOutlineIcon color="success" fontSize={size} /> : <CheckCircleIcon color="success" fontSize={size} />

const Add = ({ size = 'medium', outlined = false }: IconProps) =>
    outlined ? <AddCircleOutlineOutlinedIcon color="success" fontSize={size} /> : <AddCircleIcon color="primary" fontSize={size} />

const Delete = ({ size = 'medium', outlined = false, color = 'primary' }: IconProps) =>
    outlined ? <DeleteOutlineIcon color={color} fontSize={size} /> : <DeleteIcon color={color} fontSize={size} />

const Calendar = ({ size = 'medium', outlined = false, color }: IconProps) =>
    outlined ? (
        <CalendarMonthOutlinedIcon color={color ? color : 'primary'} fontSize={size} />
    ) : (
        <CalendarMonthIcon color={color ? color : 'primary'} fontSize={size} />
    )

const Location = ({ size = 'medium', outlined = false, color }: IconProps) =>
    outlined ? (
        <LocationOnOutlinedIcon color={color ? color : 'primary'} fontSize={size} />
    ) : (
        <LocationOnIcon color={color ? color : 'primary'} fontSize={size} />
    )

const Book = ({ size = 'medium', outlined = false, color }: IconProps) =>
    outlined ? (
        <AutoStoriesOutlinedIcon color={color ? color : 'primary'} fontSize={size} />
    ) : (
        <AutoStoriesIcon color={color ? color : 'primary'} fontSize={size} />
    )

const Clock = ({ size = 'medium', outlined = false, color }: IconProps) =>
    outlined ? (
        <WatchLaterOutlinedIcon color={color ? color : 'primary'} fontSize={size} />
    ) : (
        <WatchLaterIcon color={color ? color : 'primary'} fontSize={size} />
    )

const Lightbulb = ({ size = 'medium', color }: IconProps) => <LightbulbIcon color={color ? color : 'primary'} fontSize={size} />

export default {
    Error,
    Warning,
    Info,
    Check,
    Success,
    Add,
    Delete,
    Calendar,
    Location,
    Book,
    Clock,
    Lightbulb,
}
