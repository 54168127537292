/* eslint-disable @typescript-eslint/no-unused-vars */
import { TrrProblemDetails, TrrValidationErrorField, TrrValidationProblemDetails } from '@local/src/services/SwaggerApi/data-contracts'
import { HttpResponse } from '@local/src/services/SwaggerApi/http-client'
import { getBaseRequestParams } from '@local/src/services/apiEndpointHelper'
import { log_error, mapArgsToObject } from '@local/src/utils/helpers/logger'

import { ApiResponseModel } from './ApiResponseModel'

type ApiRequest = (...params: unknown[]) => Promise<HttpResponse<unknown, unknown>>

type ApiResponse<T> = T extends (...params: unknown[]) => Promise<HttpResponse<infer TResponse, infer TError>>
    ? ApiResponseModel<TResponse>
    : never

type UnwrapResponse<T> = T extends (...params: unknown[]) => Promise<HttpResponse<infer TResponse, infer TError>> ? TResponse : never

export const apiRequestTryCatchWrapper = async <T extends ApiRequest>(
    requestName: string,
    request: T,
    ...requestParams: Parameters<T>
): Promise<ApiResponse<T>> => {
    try {
        const apiResponse = await request(...requestParams, getBaseRequestParams())

        return {
            isSuccess: apiResponse.ok,
            hasError: !apiResponse.ok,
            response: apiResponse.ok ? apiResponse.data : null,
            payload: mapArgsToObject(requestParams),
        } as ApiResponse<T>
    } catch (error) {
        const baseErrorResponse = {
            hasError: true,
            isSuccess: false,
            payload: mapArgsToObject(requestParams),
            error: {
                status: 500,
                errorMessage: 'Something went wrong',
                errorType: 'Generic Error',
            },
        }
        const asApiError = error as HttpResponse<unknown, TrrValidationProblemDetails | TrrProblemDetails>
        if (!asApiError) {
            log_error(`apiRequestTryCatchWrapper - not api error - ${requestName}`, { error, requestParams: baseErrorResponse.payload })
            return baseErrorResponse as ApiResponse<T>
        }

        const errorModel = (asApiError.bodyUsed ? asApiError.error : await asApiError.json()) as TrrValidationProblemDetails
        const hasFluentValidationErrors = (errorModel?.validationErrors ?? []).length > 0
        const validationErrors = hasFluentValidationErrors ? errorModel?.validationErrors : mapToTrrValidationErrorField(errorModel?.errors)
        const errorResponse = {
            ...baseErrorResponse,
            error: {
                status: asApiError.status,
                errorMessage: errorModel?.title,
                errorType: hasFluentValidationErrors ? errorModel?.errorType : errorModel?.status,
                validationErrors: validationErrors,
            },
        }
        log_error(`apiRequestTryCatchWrapper - ${requestName}`, {
            errorResponse,
            requestParams: baseErrorResponse.payload,
            url: asApiError.url,
        })
        return errorResponse as ApiResponse<T>
    }
}

export const apiGetTryCatchWrapper = async <T extends ApiRequest>(
    requestName: string,
    request: T,
    ...requestParams: Parameters<T>
): Promise<UnwrapResponse<T>> => {
    try {
        const res = await request(...requestParams, getBaseRequestParams())
        return res.data as UnwrapResponse<T>
    } catch (error) {
        log_error(`apiGetTryCatchWrapper - ${requestName}`, { error, requestParams })
        throw error
    }
}

export const mapToTrrValidationErrorField = (errors: Record<string, string[]>) => {
    let validationErrors: TrrValidationErrorField[] = []
    if (!errors) {
        return validationErrors
    }
    Object.entries(errors).forEach((item) => {
        const propertiesErrors: TrrValidationErrorField[] = item[1].map((value) => ({
            propertyName: item[0],
            errorMessage: value,
        }))
        validationErrors = [...validationErrors, ...propertiesErrors]
    })

    return validationErrors
}
