import { StudiestodListApiModel } from '@local/src/services/SwaggerApi/data-contracts'
import React from 'react'
import { Alert, AlertTitle, Card, CardActionArea, CardContent, CardHeader, Stack, Typography } from '@mui/material'
import StudiestodStatusChip from '@local/src/components/StudiestodStatusChip/StudiestodStatusChip'
import { formatToDate, formatToDateTime } from '@local/src/utils/helpers/dateTimeFormatHelper'
import UtbildningListItem from '@local/src/features/StudiestodAnsokanList/Components/UtbildningListItem/UtbildningListItem'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import { useNavigateToPage } from '@local/src/hooks'
import { useTranslation } from 'react-i18next'
import useCommonTranslation from '@local/src/hooks/useCommonTranslation'
import { getStudiestodTitle } from '@local/src/utils/helpers/studiestodAnsokanHelper'

interface Props {
    ansokan: StudiestodListApiModel
    isEjAktuell?: boolean
}

const AnsokanItemCard = ({ ansokan, isEjAktuell = false }: Props) => {
    const { senastUppdateradDatum, publiceringsdatum, utbildningar, id } = ansokan
    const { navigateToStudieansokan } = useNavigateToPage()

    const gotoStudiestod = () => navigateToStudieansokan(id)

    const cardTitle = getStudiestodTitle(ansokan)
    const isEjAktuellString = isEjAktuell ? 'ejAktuell' : 'aktuell'

    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokanList.ansokanItemCard' })
    const { tCommon } = useCommonTranslation()

    return (
        <Card data-testid="studiestodansokan-link">
            <CardActionArea onClick={gotoStudiestod}>
                <CardHeader
                    sx={{ flexDirection: { xs: 'column', sm: 'row' }, gap: 1 }}
                    data-testid={`studiestod-list-item-header`}
                    title={cardTitle}
                    titleTypographyProps={{ sx: { wordBreak: 'break-word' } }}
                    action={<StudiestodStatusChip status={ansokan.status} />}
                />
                <CardContent data-testid={`studiestod-list-item-${isEjAktuellString}`}>
                    <Stack spacing={2}>
                        <Stack spacing={1} direction="row">
                            <CalendarMonthOutlinedIcon fontSize={'small'} />
                            <Typography>{tCommon('timestamp_publicerad')}</Typography>
                            <Typography fontWeight={(theme) => theme.typography.fontWeightBold}>
                                {formatToDateTime(publiceringsdatum)}
                            </Typography>
                        </Stack>
                        <Stack spacing={1} direction="row">
                            <CalendarMonthOutlinedIcon fontSize={'small'} />
                            <Typography>{tCommon('timestamp_changed')}</Typography>
                            <Typography fontWeight={(theme) => theme.typography.fontWeightBold}>
                                {formatToDate(senastUppdateradDatum)}
                            </Typography>
                        </Stack>
                        {isEjAktuell && (
                            <Alert data-testid="studiestod-list-item-ej-aktuell-alert" severity="warning">
                                <AlertTitle>{t('ejAktuellAlertTitle')}</AlertTitle>
                                {ansokan?.ejAktuell?.orsakMedarbetare}
                            </Alert>
                        )}
                        {utbildningar.map((utbildning) => (
                            <UtbildningListItem key={utbildning.id} utbildning={utbildning} ansokan={ansokan} />
                        ))}
                    </Stack>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}
export default AnsokanItemCard
