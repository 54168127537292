import { useEffect } from 'react'

import { useRadgivarensUnderlagFormContext } from '../Components/Steps/RadgivarensUnderlag/Hooks'

const useSetStudieersattningAndStudielon = (setInitialValues: boolean, utbildningsIndex: number) => {
    const { setValue } = useRadgivarensUnderlagFormContext()
    useEffect(() => {
        if (setInitialValues) {
            setValue(`utbildningar.${utbildningsIndex}.studieersattningAndStudielon`, {
                beraknadUtbetalningsstart: { year: undefined, termin: undefined },
                kalenderhalvar: [{ hasNoStudielon: false, studieersattning: undefined, studielon: undefined }],
                motiveringStudielon: {
                    anledning: undefined,
                    beskrivning: undefined,
                },
            })
        }
    }, [setValue, setInitialValues, utbildningsIndex])
}

export default useSetStudieersattningAndStudielon
