import React from 'react'
import { Alert, AlertTitle, Stack, Typography } from '@mui/material'
import { ExternalLink } from '@local/src/components/ExternalLink'

interface Props {
    title: string
    content: string
    link?: string
    linkText?: string
    isVisible: boolean
}
const NyaAvtaletHighlightBox = ({ title, content, link, linkText, isVisible }: Props) => {
    const hasLink = Boolean(link)
    return isVisible ? (
        <Alert sx={{ p: 2 }} data-testid="nya-avtalet-highlight-box" icon={false} severity="warning">
            <AlertTitle>
                <Typography variant="h4">{title}</Typography>
            </AlertTitle>
            <Stack spacing={2}>
                <Typography variant="body2">{content}</Typography>
                {hasLink && <ExternalLink href={link} linkText={linkText} />}
            </Stack>
        </Alert>
    ) : null
}

export default NyaAvtaletHighlightBox
