import { useQuery } from '@tanstack/react-query'
import { csnAdHocYttrandeApi } from '@local/src/services/apiEndpointHelper'
import { apiGetTryCatchWrapper } from '@local/src/services/apiRequestHelper'
import { isNotNil } from 'ramda'

const useGetAdHocYttrandenByAnsokanId = (ansokanId: string) => {
    const { data: adHocYttranden, ...query } = useQuery({
        queryKey: ['getAdHocYttranden', ansokanId],
        queryFn: () =>
            apiGetTryCatchWrapper('useGetAdHocYttrandenByAnsokanId', csnAdHocYttrandeApi.csnAnsokanCsnArendeAdHocYttrandeDetail, ansokanId),
        placeholderData: [],
        enabled: isNotNil(ansokanId),
    })

    return { adHocYttranden, ...query }
}
export default useGetAdHocYttrandenByAnsokanId
