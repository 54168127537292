import React from 'react'
import { Box, Typography, Stack, Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import { TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from '@mui/lab'
import { formatToDate, formatToTime } from '@local/src/utils/helpers/dateTimeFormatHelper'
import {
    getChanges,
    getEventText,
    getNameFromEvent,
    getTimelineDotColor,
} from '@local/src/features/StudiestodAnsokan/Components/DomainEventsLog/Utils/domainEventHelper'
import { EventName } from '@local/src/features/StudiestodAnsokan/Components/DomainEventsLog/Utils/domainEventTypes'
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent'
import { DomainEventLogApiModel } from '@local/src/services/SwaggerApi/data-contracts'
import DomainEventDiffItem from '@local/src/features/StudiestodAnsokan/Components/DomainEventsLog/DomainEventDiffItem'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

interface Props {
    domainEvent: DomainEventLogApiModel
    prevEvents: DomainEventLogApiModel[]
}

const DomainEventTimeLineItem = ({ domainEvent, prevEvents }: Props) => {
    const changes = getChanges(domainEvent, prevEvents)
    const hasChanges = changes.length > 0

    return (
        <TimelineItem>
            <TimelineOppositeContent sx={{ px: 1 }} align="right">
                <Stack direction="column" justifyContent="space-between">
                    <Typography minWidth="10ch" variant="subtitle1">
                        {formatToDate(domainEvent.time)}
                    </Typography>
                    <Typography minWidth="10ch" variant="body2" color="text.secondary">
                        {formatToTime(domainEvent.time)}
                    </Typography>
                </Stack>
            </TimelineOppositeContent>
            <TimelineSeparator>
                <TimelineDot color={getTimelineDotColor(domainEvent)} />
                <TimelineConnector />
            </TimelineSeparator>
            {hasChanges ? (
                <TimelineContent sx={{ px: 1 }} align="right">
                    <Accordion elevation={0}>
                        <AccordionSummary
                            sx={{
                                padding: 0,
                                minHeight: '48px',
                                '&.Mui-expanded': {
                                    minHeight: '48px',
                                },
                                '& .MuiAccordionSummary-content': {
                                    margin: 0,
                                },
                            }}
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Box>
                                <Typography variant="subtitle1">{getEventText(domainEvent)}</Typography>
                                {domainEvent.name !== 'Systemet' && (
                                    <Typography variant="body2" color="text.secondary">
                                        {`Av ${getNameFromEvent(domainEvent.subject as EventName, domainEvent.name)}`}
                                    </Typography>
                                )}
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails sx={{ borderRadius: 1, backgroundColor: 'ghostwhite' }}>
                            <DomainEventDiffItem changes={changes} domainEvent={domainEvent} />
                        </AccordionDetails>
                    </Accordion>
                </TimelineContent>
            ) : (
                <TimelineContent align="right">
                    <Box>
                        <Typography variant="subtitle1">{getEventText(domainEvent)}</Typography>
                        {domainEvent.name !== 'Systemet' && (
                            <Typography variant="body2" color="text.secondary">
                                {`Av ${getNameFromEvent(domainEvent.subject as EventName, domainEvent.name)}`}
                            </Typography>
                        )}
                    </Box>
                </TimelineContent>
            )}
        </TimelineItem>
    )
}

export default DomainEventTimeLineItem
