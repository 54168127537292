import { OmstallningsinsatsTypApiModel } from '@local/src/services/SwaggerApi/data-contracts'
import { ShouldBeFalseBooleanSchema } from '@local/src/utils/Yup/YupHelper'
import { ArendeTyp } from '@local/src/@types/Arenden.types'
import { object as yupObject, SchemaOf, boolean as yupBoolean } from 'yup'

export const omstallningsinsatsTypSchema = (arendeTyp?: ArendeTyp): SchemaOf<OmstallningsinsatsTypApiModel> =>
    yupObject()
        .nullable()
        .shape({
            hasEnstakaResor: yupBoolean()
                .nullable()
                .when({
                    is: () => arendeTyp === ArendeTyp.Anstallning,
                    then: ShouldBeFalseBooleanSchema,
                    otherwise: yupBoolean().nullable(),
                }),
            hasLogi: yupBoolean()
                .nullable()
                .when({
                    is: () => arendeTyp === ArendeTyp.Anstallning,
                    then: ShouldBeFalseBooleanSchema,
                    otherwise: yupBoolean().nullable(),
                }),
            hasProgramvaror: yupBoolean()
                .nullable()
                .when({
                    is: () => arendeTyp === ArendeTyp.Anstallning,
                    then: ShouldBeFalseBooleanSchema,
                    otherwise: yupBoolean().nullable(),
                }),
            hasRegelbundnaResor: yupBoolean()
                .nullable()
                .when({
                    is: () => arendeTyp === ArendeTyp.Anstallning,
                    then: ShouldBeFalseBooleanSchema,
                    otherwise: yupBoolean().nullable(),
                }),
            hasUtbildningskostnader: yupBoolean()
                .nullable()
                .when({
                    is: () => arendeTyp === ArendeTyp.Anstallning,
                    then: ShouldBeFalseBooleanSchema,
                    otherwise: yupBoolean().nullable(),
                }),
        })
