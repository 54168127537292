import { ApiResponseModel } from '@local/services/ApiResponseModel'
import { pushFeedback } from '@local/src/components/Toaster/Toaster'
import ErrorResponse from '@local/src/features/StudiestodAnsokan/Components/SubmitResponse/ErrorResponse'
import { IconButton, Portal } from '@mui/material'
import { useIsFeatureEnabled } from '@trr/app-shell-data'
import { closeSnackbar, useSnackbar } from 'notistack'
import React, { HTMLAttributes, useEffect } from 'react'
import CloseIcon from '@mui/icons-material/Close'

interface Props {
    apiResponse: ApiResponseModel<unknown>
    successMessage: string
    failureMessage: string
    hideApiResponseErrorText?: boolean
    portalContainer?: Element
}

const SubmitResponse = ({
    apiResponse,
    successMessage,
    failureMessage,
    hideApiResponseErrorText = false,
    portalContainer = undefined,
}: Props) => {
    const isSnackbarEnabled = useIsFeatureEnabled('CSN-Medarbetare-Studier-Frontend_snackbar_temp_241023')
    const { enqueueSnackbar } = useSnackbar()

    useEffect(() => {
        if (apiResponse !== undefined) {
            const isSuccess = apiResponse.isSuccess
            const message = isSuccess ? successMessage : failureMessage
            const testId = `snackbar-${isSuccess ? 'success' : 'failure'}`

            if (isSnackbarEnabled) {
                enqueueSnackbar({
                    message,
                    variant: isSuccess ? 'success' : 'error',
                    autoHideDuration: isSuccess ? 3000 : null,
                    action: (key) => (
                        <IconButton size="small" aria-label="close" color="inherit" onClick={() => closeSnackbar(key)}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    ),
                    SnackbarProps: { 'data-testid': testId } as HTMLAttributes<HTMLDivElement>,
                })
            } else {
                pushFeedback({
                    body: message,
                    testSelector: isSuccess ? 'toaster-confirmation' : 'toaster-error',
                    isSuccess: isSuccess,
                })
            }
        }
    }, [apiResponse])

    if (!apiResponse || hideApiResponseErrorText) return <></>
    if (apiResponse.isSuccess) return <></>

    return portalContainer ? (
        <Portal container={portalContainer}>
            <ErrorResponse error={apiResponse?.error} />
        </Portal>
    ) : (
        <ErrorResponse error={apiResponse?.error} />
    )
}

export default SubmitResponse
