import { useQuery } from '@tanstack/react-query'
import { csnArendeBeslutApi } from '@local/src/services/apiEndpointHelper'
import { apiGetTryCatchWrapper } from '@local/src/services/apiRequestHelper'

export const useGetBeslutFromCsnByAnsokanId = (ansokanId: string) => {
    const query = useQuery({
        queryKey: ['getBeslutFromCsn', ansokanId],
        queryFn: () => apiGetTryCatchWrapper('useGetBeslutFromCsnByAnsokanId', csnArendeBeslutApi.csnBeslutAnsokanIdDetail, ansokanId),
        placeholderData: [],
    })

    return query
}
