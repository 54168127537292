import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import Loader from '@local/src/components/Loader'
import { Box, Stack } from '@mui/material'
import { useSignalRStudiestodListEvent } from '@local/src/hooks/SignalR'
import EjAktuellStudiestodAnsokanList from '@local/src/features/StudiestodAnsokanList/EjAktuellStudiestodAnsokanList'
import { useSignalR } from '@trr/app-shell-data'

import StudiestodAnsokanList from '../features/StudiestodAnsokanList/StudiestodAnsokanList'
import BegardaYttrandenList from '../features/BegardaYttrandenList/BegardaYttrandenList'
import StudiestodAnsokanDetail from '../features/StudiestodAnsokan/StudiestodAnsokanDetail'
import { useGetCurrentArende } from '../features/StudiestodAnsokan/Hooks'

const StudierTab = () => {
    const match = useRouteMatch<{ arendeId: string; ansokanId: string }>({
        path: '*/arende/:arendeId/studier/ansokan/:ansokanId',
    })
    const isAnsokanDetailsPage = Boolean(match?.params?.ansokanId)
    const { arende, isLoading } = useGetCurrentArende()

    const signalR = useSignalR()
    const medarbetare = signalR?.medarbetare

    useSignalRStudiestodListEvent(medarbetare, arende?.id)

    if (isLoading)
        return (
            <Box mt={8}>
                <Loader />
            </Box>
        )

    return isAnsokanDetailsPage ? (
        <Box pt={3}>
            <StudiestodAnsokanDetail />
        </Box>
    ) : (
        <Stack spacing={4}>
            <StudiestodAnsokanList arendeId={arende?.id} fornamn={arende?.klient?.fornamn} />
            <BegardaYttrandenList arendeId={arende?.id} />
            <EjAktuellStudiestodAnsokanList arendeId={arende?.id} fornamn={arende?.klient?.fornamn} />
        </Stack>
    )
}

export default StudierTab
