import { CsnAdHocYttrandeApiModel, BegaranCsnYttrandeListItemApiModel } from '@local/services/SwaggerApi/data-contracts'
import { useGetBegardaYttranden } from '@local/src/features/BegardaYttrandenList/Hooks/useGetBegardaYttranden'
import { useGetCurrentArende } from '@local/src/features/StudiestodAnsokan/Hooks'

import useGetAdHocYttrandenByAnsokanId from './useGetAdHocYttrandenByAnsokanId'

interface CsnAdHocYttrandeApiModelWithType extends CsnAdHocYttrandeApiModel {
    type: 'AdHocYttrande'
    createdAt: string
}

interface CsnBegaranYttrandeApiModelWithType extends BegaranCsnYttrandeListItemApiModel {
    type: 'BegaranYttrande'
    createdAt: string
}

const useGetYttranden = (arendeId: string, ansokanId: string) => {
    const { arende } = useGetCurrentArende(arendeId)

    const {
        begaranYttranden,
        isError: begaranYttrandenIsError,
        isFetching: begaranYttrandenIsFetching,
    } = useGetBegardaYttranden(arende?.klient?.personnummer, true)

    const {
        adHocYttranden,
        isError: adHocYttrandenIsError,
        isFetching: adHocYttrandenIsFetching,
    } = useGetAdHocYttrandenByAnsokanId(ansokanId)

    const isYttrandenFetching = begaranYttrandenIsFetching || adHocYttrandenIsFetching
    const isYttrandenError = begaranYttrandenIsError || adHocYttrandenIsError

    if (isYttrandenError) {
        return { yttranden: [], isYttrandenFetching, isYttrandenError }
    }

    const ansokanBegaranYttrandenWithType = begaranYttranden
        .filter((y: { ansokanId: string }) => y.ansokanId === ansokanId)
        .map(
            (y: CsnBegaranYttrandeApiModelWithType) =>
                ({ ...y, type: 'BegaranYttrande', createdAt: y.tidpunktBegaran }) as CsnBegaranYttrandeApiModelWithType
        )

    const adHocYttrandenWithType = adHocYttranden?.map(
        (y: CsnAdHocYttrandeApiModelWithType) =>
            ({ ...y, type: 'AdHocYttrande', createdAt: y.createdBy?.lastUpdatedAt }) as CsnAdHocYttrandeApiModelWithType
    )

    const yttranden = [...ansokanBegaranYttrandenWithType, ...adHocYttrandenWithType].sort(
        (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    )

    return { yttranden, isYttrandenFetching, isYttrandenError }
}

export default useGetYttranden
