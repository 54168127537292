import { useEffect } from 'react'
import { FieldErrors, FieldValues } from 'react-hook-form'

export const scrollElementIntoView = (el: HTMLElement, offsetTop = 0) => {
  if (el) {
    const currentPosition = el.style.position
    const currentTop = el.style.top
    el.style.position = 'relative'
    el.style.top = `${offsetTop}px`
    el.scrollIntoView({ behavior: 'smooth', block: 'start' })
    el.style.top = currentTop
    el.style.position = currentPosition
  }
}
const errorClass = '*[class^="Error-module_Error"]'
const useScrollToError = (errors: FieldErrors<FieldValues>, isSubmitted: boolean) => {
  useEffect(() => {
    if (isSubmitted) {
      const hasErrors = Object.keys(errors).length > 0
      if (hasErrors) {
        const error = document.querySelectorAll(errorClass)[0]
        scrollElementIntoView(error as HTMLElement, -200)
      }
    }
  }, [isSubmitted, errors])
}

export default useScrollToError
