import React, { useState } from 'react'
import { PageWrapper } from '@local/src/components/PageWrapper'
import { FormProvider } from 'react-hook-form'
// import { DevTool } from '@hookform/devtools'
import { AppBar, Grid2 as Grid, Stack, Typography } from '@mui/material'
import { useSignalRStudiestodDetailEvent } from '@local/src/hooks/SignalR'
import SetEjAktuellAction from '@local/src/features/StudiestodAnsokan/Components/SetEjAktuellAction/SetEjAktuellAction'
import EjAktuellAlert from '@local/src/features/StudiestodAnsokan/Components/EjAktuellAlert/EjAktuellAlert'
import IsDeletedDialog from '@local/src/features/StudiestodAnsokan/Components/IsDeletedDialog/IsDeletedDialog'
import { useSignalR } from '@trr/app-shell-data'
import useCommonTranslation from '@local/src/hooks/useCommonTranslation'
import StudiestodStatusChip from '@local/src/components/StudiestodStatusChip/StudiestodStatusChip'
import { getStudiestodTitle } from '@local/src/utils/helpers/studiestodAnsokanHelper'

import { StudiestodStepper, StudiestodActiveStepComponent } from './Components/StudiestodStepper/'
import useUpdateUnderlagForm from './Hooks/useUpdateUnderlagForm'
import { useFormStepUrl, useGetCurrentArende, useGetCurrentStudiestodansokan } from './Hooks'
import DomainEventsLog from './Components/DomainEventsLog/DomainEventsLog'

const StudiestodAnsokanDetail = () => {
    const { tCommon } = useCommonTranslation()
    const { changeStep, activeStep } = useFormStepUrl()
    const signalR = useSignalR()
    const medarbetare = signalR?.medarbetare
    const { studiestodAnsokan, isLoading, isError, error, isEjAktuell } = useGetCurrentStudiestodansokan()

    const [isDeleted, setIsDeleted] = useState(false)
    const { arende } = useGetCurrentArende()

    const formMethods = useUpdateUnderlagForm(studiestodAnsokan, arende?.arendeTyp)

    const ansokanIsDeleted = isDeleted || (isError && error?.status === 410)

    useSignalRStudiestodDetailEvent(medarbetare, studiestodAnsokan?.id, () => setIsDeleted(true))

    const cardTitle = getStudiestodTitle(studiestodAnsokan)

    return (
        <FormProvider {...formMethods}>
            <PageWrapper isLoading={isLoading} errorLoadingData={isError} errorText={tCommon('error_generic')}>
                <Grid container direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <Grid size={{ xs: 12, sm: 3 }}>
                        <Stack sx={{ position: 'sticky', top: 0 }} spacing={2}>
                            <StudiestodStepper changeStep={changeStep} activeStep={activeStep} />
                        </Stack>
                    </Grid>
                    <Grid size={{ xs: 12, sm: 9 }}>
                        <Stack spacing={2}>
                            <AppBar
                                position="sticky"
                                sx={{
                                    bgcolor: '#fff',
                                    boxShadow: 'none',
                                    borderBottom: '2px solid #e0e0e0',
                                }}
                            >
                                <Stack alignItems="stretch">
                                    <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                                        <Typography
                                            sx={{
                                                display: '-webkit-box',
                                                WebkitLineClamp: 2,
                                                WebkitBoxOrient: 'vertical',
                                                wordBreak: 'break-word',
                                            }}
                                            variant="h6"
                                            color="text.primary"
                                            maxWidth="90%"
                                            textOverflow="ellipsis"
                                            overflow="hidden"
                                        >
                                            {cardTitle}
                                        </Typography>
                                        <StudiestodStatusChip status={studiestodAnsokan?.studiestodAnsokanState} />
                                    </Stack>
                                    <Stack direction="row" py={1} spacing={2} justifyContent="flex-start" alignItems="center">
                                        <DomainEventsLog studiestod={studiestodAnsokan} />
                                        {studiestodAnsokan?.canBeSetToEjAktuell && (
                                            <SetEjAktuellAction
                                                isEjAktuell={isEjAktuell}
                                                hasFakturor={studiestodAnsokan.hasFakturor}
                                                hasErsattning={studiestodAnsokan.hasStudieersattning}
                                            />
                                        )}
                                    </Stack>
                                </Stack>
                            </AppBar>

                            <StudiestodActiveStepComponent activeStep={activeStep} />
                            {isEjAktuell && <EjAktuellAlert orsak={studiestodAnsokan?.ejAktuell?.orsakMedarbetare} />}
                        </Stack>
                    </Grid>
                </Grid>
            </PageWrapper>
            <IsDeletedDialog
                isDeleted={ansokanIsDeleted}
                studiestodNummer={studiestodAnsokan?.studiestodNummer}
                ansokanNamn={studiestodAnsokan?.ansokanNamn}
            />
            {/* <DevTool control={formMethods.control} placement="bottom-right" /> */}
        </FormProvider>
    )
}

export default StudiestodAnsokanDetail
