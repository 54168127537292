import { requiredValidationText } from '@local/src/utils/Yup/YupHelper'
import { ArendeTyp } from '@local/src/@types/Arenden.types'
import { object as yupObject, string as yupString, boolean as yupBoolean, array as yupArray, SchemaOf } from 'yup'
import { FormValidationTexts } from '@local/src/content/resources'

import { RadgivarensUnderlagForm } from '../Models/RadgivarensUnderlagForm'

import { UtbildningUnderlagSchema, UtbildningUnderlagDraftSchema } from './UtbildningUnderlagSchema'

export const RadgivarensUnderlagDraftSchema = (
    formValidationTexts?: FormValidationTexts,
    arendeTyp?: ArendeTyp
): SchemaOf<RadgivarensUnderlagForm> =>
    RadgivarensUnderlagSubmitSchema(formValidationTexts, arendeTyp).shape({
        hasAktivitetstod: yupBoolean().nullable(),
        motiveringArbetsmarknad: yupString().nullable().trim(),
        utbildningar: yupArray().nullable().of(UtbildningUnderlagDraftSchema(formValidationTexts, arendeTyp)),
    })

export const RadgivarensUnderlagSubmitSchema = (
    formValidationTexts?: FormValidationTexts,
    arendeTyp?: ArendeTyp,
    isLegacy?: boolean
): SchemaOf<RadgivarensUnderlagForm> =>
    yupObject({
        isUpdate: yupBoolean().nullable(),
        hasAktivitetstod: yupBoolean().nullable().required(formValidationTexts?.hasAktivitetstod),
        motiveringArbetsmarknad: yupString().nullable().trim().required(formValidationTexts?.motiveringArbetsmarknad),
        motiveringUtbildningsmal: yupString().nullable().trim(),
        utbildningar: yupArray()
            .required(requiredValidationText)
            .min(1, formValidationTexts?.minOneKalenderhalvar)
            .of(UtbildningUnderlagSchema(formValidationTexts, arendeTyp, isLegacy)),
    })
