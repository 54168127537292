/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect, useState } from 'react'
import { useDirtyCheckPrompt } from '@trr/react-use-dirty-check-prompt'
import useScrollToError from '@local/src/hooks/useScrollToError'
import { Alert, AlertTitle, Divider, Stack, Typography } from '@mui/material'
import Icons from '@local/src/components/Icons'
import { useOnUnmount } from '@local/src/hooks/useOnUnmount'
import { StudiestodUnlockState } from '@local/src/services/SwaggerApi/data-contracts'
import { useTranslation } from 'react-i18next'

import { useGetCurrentStudiestodansokan } from '../../../Hooks'
import Form from '../../Form/Form'
import SubmitResponse from '../../SubmitResponse/SubmitResponse'
import Utbildning from '../../Utbildning/Utbildning'
import NyaAvtaletHighlightBox from '../../NyaAvtaletHighlightBox/NyaAvtaletHighlightBox'
import StudiestodUnlockForEditingActionBox from '../../StudiestodUnlockForEditingActionBox'

import {
    InformationWithWarningIcon,
    MarkeratSomKlartInfo,
    DraftSubmitButton,
    FormErrorSummary,
    YttrandeToCsnRadioButton,
    NekaStod,
    Insats,
} from './Components'
import { MotiveringArbetsmarknad, MotiveringUtbildningsmalet, HarAktivitetsstod } from './RadgivarensUnderlagInputs'
import { useRadgivarensUnderlagSubmit, useRadgivarensUnderlagFormContext } from './Hooks'
import { RadgivarensUnderlagForm } from './Models/RadgivarensUnderlagForm'

const RadgivarensUnderlag = () => {
    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokan.steps.radgivarensUnderlag' })

    const [apiResponse, setApiResponse] = useState(undefined)
    const { submitRadgivarensUnderlag } = useRadgivarensUnderlagSubmit()
    const { studiestodAnsokan, isEjAktuell, invalidateQuery } = useGetCurrentStudiestodansokan()

    const isMarkeradSomKlar = Boolean(studiestodAnsokan?.radgivarunderlag?.isPublished)
    const isAktuell = !isEjAktuell
    const isReadonly = isMarkeradSomKlar || isEjAktuell
    const canUnlockState = isMarkeradSomKlar && isAktuell

    const {
        handleSubmit,
        reset,
        watch,
        clearErrors,
        formState: { isDirty, isSubmitted, errors, dirtyFields },
    } = useRadgivarensUnderlagFormContext()
    const isDraft = watch('isUpdate')
    const isReallyDraft = isDirty && Object.keys(dirtyFields).length > 0
    const { routerPrompt: renderUnsavedChangesPrompt } = useDirtyCheckPrompt(isReallyDraft, t('lamnaIfylltFormularWarningText'))

    useScrollToError(errors, isSubmitted)

    useEffect(() => {
        setApiResponse(undefined)
    }, [isMarkeradSomKlar])

    const onSubmit = async (data: RadgivarensUnderlagForm) => {
        const resp = await submitRadgivarensUnderlag(data, !data.isUpdate)
        setApiResponse(resp)
        if (resp.isSuccess) {
            await invalidateQuery()
            reset(data)
        }
    }

    const isNotDigitalt = !studiestodAnsokan?.isStudiestodAnsokanUnderlagBeslutDigitalt

    useOnUnmount(clearErrors)

    return isNotDigitalt ? (
        <Alert sx={{ p: 2 }} data-testid="not-digital-process-step-info" icon={<Icons.Lightbulb />} severity="warning">
            <AlertTitle>
                <Typography variant="h5">{t('notCompleteDigitalStudiestodTitle')}</Typography>
            </AlertTitle>
            <Typography variant="body2">{t('notCompleteDigitalStudiestodDescription')}</Typography>
        </Alert>
    ) : (
        <Form data-testid="form-steppers-section-underlag" onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2}>
                <NyaAvtaletHighlightBox
                    isVisible={studiestodAnsokan?.showOnlyStudiestodsTypStudieersattningAndStudielon}
                    title={t('preNyaAvtaletHighlightBoxHeader')}
                    content={t('preNyaAvtaletHighlightBoxText')}
                />

                {canUnlockState && <StudiestodUnlockForEditingActionBox unlockStep={StudiestodUnlockState.Radgivarunderlag} />}

                <Stack spacing={2}>
                    <Typography variant="h4">{t('header')}</Typography>
                    <Typography>{t('description')}</Typography>
                    <Divider />
                    <MotiveringArbetsmarknad
                        label={t('motiveringArbetsmarknadText')}
                        isReadonly={isReadonly}
                        popoverContent={{ content: t('motiveringArbetsmarknadTooltipText') }}
                    />
                    <MotiveringUtbildningsmalet
                        label={t('motiveringUtbildningsmaletText')}
                        subLabel={t('motiveringUtbildningsmaletSubText')}
                        popoverContent={{
                            content: t('motiveringUtbildningsmaletTooltipText'),
                            link: t('motiveringUtbildningsmaletTooltipLink'),
                            linkText: t('motiveringUtbildningsmaletTooltipLinkText'),
                        }}
                        isReadonly={isReadonly}
                    />
                    <InformationWithWarningIcon warningText={t('gdprText')} linkText={t('gdprLinkText')} link={t('gdprLink')} />
                    <Divider />
                    <HarAktivitetsstod
                        label={t('aktivitetsstodText')}
                        popoverContent={{ content: t('aktivitetsstodTooltipText') }}
                        isReadonly={isReadonly}
                    />
                    <Divider />
                    <Typography variant="h5">{t('insatserHeader')}</Typography>
                    <Typography>{t('insatserDescription')}</Typography>
                    <Stack spacing={2}>
                        {studiestodAnsokan?.radgivarunderlag?.utbildningar?.map((insats, index) => (
                            <Utbildning key={insats?.utbildning?.id} utbildning={insats?.utbildning} index={index}>
                                <Stack spacing={2}>
                                    <Divider />
                                    <YttrandeToCsnRadioButton isReadonly={isReadonly} utbildningIndex={index} />
                                    <Divider />
                                    <NekaStod isReadonly={isReadonly} utbildningIndex={index} />
                                    <Insats isReadonly={isReadonly} utbildningsIndex={index} />
                                </Stack>
                            </Utbildning>
                        ))}
                    </Stack>
                    <MarkeratSomKlartInfo isReadonly={isMarkeradSomKlar} content={t('markeratSomKlartInfo')} />
                    <DraftSubmitButton
                        submitButtonText={t('submitButtonText')}
                        draftButtonText={t('draftButtonText')}
                        draftSubmitDescription={t('draftSubmitDescription')}
                        isReadonly={isReadonly}
                    />
                    <FormErrorSummary />
                    <SubmitResponse
                        apiResponse={apiResponse}
                        successMessage={isDraft ? t('sparaUtkastSnackbarSuccessText') : t('markeradSomKlarSnackbarSuccessText')}
                        failureMessage={isDraft ? t('sparaUtkastSnackbarFailureText') : t('markeradSomKlarSnackbarFailureText')}
                    />

                    {renderUnsavedChangesPrompt}
                    {canUnlockState && <StudiestodUnlockForEditingActionBox unlockStep={StudiestodUnlockState.Radgivarunderlag} />}
                </Stack>
            </Stack>
        </Form>
    )
}

export default RadgivarensUnderlag
