import begardaYttrandenList from './begardaYttrandenList.json'
import studiestodAnsokanList from './studiestodAnsokanList.json'
import studiestodAnsokan from './studiestodAnsokan.json'
import common from './common.json'

export const translation = {
    studiestodAnsokan,
    studiestodAnsokanList,
    common,
    begardaYttrandenList,
}
const resources = {
    translation,
} as const

export type ResourceContent = typeof translation

export type FormValidationTexts = typeof translation.studiestodAnsokan.steps.radgivarensUnderlag.formValidationTexts

export default resources
